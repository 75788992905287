import * as React from "react";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../shared-ui/frontend/dialog";
import { Input } from "../../../shared-ui/frontend/input";
import { Label } from "../../../shared-ui/frontend/label";
import { Button } from "../../ui/button";
import { ScrollArea } from "../../ui/scroll-area";
import {
  useFaultTreesQuery,
  useGroupsQuery,
  useInvalidateGroupsQuery,
  useInvalidateSectionsQuery,
} from "../../../hooks/tanstack-query";
import { useMemo } from "use-memo-one";
import { Minus, Plus, X } from "lucide-react";
import {
  DndContext,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { v4 } from "uuid";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { z, ZodError } from "zod";
import { addUnknownErrorToast } from "../../toast/use-toast-store";
import { useMutation } from "@tanstack/react-query";
import { createSection } from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";
import { SectionWithFaultTrees } from "./use-groups-with-sections";
import { faultTreeSchema } from "../../../lib/api-schema/ft/fault-tree";

export function useCreateSectionMutation(opts?: { onSuccess?: () => void }) {
  const b = useBaseUrlExperimental();
  const refreshSections = useInvalidateSectionsQuery();
  const refreshGroups = useInvalidateGroupsQuery();
  const refresh = () => {
    refreshGroups();
    refreshSections();
  };
  const mut = useMutation({
    mutationFn: async ({
      groupIds,
      name,
    }: {
      name: string;
      groupIds: [string, ...string[]];
    }) => {
      await createSection(b, { name, groups: groupIds, type: "trees" });
    },
    onError: (e) => {
      refresh();
      addUnknownErrorToast(e);
    },
    onSuccess: () => {
      opts?.onSuccess?.();
      refresh();
    },
  });

  return mut;
}

export function CreateFaultTreeSectionButton({ className }: PropsWithCn) {
  const [isCreating, setIsCreating] = React.useState(false);

  const closeDialog = () => setIsCreating(false);
  const mut = useCreateSectionMutation({
    onSuccess: closeDialog,
  });

  return (
    <Dialog open={isCreating} onOpenChange={setIsCreating}>
      <DialogTrigger asChild>
        <Button className={className} variant={"violet"} size={"xs"}>
          <Plus className="size-3.5 mr-1" /> Create Section
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-max p-5">
        <DialogHeader>
          <DialogTitle>Create Section</DialogTitle>
          <DialogDescription>
            Add fault trees to section. Drag and drop to reorder.
          </DialogDescription>
        </DialogHeader>
        <CreateOrEditSectionForm
          close={closeDialog}
          disabled={mut.isLoading}
          onSubmit={mut.mutate}
        />
      </DialogContent>
    </Dialog>
  );
}

export function CreateOrEditSectionForm({
  defaults,
  disabled,
  onSubmit,
  close,
}: {
  defaults?: Pick<SectionWithFaultTrees, "faultTrees"> & {
    section: Pick<SectionWithFaultTrees["section"], "name">;
  };
  disabled?: boolean;
  onSubmit: (p: { groupIds: [string, ...string[]]; name: string }) => void;
  close?: () => void;
}) {
  const trees = useFaultTreesQuery().data;

  const [selectedGroups, setSelectedGroups] = React.useState<faultTreeSchema[]>(
    defaults?.faultTrees ?? []
  );
  const [sectionName, setSectionName] = React.useState(
    defaults?.section.name ?? ""
  );

  const excluded = useMemo(() => {
    return (
      trees?.filter((x) => !selectedGroups.some((y) => x._id === y._id)) ?? []
    );
  }, [selectedGroups, trees]);

  /**
   * If groups change while editing, ensure edits are valid
   */
  React.useEffect(() => {
    if (!trees) return;

    setSelectedGroups((curr) => {
      return curr.filter((x) => {
        const isStillValid = trees.some((y) => x._id === y._id);
        return isStillValid;
      });
    });
  }, [trees, setSelectedGroups]);

  const defaultName = defaults?.section.name;
  const defaultSelectedGroups = defaults?.faultTrees;

  /**
   * If this form is used as edit, and not create,
   * we should listen for changes to the editing
   * document. This can change if the query refreshes.
   */
  React.useEffect(() => {
    if (defaultName === undefined || defaultSelectedGroups === undefined)
      return;

    setSelectedGroups(defaultSelectedGroups);
    setSectionName(defaultName);
  }, [defaultName, defaultSelectedGroups]);

  if (!trees) return null;

  /**
   * @throws ZodError
   */
  const getPayload = () => {
    return {
      groupIds: assertMinLen1(
        z
          .string()
          .array()
          .min(1, {
            message: "At least 1 group is required",
          })
          .parse(selectedGroups.map((x) => x._id))
      ),
      name: z
        .string()
        .trim()
        .min(1, {
          message: "Section name is required",
        })
        .parse(sectionName),
    };
  };

  const isLoading = disabled;

  return (
    <form
      className="grid grid-cols-2 gap-4"
      onSubmit={(e) => {
        e.preventDefault();

        try {
          onSubmit(getPayload());
        } catch (e) {
          if (e instanceof ZodError) {
            addUnknownErrorToast(
              e.issues[0]?.message ?? "An unknown error occurred"
            );
            return;
          }

          addUnknownErrorToast(e);
          throw e;
        }
      }}
    >
      <Label htmlFor="name" className="col-span-2 text-xslate-11">
        Name
      </Label>
      <Input
        className="col-span-2"
        autoComplete="off"
        value={sectionName}
        onChange={(e) => setSectionName(e.target.value)}
        required
        disabled={isLoading}
        minLength={1}
        name="name"
        placeholder="Section name"
      />
      <SwapList
        disabled={isLoading}
        type="Included"
        // className={cn(!variablesQuery.isError && "hover:border-xslate-9")}
        items={selectedGroups}
        icon={<Minus className="size-3" />}
        onClick={(g) => {
          setSelectedGroups((curr) => curr.filter((x) => x._id !== g._id));
        }}
        onAll={() => {
          setSelectedGroups([]);
        }}
        onRedorder={(newOrderIds) => {
          setSelectedGroups((curr) => {
            const copy = curr.slice().sort((a, b) => {
              const idx = newOrderIds.findIndex((x) => x === a._id);

              if (idx === -1) throw new Error("impossible");

              const idx2 = newOrderIds.findIndex((x) => x === b._id);

              if (idx2 === -1) throw new Error("impossible");

              return idx - idx2;
            });

            return copy;
          });
        }}
      />
      <SwapList
        disabled={isLoading}
        type="Excluded"
        // className={cn(!variablesQuery.isError && "hover:border-xslate-9")}
        items={excluded}
        icon={<Plus className="size-3" />}
        onClick={(g) => {
          setSelectedGroups((curr) => curr.concat(g));
        }}
        onAll={() => {
          setSelectedGroups(trees);
        }}
      />

      <div className="col-span-2 flex">
        {close && (
          <Button
            variant={"destructive"}
            onClick={close}
            type="button"
            size={"sm"}
          >
            Cancel
          </Button>
        )}
        <Button
          disabled={isLoading}
          type="submit"
          size={"sm"}
          className="ml-auto"
        >
          Save
        </Button>
      </div>
    </form>
  );
}

function SwapList({
  className,
  items: groups,
  onClick,
  icon,
  type,
  onAll,
  onRedorder,
  disabled,
}: {
  className?: string;
  items: faultTreeSchema[]; // order matters
  onClick: ((id: faultTreeSchema) => void) | undefined;
  icon: React.ReactNode;
  type: "Included" | "Excluded";
  onAll: (() => void) | undefined;
  onRedorder?: (groupIds: string[]) => void;
  disabled?: boolean;
}) {
  // const isNew = (tag: TagForForm) => {
  //   switch (type) {
  //     case "Excluded":
  //       return tag.currentlyIncluded;
  //     case "Included":
  //       return !tag.currentlyIncluded;
  //     default:
  //       const _: never = type;
  //       throw new Error("impossible");
  //   }
  // };

  const allButtonProps = (): React.PropsWithChildren<
    Required<Pick<React.ComponentProps<typeof Button>, "variant">>
  > => {
    switch (type) {
      case "Excluded":
        return {
          variant: "secondary",
          children: "Add All",
        };
      case "Included":
        return {
          variant: "destructive",
          children: "Remove All",
        };
      default:
        const _: never = type;
        throw new Error("impossible");
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const displayOrderById = groups.map((x) => x._id);

  const [droppableId] = React.useState(() => v4());

  const { setNodeRef } = useDroppable({
    id: droppableId,
  });

  const [search, setSearch] = React.useState("");
  const trimmedSearch = search.trim().toLowerCase();

  const filteredGroups = useMemo(() => {
    const noSearch = !trimmedSearch;

    if (noSearch) return groups;

    return groups.filter((x) => x.name.toLowerCase().includes(trimmedSearch));
  }, [trimmedSearch, groups]);

  return (
    <div className="col-span-1 inline-flex flex-col gap-2">
      <div className="flex items-end">
        <Label className="text-xslate-11">{type}</Label>
        <Button
          disabled={disabled || (groups ? groups.length === 0 : true)}
          className="ml-auto"
          size={"xxs"}
          type="button"
          onClick={onAll}
          {...allButtonProps()}
        />
      </div>
      <div className="relative">
        {/* search input  */}
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by fault tree name"
        />
        {trimmedSearch !== "" && (
          // a button to clear the search input
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="absolute top-1/2 right-1.5 -translate-y-1/2 text-xslate-11"
                size={"icon-sm"}
                variant={"ghost"}
                type="button"
                onClick={() => setSearch("")}
              >
                <X className="size-3.5" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Clear search</p>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      <ScrollArea
        ref={setNodeRef}
        className="h-[50svh] rounded-md border border-xslate-7 w-96 flex flex-col"
      >
        <DndContext
          sensors={sensors}
          onDragEnd={(e) => {
            const groupId = e.active.id.toString();

            const over = e.over?.id.toString();

            if (groupId === over) return; // no-op

            if (over === undefined) {
              // all the way at the bottom of the list
              onRedorder?.(
                displayOrderById.filter((x) => x !== groupId).concat(groupId)
              );
              return;
            }

            if (over === droppableId) {
              return; // no-op
            }
            const idxB = displayOrderById.indexOf(over);
            const newOrder = displayOrderById.filter((x) => x !== groupId);
            newOrder.splice(idxB, 0, groupId);
            onRedorder?.(newOrder);
          }}
        >
          <SortableContext
            /**
             * Feed in the order of the groups from top to bottom.
             * Update this state upon drag-n-drop to reorder.
             */
            items={displayOrderById}
          >
            <ul
              className={cn(
                "relative flex flex-1 flex-col pl-2 pr-3 grow",
                className
              )}
            >
              {filteredGroups.map((group) => {
                return (
                  <DraggableGroupElement
                    icon={icon}
                    key={group._id}
                    onClick={onClick}
                    group={group}
                    /**
                     * The useMemo above may return a filtered array, or
                     * the same array, so use Object.is
                     */
                    disabled={!Object.is(filteredGroups, groups)} // only allow drag n drop when there is no searching being done
                  />
                );
              })}
            </ul>
          </SortableContext>
        </DndContext>
      </ScrollArea>
    </div>
  );
}

function DraggableGroupElement({
  onClick,
  group,
  icon,
  disabled,
}: {
  onClick?: (g: faultTreeSchema) => void;
  group: faultTreeSchema;
  icon: React.ReactNode;
  disabled?: boolean;
}) {
  const notClickable = false;

  // got this from the docs
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: group._id,
    disabled: disabled,
  });

  // got this from the docs
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className={cn(
        "first-of-type:mt-2 last-of-type:mb-2",
        isDragging && "z-10" // show on top while dragging
      )}
    >
      <button
        onClick={() => {
          onClick?.(group);
        }}
        type="button"
        className={cn(
          "flex w-full items-center gap-2 rounded-md px-2 py-1.5 text-sm transition-all",
          !notClickable && "focus:scale-[97%]",
          !notClickable
            ? "cursor-pointer hover:bg-xslate-2 hover:pl-3 hover:text-xindigo-9"
            : "pointer-events-none",
          isDragging && "border border-xindigo-9 bg-xindigo-4 text-xindigo-11"
          // isNewToThisList && "font-medium text-indigo-800"
        )}
      >
        {icon}
        <span className="tracking-tight text-sm">{group.name}</span>
      </button>
    </li>
  );
}

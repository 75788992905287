import * as R from "remeda";
import { SlPlus } from "react-icons/sl";
import { cn } from "../../../lib/utils";
import {
  ComponentProps,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  PersonalFoldersQuery,
  useAnomalousVariablesQuery,
  useAriaClustersQuery,
  useFreshAnomaliesQuery,
  useGroupsQuery,
  usePersonalFoldersQuery,
  useSlopesForGroupQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { useSearchParams } from "react-router-dom";
import { DRAParamsMap } from "../../boundaries/hooks/useDRAParams2";
import { Button } from "../../../shared-ui/frontend/button";
import { FaChevronRight, FaEllipsisV } from "react-icons/fa";
import { Badge } from "../../../shared-ui/frontend/badge";
import { DATESTATE_SP_KEYS, useDateState } from "../../../zustand/useDateState";
import { BsFillLightningChargeFill } from "react-icons/bs";
import {
  sectionOfTitle,
  useGetUseProfileBookStoreRequired,
} from "../use-profile-book-store";
import { useGetUseCreateOrEditFolderStore } from "../create-or-edit-folder-store";
import { createJotaiStoreForChartSync } from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../shared-ui/frontend/dropdown-menu";
import { Pencil, Trash2 } from "lucide-react";
import { assertMinLen1, iife, minLen1 } from "../../../shared-ui/lib/utils";
import { useMemoOne } from "use-memo-one";
import {
  defaultRangeIndex,
  defaultSlopingTrendsRangeIndex,
} from "../../../constants/dateState";
import { AnomalyLevelEnum } from "../../../shared-ui/lib/anomaly-levels";
import { GenericDeleteTriggerWithConfirmationPopup } from "../../../shared-ui/frontend/generic-delete-confirm";
import { useMutation } from "@tanstack/react-query";
import { deleteFolder } from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import { addUnknownErrorToast } from "../../toast/use-toast-store";
import {
  Atoms,
  ChartVariant,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useGetUseDisplayedChartsStores } from "../use-displayed-charts-store";
import { variableSchema } from "../../../lib/api-validators";
import { v4 } from "uuid";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { OVERALL_GROUP_NAME } from "../../../lib/api-schema/misc";
import { useGetUseViewModeStore } from "../../../shared-ui/time-series-2/grid-view-store";
import { ellipsify } from "../../utils/stylable";
import { minutesToMilliseconds } from "date-fns";
import { Input } from "../../../shared-ui/frontend/input";

function PersonalFolders() {
  const [, setSp] = useSearchParams();
  const foldersQuery = usePersonalFoldersQuery();
  const [isCollapsed, setCollapsed] = useState(false);
  const folders = foldersQuery.data;
  const variablesQuery = useVariablesArrayQuery();

  const variables = variablesQuery.data;
  const usePbStore = useGetUseProfileBookStoreRequired();
  const title = usePbStore((s) => s.title);
  const setTitle = usePbStore((s) => s.setTitle);

  const isOverlayView = useGetUseViewModeStore()((s) => s.viewMode === "chart");
  const useCreateOrEditFolderStore = useGetUseCreateOrEditFolderStore();
  const enterCreateFolderMode = useCreateOrEditFolderStore(
    (s) => s.enterCreateMode
  );

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();

  const invalidateFolders = PersonalFoldersQuery.useInvalidate();
  const b = useBaseUrlExperimental();
  const deleteFolderMut = useMutation({
    mutationFn: async (_id: string) => {
      await deleteFolder(b, _id);
    },
    onSuccess: invalidateFolders,
    onError: (e) => addUnknownErrorToast(e),
  });

  const [confirmFolderDeleteId, setDeleteId] = useState<string>();
  const enterEditMode = useCreateOrEditFolderStore((s) => s.enterEditMode);

  const zone = useTimezone();
  useEffect(
    function handleChange() {
      if (title.type !== "personal-folders") return;
      const folderId = title._id;
      const folder = folders?.folder.find((f) => f._id === folderId);

      if (!folder) {
        // clear the url
        useDisplayedChartsStore.getState().replaceCharts([]);
        return;
      }

      const currentCharts = useDisplayedChartsStore.getState().charts ?? [];

      const chartsDontMatch =
        folder.variables.length !== currentCharts.length ||
        folder.variables.some((vids, i) => {
          const correspondingChart = currentCharts[i];
          if (!correspondingChart) throw new Error("BUG");

          const initialVariableIds = correspondingChart.store.get(
            Atoms.getInitialStateOnlyVariableTrendLines
          );

          return !R.isDeepEqual(vids, initialVariableIds);
        });

      if (chartsDontMatch) {
        useDisplayedChartsStore.getState().replaceCharts(
          folder.variables.map((vs, _, arr) => {
            const store = createJotaiStoreForChartSync({
              initialExpanded: arr.length === 1,
              initialBatchVariables: assertMinLen1(
                vs.map((vid) => {
                  return {
                    type: "variable",
                    bv: vid.padStart(48, "0"),
                  };
                })
              ),
              zone,
            });
            store.set(Atoms.setSlopingTrendsAtom, {
              on: usePbStore.getState().showSlopingTrends,
            });
            return {
              id: v4(),
              store,
            };
          })
        );
      }
    },
    [
      title,
      folders,
      variables,
      setSp,
      useDisplayedChartsStore,
      usePbStore,
      zone,
    ]
  );

  const handleLeavingSlopingTrendsFolder =
    useHandleClickingOnNonSlopingTrendsFolder();

  if (!folders || !variables) return null;

  const { folder: personalFolders } = folders;

  return (
    <>
      <div className="flex flex-col">
        <div
          className={cn(
            "text-slate-500 text-[13px] font-semibold mb-1.5 w-full flex justify-between items-center cursor-pointer"
          )}
          onClick={function () {
            setCollapsed((prev) => !prev);
          }}
        >
          <div className="inline-flex items-center select-none">
            <Button size={"icon"} variant={"ghost"}>
              <FaChevronRight
                className={cn(
                  "transition-transform",
                  !isCollapsed && "rotate-90"
                )}
              />
            </Button>
            Personal Folders ({personalFolders.length})
          </div>

          <Button
            variant={"ghost"}
            size={"icon-sm"}
            className="text-indigo-600"
            onClick={(e) => {
              const s = useDisplayedChartsStore.getState();
              /**
               * When entering create mode, put all the charts on the screen into the
               * starting state of the creation form.
               */
              const currentlyDisplayedChartsStores = s.charts;

              const currentlyDisplayedOverlay = s.overlayChart;

              if (isOverlayView) {
                enterCreateFolderMode(
                  currentlyDisplayedOverlay
                    ? [
                        {
                          chartJotaiStore: currentlyDisplayedOverlay.store,
                          primaryVariableId: assertMinLen1(
                            currentlyDisplayedOverlay.store.get(
                              Atoms.onlyVariableTrendLinesAtom
                            )
                          )[0].bv.slice(24),
                        },
                      ]
                    : []
                );
              } else
                enterCreateFolderMode(
                  (currentlyDisplayedChartsStores ?? []).map(
                    ({ store: jotaiStore }) => {
                      const primaryVariableId = jotaiStore.get(
                        Atoms.onlyVariableTrendLinesAtom
                      )[0];

                      if (!primaryVariableId) throw new Error("BUG");

                      return {
                        primaryVariableId: primaryVariableId.bv.slice(24),
                        chartJotaiStore: jotaiStore,
                      };
                    }
                  )
                );
              e.stopPropagation(); // prevent the parent from collapsing
            }}
          >
            <SlPlus className="size-4" />
          </Button>
        </div>
        {!isCollapsed &&
          personalFolders.map(
            ({ _id: personalFolderId, variables: folderVars, name }) => {
              const disabled = folderVars.length === 0;
              return (
                <div
                  key={personalFolderId}
                  className="inline-flex gap-2 items-center"
                >
                  <FolderButton
                    active={
                      !disabled &&
                      title.type === "personal-folders" &&
                      title._id === personalFolderId
                    }
                    disabled={disabled}
                    onClick={() => {
                      const variablesToDisplay = folderVars
                        .map(
                          (
                            vid
                          ):
                            | undefined
                            | [variableSchema, ...variableSchema[]] => {
                            const vs: variableSchema[] = vid
                              .map((x) => variables.find((v) => v._id === x))
                              .filter((x) => x !== undefined);
                            if (!minLen1(vs)) return undefined;
                            return vs;
                          }
                        )
                        .filter((x) => x !== undefined);
                      if (!variablesToDisplay.length) return; // empty folder, do nothing

                      setTitle({
                        type: "personal-folders",
                        _id: personalFolderId,
                      });
                      handleLeavingSlopingTrendsFolder();

                      useDisplayedChartsStore.getState().replaceCharts(
                        variablesToDisplay.map((vs, _, arr) => {
                          const store = createJotaiStoreForChartSync({
                            zone,
                            initialExpanded: arr.length === 1,
                            initialBatchVariables: assertMinLen1(
                              vs.map((x) => {
                                return {
                                  type: "variable",
                                  bv: x._id.padStart(48, "0"),
                                };
                              })
                            ),
                          });
                          store.set(Atoms.setSlopingTrendsAtom, {
                            on: usePbStore.getState().showSlopingTrends,
                          });
                          return {
                            id: v4(),
                            store,
                          };
                        })
                      );
                    }}
                  >
                    {name}
                    <Badge
                      className="py-0 px-2 leading-4 ml-auto"
                      variant={"primary"}
                    >
                      {folderVars.length}
                    </Badge>
                  </FolderButton>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        size={"icon-sm"}
                        className="shrink-0"
                      >
                        <FaEllipsisV className="size-3.5" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-32">
                      <DropdownMenuGroup>
                        <DropdownMenuItem
                          onSelect={() => {
                            enterEditMode(
                              folderVars.map((vids) => {
                                if (!minLen1(vids)) throw new Error("BUG");
                                return {
                                  primaryVariableId: vids[0],
                                  chartJotaiStore: createJotaiStoreForChartSync(
                                    {
                                      initialBatchVariables: assertMinLen1(
                                        vids.map((x) => {
                                          return {
                                            bv: x.padStart(48, "0"),
                                            type: "variable" as const,
                                          };
                                        })
                                      ),
                                      initialExpanded: false,
                                      zone,
                                    }
                                  ),
                                };
                              }),
                              personalFolderId
                            );
                          }}
                        >
                          <Pencil className="mr-2 h-4 w-4" />
                          <span>Edit</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onSelect={() => setDeleteId(personalFolderId)}
                        >
                          <Trash2 className="mr-2 h-4 w-4" />
                          <span>Delete</span>
                        </DropdownMenuItem>
                      </DropdownMenuGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              );
            }
          )}
      </div>
      <GenericDeleteTriggerWithConfirmationPopup
        open={confirmFolderDeleteId !== undefined}
        onConfirm={() => {
          if (confirmFolderDeleteId === undefined) return;
          deleteFolderMut.mutate(confirmFolderDeleteId);
          setDeleteId(undefined);
        }}
        confirmDisabled={deleteFolderMut.isLoading}
        title={iife(() => {
          const name =
            folders.folder.find((f) => f._id === confirmFolderDeleteId)?.name ??
            "this folder";
          return `Are you sure you want to delete ${name}?`;
        })}
        onOpenChange={(open) => !open && setDeleteId(undefined)}
      />
    </>
  );
}

function ClusterFolders() {
  // filter clusters by selected group
  const groupsQuery = useGroupsQuery();
  const groups = groupsQuery.data;
  const [sp, _setSp] = useSearchParams();
  const groupId = sp.get(DRAParamsMap.g)?.trim() || null;
  const groupObj = groups?.find(
    (g) => g.short_id === parseInt(groupId || "-1")
  );

  const ariaClustersQuery = useAriaClustersQuery();
  const clusters = ariaClustersQuery.data
    ?.sort((a, b) => {
      // static clusters first
      if (a.type === "static" && b.type === "dynamic") return -1;
      if (a.type === "dynamic" && b.type === "static") return 1;
      // then sort by score
      const diff = (b.score || 0) - (a.score || 0);
      if (diff !== 0) return diff;
      // then sort by name
      return a.name.localeCompare(b.name);
    })
    .filter((c) => {
      if (!groupObj) return false;
      return c.variables.some((vid) => groupObj.variables.includes(vid));
    });
  const variablesQuery = useVariablesArrayQuery();

  const variables = variablesQuery.data;
  const usePbStore = useGetUseProfileBookStoreRequired();
  const title = usePbStore((s) => s.title);
  const setTitle = usePbStore((s) => s.setTitle);

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();

  const handleLeavingSlopingTrendsFolder =
    useHandleClickingOnNonSlopingTrendsFolder();
  const zone = useTimezone();
  const setViewMode = useGetUseViewModeStore()((s) => s.setViewMode);

  const [search, setSearch] = useState("");

  if (!clusters) return null;

  return (
    <div className="flex flex-col">
      <Input
        placeholder="Search within Clusters"
        onChange={(e) => setSearch(e.target.value)}
        className="h-8 mb-1"
      />
      {clusters
        .filter(
          (c) =>
            c.name.toLowerCase().includes(search.toLowerCase()) ||
            c.description.toLowerCase().includes(search.toLowerCase()) ||
            c.variables.some((vid) => {
              const found = variables?.find((v) => v._id === vid);
              if (!found) return false;
              return (
                found.name.toLowerCase().includes(search.toLowerCase()) ||
                found.description.toLowerCase().includes(search.toLowerCase())
              );
            })
        )
        .map((c) => {
          const disabled = c.variables.length === 0;
          return (
            <FolderButton
              active={
                !disabled && title.type === "clusters" && title._id === c._id
              }
              disabled={disabled}
              key={c._id}
              onClick={
                variables &&
                (() => {
                  const leavingSection = title.type !== "clusters";
                  setTitle({
                    type: "clusters",
                    _id: c._id,
                  });

                  handleLeavingSlopingTrendsFolder();

                  useDisplayedChartsStore.getState().replaceCharts(
                    c.variables.map((vid, _, arr) => {
                      const store = createJotaiStoreForChartSync({
                        initialExpanded: arr.length === 1,
                        initialBatchVariables: [
                          {
                            type: "variable",
                            bv: vid.padStart(48, "0"),
                          },
                        ],
                        zone,
                      });

                      store.set(Atoms.setSlopingTrendsAtom, {
                        on: usePbStore.getState().showSlopingTrends,
                      });
                      return {
                        id: v4(),
                        store,
                      };
                    })
                  );

                  if (leavingSection) setViewMode("grid");
                })
              }
            >
              {c.type === "dynamic" && (
                <BsFillLightningChargeFill className="size-3 mr-1" />
              )}
              {ellipsify(c.name, 30)}
              <Badge
                className="py-0 px-2 leading-4 ml-auto"
                variant={"primary"}
              >
                {c.variables.length}
              </Badge>
            </FolderButton>
          );
        })}
    </div>
  );
}

function GroupsFolders() {
  // filters groups by overlap with selected group
  const groupsQuery = useGroupsQuery();
  const groups = groupsQuery.data;
  const [sp, _setSp] = useSearchParams();
  const groupId = sp.get(DRAParamsMap.g)?.trim() || null;
  const groupObj = groups?.find(
    (g) => g.short_id === parseInt(groupId || "-1")
  );
  const filteredGroups = groups?.filter((g) => {
    if (!groupObj) return false;
    return g.variables.some((vid) => groupObj.variables.includes(vid));
  });
  const variablesQuery = useVariablesArrayQuery();
  const variables = variablesQuery.data;
  const usePbStore = useGetUseProfileBookStoreRequired();
  const title = usePbStore((s) => s.title);
  const setTitle = usePbStore((s) => s.setTitle);

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();

  const handleLeavingSlopingTrendsFolder =
    useHandleClickingOnNonSlopingTrendsFolder();
  const zone = useTimezone();
  const setViewMode = useGetUseViewModeStore()((s) => s.setViewMode);

  const [search, setSearch] = useState("");

  if (!filteredGroups) return null;

  return (
    <div className="flex flex-col">
      <Input
        placeholder="Search within Groups"
        onChange={(e) => setSearch(e.target.value)}
        className="h-8 mb-1"
      />
      {filteredGroups
        .filter(
          (g) =>
            g.name.toLowerCase().includes(search.toLowerCase()) ||
            g.variables.some((vid) => {
              const found = variables?.find((v) => v._id === vid);
              if (!found) return false;
              return (
                found.name.toLowerCase().includes(search.toLowerCase()) ||
                found.description.toLowerCase().includes(search.toLowerCase())
              );
            })
        )
        .map((group) => {
          const disabled = group.variables.length === 0;
          return (
            <FolderButton
              active={
                title.type === "groups" && title._id === group._id && !disabled
              }
              disabled={disabled}
              key={group._id}
              onClick={
                variables &&
                (() => {
                  const leavingSection = title.type !== "groups";
                  setTitle({
                    type: "groups",
                    _id: group._id,
                  });
                  handleLeavingSlopingTrendsFolder();
                  useDisplayedChartsStore.getState().replaceCharts(
                    group.variables.map((vid, _, arr) => {
                      const store = createJotaiStoreForChartSync({
                        initialExpanded: arr.length === 1,
                        initialBatchVariables: [
                          {
                            type: "variable",
                            bv: vid.padStart(48, "0"),
                          },
                        ],
                        zone,
                      });
                      store.set(Atoms.setSlopingTrendsAtom, {
                        on: usePbStore.getState().showSlopingTrends,
                      });
                      return {
                        id: v4(),
                        store,
                      };
                    })
                  );
                  if (leavingSection) setViewMode("grid");
                })
              }
            >
              {group.name}
              <Badge
                className="py-0 px-2 leading-4 ml-auto"
                variant={"primary"}
              >
                {group.variables.length}
              </Badge>
            </FolderButton>
          );
        })}
    </div>
  );
}

function AnomaliesAndWatchlistFolders() {
  const [sp, setSp] = useSearchParams();
  const [isCollapsed, setCollapsed] = useState(false);
  const variablesQuery = useVariablesArrayQuery();
  const groupsQuery = useGroupsQuery();
  const variables = variablesQuery.data;
  const groups = groupsQuery.data;
  const groupShort = sp.get(DRAParamsMap.g)?.trim();
  const groupShortIdMaybe = groupShort ? parseInt(groupShort) : undefined;

  const groupShortId =
    groupShortIdMaybe !== undefined && !isNaN(groupShortIdMaybe)
      ? groupShortIdMaybe
      : undefined;

  const watchListVariables = useMemo(() => {
    if (!groups) return undefined;
    if (!variables) return undefined;

    const group =
      groups.find((g) => g.short_id === groupShortId) ??
      groups.find((g) => g.name === OVERALL_GROUP_NAME) ??
      groups[0];
    if (!group) return undefined;

    const watch = variables.filter(
      (v) => v.watchlist && group.variables.includes(v._id)
    );

    if (watch.length === 0) return undefined;

    return watch;
  }, [groupShortId, variables, groups]);

  const usePbStore = useGetUseProfileBookStoreRequired();
  const title = usePbStore((s) => s.title);
  const setTitle = usePbStore((s) => s.setTitle);

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();

  const zone = useTimezone();

  /**
   * If we get new data and there happens to be new watchlist variables
   * and we're in the watchlist folder, we need to update the URL to
   * show the charts for the new watchlist variables.
   */
  useEffect(
    function handleWatchlistVariablesUpdating() {
      if (title.type !== "anomalies") return;
      const isInWatchlistFolder = title.name === "Watchlist";
      if (!isInWatchlistFolder) return;
      if (!watchListVariables) return;

      const currentCharts = useDisplayedChartsStore.getState().charts ?? [];

      const chartsDontMatch =
        watchListVariables.length !== currentCharts.length ||
        watchListVariables.some((v, i) => {
          const correspondingChart = currentCharts[i];
          if (!correspondingChart) throw new Error("BUG");

          const initialVariableIds = correspondingChart.store.get(
            Atoms.getInitialStateOnlyVariableTrendLines
          );

          return !R.isDeepEqual([v._id], initialVariableIds);
        });

      if (chartsDontMatch) {
        useDisplayedChartsStore.getState().replaceCharts(
          watchListVariables.map((v, _, arr) => {
            const store = createJotaiStoreForChartSync({
              initialExpanded: arr.length === 1,
              initialBatchVariables: [
                {
                  type: "variable",
                  bv: v._id.padStart(48, "0"),
                },
              ],
              zone,
            });
            store.set(Atoms.setSlopingTrendsAtom, {
              on: usePbStore.getState().showSlopingTrends,
            });
            return {
              id: v4(),
              store,
            };
          })
        );
      }
    },
    [
      title,
      watchListVariables,
      setSp,
      zone,
      usePbStore,
      useDisplayedChartsStore,
    ]
  );

  const handleLeavingSlopingTrendsFolder =
    useHandleClickingOnNonSlopingTrendsFolder();

  return (
    <div className="flex flex-col">
      <div
        className={cn(
          "text-slate-500 text-[13px] font-semibold mb-1.5 w-full flex justify-between items-center cursor-pointer"
        )}
        onClick={() => setCollapsed((prev) => !prev)}
      >
        <div className="inline-flex items-center">
          <Button size={"icon"} variant={"ghost"}>
            <FaChevronRight
              className={cn(
                "transition-transform",
                !isCollapsed && "rotate-90"
              )}
            />
          </Button>
          Anomalies (5)
        </div>
      </div>
      {!isCollapsed && (
        <>
          {watchListVariables && (
            <FolderButton
              active={
                watchListVariables.length > 0 &&
                title.type === "anomalies" &&
                title.name === "Watchlist"
              }
              disabled={!watchListVariables.length}
              onClick={() => {
                setTitle({
                  type: "anomalies",
                  name: "Watchlist",
                });

                handleLeavingSlopingTrendsFolder();

                useDisplayedChartsStore.getState().replaceCharts(
                  watchListVariables.map((v, _, arr) => {
                    const store = createJotaiStoreForChartSync({
                      initialExpanded: arr.length === 1,
                      initialBatchVariables: [
                        {
                          type: "variable",
                          bv: v._id.padStart(48, "0"),
                        },
                      ],
                      zone,
                    });
                    store.set(Atoms.setSlopingTrendsAtom, {
                      on: usePbStore.getState().showSlopingTrends,
                    });
                    return {
                      id: v4(),
                      store,
                    };
                  })
                );
              }}
            >
              Watchlist
              <Badge
                className="py-0 px-2 leading-4 ml-auto"
                variant={"primary"}
              >
                {watchListVariables.length}
              </Badge>
            </FolderButton>
          )}
          <AnomalyFolderButtons />
        </>
      )}
    </div>
  );
}

function FolderButton({
  children,
  active,
  ...rest
}: Pick<ComponentProps<"button">, "disabled" | "onClick"> & {
  // numVariables: number;
  active: boolean;
} & PropsWithChildren) {
  const usePbStore = useGetUseProfileBookStoreRequired();
  const setViewMode = useGetUseViewModeStore()((s) => s.setViewMode);
  return (
    <Button
      variant={active ? "default" : "ghost"}
      size={"sm"}
      {...rest}
      className={cn(
        "rounded-md p-2 pl-3 flex justify-start transition-all duration-100 select-none hover:text-indigo-700 hover:pl-5 text-[13px] last-of-type:mb-4 grow"
      )}
      onClick={(e) => {
        const currentSection = sectionOfTitle(usePbStore.getState().title);
        // reset modes when clicking on folders
        usePbStore.getState().setDefaultModes();
        rest.onClick?.(e);
        // if you're clicking on a different section (folders, clusters, groups) then ensure we're in grid view
        const newSection = sectionOfTitle(usePbStore.getState().title);
        if (newSection !== currentSection) {
          setViewMode("grid");
        }
        // scroll to top
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      {children}
    </Button>
  );
}

function SlopingTrendsFolders() {
  const [sp, setSp] = useSearchParams();
  const groupsQuery = useGroupsQuery();
  const groupShort = sp.get(DRAParamsMap.g)?.trim() || (-1).toString();
  const groups = groupsQuery.data;
  const variablesArr = useVariablesArrayQuery().data;

  const groupObj =
    groups?.find((g) => g.short_id.toString() === groupShort) ??
    groups?.find((g) => g.name === OVERALL_GROUP_NAME) ??
    groups?.[0];
  const [isCollapsed, setCollapsed] = useState(false);

  const ds = useDateState();

  const slopesQuery = useSlopesForGroupQuery({
    date: ds.axisRangeTo.dateString,
    groupId: groupObj?._id ?? "dummy",
    opts: {
      enabled: groupObj !== undefined,
    },
  });

  const slopes = slopesQuery.data;

  const usePbStore = useGetUseProfileBookStoreRequired();
  const title = usePbStore((s) => s.title);
  const setTitle = usePbStore((s) => s.setTitle);

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();

  const zone = useTimezone();

  useEffect(
    function handleWatchlistVariablesUpdating() {
      if (title.type !== "slopes") return;

      const currentCharts = useDisplayedChartsStore.getState().charts ?? [];

      switch (title.name) {
        case "Long-term trends":
          if (
            currentCharts.length !== (slopes?.longSlopes ?? []).length ||
            (slopes?.longSlopes ?? []).some((vid, i) => {
              const correspondingChart = currentCharts[i];

              if (!correspondingChart) throw new Error("BUG");

              const initialVariableIds = correspondingChart.store.get(
                Atoms.getInitialStateOnlyVariableTrendLines
              );

              return !R.isDeepEqual([vid], initialVariableIds);
            })
          ) {
            useDisplayedChartsStore.getState().replaceCharts(
              (slopes?.longSlopes ?? []).map((vid, _, arr) => {
                return {
                  id: v4(),
                  store: createJotaiStoreForChartSync({
                    zone,
                    initialExpanded: arr.length === 1,
                    variant: ChartVariant.SlopingTrends,
                    initialBatchVariables: [
                      {
                        type: "variable",
                        bv: vid.padStart(48, "0"),
                      },
                    ],
                  }),
                };
              })
            );
          }
          break;
        case "Medium-term trends":
          if (
            currentCharts.length !== (slopes?.mediumSlopes ?? []).length ||
            (slopes?.mediumSlopes ?? []).some((vid, i) => {
              const correspondingChart = currentCharts[i];

              if (!correspondingChart) throw new Error("BUG");

              const initialVariableIds = correspondingChart.store.get(
                Atoms.getInitialStateOnlyVariableTrendLines
              );

              return !R.isDeepEqual([vid], initialVariableIds);
            })
          ) {
            useDisplayedChartsStore.getState().replaceCharts(
              (slopes?.mediumSlopes ?? []).map((vid, _, arr) => {
                return {
                  id: v4(),
                  store: createJotaiStoreForChartSync({
                    zone,
                    initialExpanded: arr.length === 1,
                    variant: ChartVariant.SlopingTrends,
                    initialBatchVariables: [
                      {
                        type: "variable",
                        bv: vid.padStart(48, "0"),
                      },
                    ],
                  }),
                };
              })
            );
          }
          break;
        case "Short-term trends":
          if (
            currentCharts.length !== (slopes?.shortSlopes ?? []).length ||
            (slopes?.shortSlopes ?? []).some((vid, i) => {
              const correspondingChart = currentCharts[i];

              if (!correspondingChart) throw new Error("BUG");

              const initialVariableIds = correspondingChart.store.get(
                Atoms.getInitialStateOnlyVariableTrendLines
              );

              return !R.isDeepEqual([vid], initialVariableIds);
            })
          ) {
            useDisplayedChartsStore.getState().replaceCharts(
              (slopes?.shortSlopes ?? []).map((vid, _, arr) => {
                return {
                  id: v4(),
                  store: createJotaiStoreForChartSync({
                    zone,
                    initialExpanded: arr.length === 1,
                    variant: ChartVariant.SlopingTrends,
                    initialBatchVariables: [
                      {
                        type: "variable",
                        bv: vid.padStart(48, "0"),
                      },
                    ],
                  }),
                };
              })
            );
          }
          break;
        default:
          const _: never = title;
          throw new Error("unreachable");
      }
    },
    [title, variablesArr, slopes, useDisplayedChartsStore, zone, usePbStore]
  );

  if (!slopes) return undefined;

  const { longSlopes, mediumSlopes, shortSlopes } = slopes;

  const numFoldersWithData = [
    longSlopes.length,
    mediumSlopes.length,
    shortSlopes.length,
  ].filter((x) => x > 0).length;

  const enter180Days = () => {
    setSp((s) => {
      const copy = new URLSearchParams(s);
      copy.set(
        DATESTATE_SP_KEYS.AXIS_RANGE_INDEX,
        defaultSlopingTrendsRangeIndex.toString()
      );
      return copy;
    });
  };

  return (
    <div className="flex flex-col">
      <div
        className={cn(
          "text-slate-500 text-[13px] font-semibold mb-1.5 w-full flex justify-between items-center cursor-pointer"
        )}
        onClick={() => setCollapsed((prev) => !prev)}
      >
        <div className="inline-flex items-center">
          <Button size={"icon"} variant={"ghost"}>
            <FaChevronRight
              className={cn(
                "transition-transform",
                !isCollapsed && "rotate-90"
              )}
            />
          </Button>
          <span>Sloping Trends ({numFoldersWithData})</span>
        </div>
      </div>
      {!isCollapsed && (
        <>
          <FolderButton
            active={
              title.type === "slopes" && title.name === "Long-term trends"
            }
            disabled={!variablesArr || !longSlopes.length}
            onClick={
              variablesArr &&
              (() => {
                setTitle({
                  type: "slopes",
                  name: "Long-term trends",
                });
                usePbStore.getState().onSlopingTrendsFolderClick();

                enter180Days();
                useDisplayedChartsStore.getState().replaceCharts(
                  longSlopes.map((vid, _, arr) => {
                    return {
                      id: v4(),
                      store: createJotaiStoreForChartSync({
                        zone,
                        initialExpanded: arr.length === 1,
                        variant: ChartVariant.SlopingTrends,
                        initialBatchVariables: [
                          {
                            type: "variable",
                            bv: vid.padStart(48, "0"),
                          },
                        ],
                      }),
                    };
                  })
                );
              })
            }
          >
            Long-term trends
            <Badge className="py-0 px-2 leading-4 ml-auto" variant={"primary"}>
              {longSlopes.length}
            </Badge>
          </FolderButton>

          <FolderButton
            active={
              title.type === "slopes" && title.name === "Medium-term trends"
            }
            disabled={!variablesArr || !mediumSlopes.length}
            onClick={
              variablesArr &&
              (() => {
                setTitle({
                  type: "slopes",
                  name: "Medium-term trends",
                });
                usePbStore.getState().onSlopingTrendsFolderClick();

                enter180Days();
                useDisplayedChartsStore.getState().replaceCharts(
                  mediumSlopes.map((vid, _, arr) => {
                    return {
                      id: v4(),
                      store: createJotaiStoreForChartSync({
                        zone,
                        initialExpanded: arr.length === 1,
                        variant: ChartVariant.SlopingTrends,
                        initialBatchVariables: [
                          {
                            type: "variable",
                            bv: vid.padStart(48, "0"),
                          },
                        ],
                      }),
                    };
                  })
                );
              })
            }
          >
            Medium-term trends
            <Badge className="py-0 px-2 leading-4 ml-auto" variant={"primary"}>
              {mediumSlopes.length}
            </Badge>
          </FolderButton>
          <FolderButton
            active={
              title.type === "slopes" && title.name === "Short-term trends"
            }
            disabled={!variablesArr || !shortSlopes.length}
            onClick={
              variablesArr &&
              (() => {
                setTitle({
                  type: "slopes",
                  name: "Short-term trends",
                });
                usePbStore.getState().onSlopingTrendsFolderClick();

                enter180Days();
                useDisplayedChartsStore.getState().replaceCharts(
                  shortSlopes.map((vid, _, arr) => {
                    return {
                      id: v4(),
                      store: createJotaiStoreForChartSync({
                        zone,
                        initialExpanded: arr.length === 1,
                        variant: ChartVariant.SlopingTrends,
                        initialBatchVariables: [
                          {
                            type: "variable",
                            bv: vid.padStart(48, "0"),
                          },
                        ],
                      }),
                    };
                  })
                );
              })
            }
          >
            Short-term trends
            <Badge className="py-0 px-2 leading-4 ml-auto" variant={"primary"}>
              {shortSlopes.length}
            </Badge>
          </FolderButton>
        </>
      )}
    </div>
  );
}

function useAnomalyFolders() {
  const [sp] = useSearchParams();
  const ds = useDateState();

  const groups = useGroupsQuery().data;

  const groupShortId = iife(() => {
    const g = sp.get(DRAParamsMap.g);
    if (!g?.trim()) return undefined;
    const short = parseInt(g);
    if (isNaN(short)) return undefined;
    if (short.toString() !== g.trim()) throw new Error("impossible");
    return short;
  });

  const group =
    groups?.find((x) => x.short_id === groupShortId) ??
    groups?.find((g) => g.name === OVERALL_GROUP_NAME) ??
    groups?.[0];

  const variables = group?.variables;

  const query = useAnomalousVariablesQuery(
    {
      varIds: variables,
    },
    ds.axisRangeTo.dateString,
    {
      enabled: !!variables,
      staleTime: minutesToMilliseconds(5),
    }
  );

  const anomalyFolders = useMemo(() => {
    if (!query.data) return undefined;
    return {
      third: query.data.filter(
        (x) => x.level === AnomalyLevelEnum["High Risk"]
      ),
      second: query.data.filter(
        (x) => x.level === AnomalyLevelEnum["Medium Risk"]
      ),
      first: query.data.filter((x) => x.level === AnomalyLevelEnum["Low Risk"]),
    };
  }, [query.data]);

  const freshAnomsQuery = useFreshAnomaliesQuery(
    {
      date: ds.axisRangeTo.dateString,
      deg1array: anomalyFolders?.first.map((x) => x.variableId),
      deg2array: anomalyFolders?.second.map((x) => x.variableId),
      deg3array: anomalyFolders?.third.map((x) => x.variableId),
    },
    {
      enabled: !!anomalyFolders,
      keepPreviousData: true,
      staleTime: minutesToMilliseconds(5),
    }
  );

  const freshAnoms = freshAnomsQuery.data;

  if (!freshAnoms || !anomalyFolders) return undefined;

  return {
    anomalies: anomalyFolders,
    fresh: freshAnoms,
  };
}

function AnomalyFolderButtons() {
  const [, setSp] = useSearchParams();
  const variables = useVariablesArrayQuery().data;
  const anomalyFolders = useAnomalyFolders();
  const orange = anomalyFolders?.anomalies.second;
  const red = anomalyFolders?.anomalies.third;
  const freshAnoms = anomalyFolders?.fresh;
  const usePbStore = useGetUseProfileBookStoreRequired();
  const title = usePbStore((s) => s.title);
  const setTitle = usePbStore((s) => s.setTitle);
  const handleLeavingSlopingTrendsFolder =
    useHandleClickingOnNonSlopingTrendsFolder();
  const chartsInUrl = useChartsInUrl();

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();
  const zone = useTimezone();

  useEffect(
    function handleChange() {
      if (title.type !== "anomalies") return;

      const currentCharts = useDisplayedChartsStore.getState().charts ?? [];

      switch (title.name) {
        case "3rd Degree Tags":
          if (
            (red ?? []).length !== currentCharts.length ||
            (red ?? []).some((anomalyObj, i) => {
              const correspondingChart = currentCharts[i];
              if (!correspondingChart) throw new Error("BUG");

              const initialVariableIds = correspondingChart.store.get(
                Atoms.getInitialStateOnlyVariableTrendLines
              );

              return !R.isDeepEqual(
                [anomalyObj.variableId],
                initialVariableIds
              );
            })
          ) {
            useDisplayedChartsStore.getState().replaceCharts(
              (red ?? []).map((anomalyObj, _, arr) => {
                const store = createJotaiStoreForChartSync({
                  zone,
                  initialExpanded: arr.length === 1,
                  initialBatchVariables: [
                    {
                      type: "variable",
                      bv: anomalyObj.variableId.padStart(48, "0"),
                    },
                  ],
                });
                store.set(Atoms.setSlopingTrendsAtom, {
                  on: usePbStore.getState().showSlopingTrends,
                });
                return {
                  id: v4(),
                  store,
                };
              })
            );
          }

          break;
        case "2nd Degree Tags":
          if (
            (orange ?? []).length !== currentCharts.length ||
            (orange ?? []).some((anomalyObj, i) => {
              const correspondingChart = currentCharts[i];
              if (!correspondingChart) throw new Error("BUG");

              const initialVariableIds = correspondingChart.store.get(
                Atoms.getInitialStateOnlyVariableTrendLines
              );

              return !R.isDeepEqual(
                [anomalyObj.variableId],
                initialVariableIds
              );
            })
          ) {
            useDisplayedChartsStore.getState().replaceCharts(
              (orange ?? []).map((anomalyObj, _, arr) => {
                const store = createJotaiStoreForChartSync({
                  zone,
                  initialExpanded: arr.length === 1,
                  initialBatchVariables: [
                    {
                      type: "variable",
                      bv: anomalyObj.variableId.padStart(48, "0"),
                    },
                  ],
                });
                store.set(Atoms.setSlopingTrendsAtom, {
                  on: usePbStore.getState().showSlopingTrends,
                });
                return {
                  id: v4(),
                  store,
                };
              })
            );
          }
          break;
        case "Fresh 3rd Degree Tags":
          if (
            (freshAnoms?.freshThird ?? []).length !== currentCharts.length ||
            (freshAnoms?.freshThird ?? []).some((vid, i) => {
              const correspondingChart = currentCharts[i];
              if (!correspondingChart) throw new Error("BUG");

              const initialVariableIds = correspondingChart.store.get(
                Atoms.getInitialStateOnlyVariableTrendLines
              );

              return !R.isDeepEqual([vid], initialVariableIds);
            })
          ) {
            useDisplayedChartsStore.getState().replaceCharts(
              (freshAnoms?.freshThird ?? []).map((vid, _, arr) => {
                const store = createJotaiStoreForChartSync({
                  zone,
                  initialExpanded: arr.length === 1,
                  initialBatchVariables: [
                    {
                      type: "variable",
                      bv: vid.padStart(48, "0"),
                    },
                  ],
                });
                store.set(Atoms.setSlopingTrendsAtom, {
                  on: usePbStore.getState().showSlopingTrends,
                });
                return {
                  id: v4(),
                  store,
                };
              })
            );
          }
          break;
        case "Fresh 2nd Degree Tags":
          if (
            (freshAnoms?.freshSecond ?? []).length !== currentCharts.length ||
            (freshAnoms?.freshSecond ?? []).some((vid, i) => {
              const correspondingChart = currentCharts[i];
              if (!correspondingChart) throw new Error("BUG");

              const initialVariableIds = correspondingChart.store.get(
                Atoms.getInitialStateOnlyVariableTrendLines
              );

              return !R.isDeepEqual([vid], initialVariableIds);
            })
          ) {
            useDisplayedChartsStore.getState().replaceCharts(
              (freshAnoms?.freshSecond ?? []).map((vid, _, arr) => {
                const store = createJotaiStoreForChartSync({
                  zone,
                  initialExpanded: arr.length === 1,
                  initialBatchVariables: [
                    {
                      type: "variable",
                      bv: vid.padStart(48, "0"),
                    },
                  ],
                });
                store.set(Atoms.setSlopingTrendsAtom, {
                  on: usePbStore.getState().showSlopingTrends,
                });
                return {
                  id: v4(),
                  store,
                };
              })
            );
          }
          break;
        case "Watchlist":
          return;
        default:
          const _: never = title;
          throw new Error("unreachable");
      }
    },
    [
      title,
      setSp,
      setTitle,
      chartsInUrl,
      freshAnoms,
      red,
      variables,
      orange,
      zone,
      usePbStore,
      useDisplayedChartsStore,
    ]
  );

  if (!anomalyFolders) return undefined;

  return (
    <>
      {freshAnoms && (
        <FolderButton
          active={
            title.type === "anomalies" &&
            title.name === "Fresh 3rd Degree Tags" &&
            freshAnoms.freshThird.length >= 0
          }
          disabled={freshAnoms.freshThird.length === 0}
          onClick={
            variables &&
            (() => {
              setTitle({
                type: "anomalies",
                name: "Fresh 3rd Degree Tags",
              });

              handleLeavingSlopingTrendsFolder();

              useDisplayedChartsStore.getState().replaceCharts(
                freshAnoms.freshThird.map((vid, _, arr) => {
                  const store = createJotaiStoreForChartSync({
                    zone,
                    initialExpanded: arr.length === 1,
                    initialBatchVariables: [
                      {
                        type: "variable",
                        bv: vid.padStart(48, "0"),
                      },
                    ],
                  });
                  store.set(Atoms.setSlopingTrendsAtom, {
                    on: usePbStore.getState().showSlopingTrends,
                  });
                  return {
                    id: v4(),
                    store,
                  };
                })
              );
            })
          }
        >
          Fresh 3rd Degree Tags
          <Badge className="py-0 px-2 leading-4 ml-auto" variant={"primary"}>
            {freshAnoms.freshThird.length}
          </Badge>
        </FolderButton>
      )}
      <FolderButton
        active={
          title.type === "anomalies" &&
          title.name === "3rd Degree Tags" &&
          !(!red || red.length === 0) // disabled
        }
        disabled={!red || red.length === 0}
        onClick={
          red &&
          variables &&
          (() => {
            setTitle({
              type: "anomalies",
              name: "3rd Degree Tags",
            });

            handleLeavingSlopingTrendsFolder();

            useDisplayedChartsStore.getState().replaceCharts(
              red.map((anomalyObj, _, arr) => {
                const store = createJotaiStoreForChartSync({
                  zone,
                  initialExpanded: arr.length === 1,
                  initialBatchVariables: [
                    {
                      type: "variable",
                      bv: anomalyObj.variableId.padStart(48, "0"),
                    },
                  ],
                });
                store.set(Atoms.setSlopingTrendsAtom, {
                  on: usePbStore.getState().showSlopingTrends,
                });
                return {
                  id: v4(),
                  store,
                };
              })
            );
          })
        }
      >
        3rd Degree Tags
        <Badge className="py-0 px-2 leading-4 ml-auto" variant={"primary"}>
          {red?.length ?? 0}
        </Badge>
      </FolderButton>
      {freshAnoms && (
        <FolderButton
          active={
            title.type === "anomalies" &&
            title.name === "Fresh 2nd Degree Tags" &&
            freshAnoms.freshSecond.length >= 0
          }
          disabled={freshAnoms.freshSecond.length === 0}
          onClick={
            variables &&
            (() => {
              setTitle({
                type: "anomalies",
                name: "Fresh 2nd Degree Tags",
              });

              handleLeavingSlopingTrendsFolder();

              useDisplayedChartsStore.getState().replaceCharts(
                freshAnoms.freshSecond.map((vid, _, arr) => {
                  const store = createJotaiStoreForChartSync({
                    zone,
                    initialExpanded: arr.length === 1,
                    initialBatchVariables: [
                      {
                        type: "variable",
                        bv: vid.padStart(48, "0"),
                      },
                    ],
                  });

                  store.set(Atoms.setSlopingTrendsAtom, {
                    on: usePbStore.getState().showSlopingTrends,
                  });
                  return {
                    id: v4(),
                    store,
                  };
                })
              );
            })
          }
        >
          Fresh 2nd Degree Tags
          <Badge className="py-0 px-2 leading-4 ml-auto" variant={"primary"}>
            {freshAnoms.freshSecond.length}
          </Badge>
        </FolderButton>
      )}
      <FolderButton
        active={
          title.type === "anomalies" &&
          title.name === "2nd Degree Tags" &&
          !(!orange || orange.length === 0) // disabled
        }
        disabled={!orange || orange.length === 0}
        onClick={
          orange &&
          variables &&
          (() => {
            setTitle({
              type: "anomalies",
              name: "2nd Degree Tags",
            });

            handleLeavingSlopingTrendsFolder();

            useDisplayedChartsStore.getState().replaceCharts(
              orange.map((anomalyObj, _, arr) => {
                const store = createJotaiStoreForChartSync({
                  zone,
                  initialExpanded: arr.length === 1,
                  initialBatchVariables: [
                    {
                      type: "variable",
                      bv: anomalyObj.variableId.padStart(48, "0"),
                    },
                  ],
                });
                store.set(Atoms.setSlopingTrendsAtom, {
                  on: usePbStore.getState().showSlopingTrends,
                });
                return {
                  id: v4(),
                  store,
                };
              })
            );
          })
        }
      >
        2nd Degree Tags
        <Badge className="py-0 px-2 leading-4 ml-auto" variant={"primary"}>
          {orange?.length ?? 0}
        </Badge>
      </FolderButton>
    </>
  );
}

function useChartsInUrl() {
  type Chart = [number, ...number[]]; // a chart has at least 1 shortId

  const [sp] = useSearchParams();

  const dv = sp.get(DRAParamsMap.dv)?.trim() || "";

  return useMemoOne(() => {
    const charts: Chart[] = dv
      .split(",")
      .map((chart) => {
        const shortIdsForOneChart = chart
          .split("-")
          .map((shortId) => parseInt(shortId))
          .filter((x) => !isNaN(x));

        if (!minLen1(shortIdsForOneChart)) return undefined;

        return shortIdsForOneChart;
      })
      .filter((x) => x !== undefined);

    return charts;
  }, [dv]);
}

function useHandleClickingOnNonSlopingTrendsFolder() {
  const usePbStore = useGetUseProfileBookStoreRequired();
  const isSlopingTrendsCurrently = usePbStore((s) => s.title.type === "slopes");
  const [, setSp] = useSearchParams();

  return () => {
    isSlopingTrendsCurrently &&
      setSp((curr) => {
        const copy = new URLSearchParams(curr);
        copy.set(
          DATESTATE_SP_KEYS.AXIS_RANGE_INDEX,
          defaultRangeIndex.toString()
        );
        return copy;
      });
  };
}

export {
  PersonalFolders,
  GroupsFolders,
  ClusterFolders,
  SlopingTrendsFolders,
  AnomaliesAndWatchlistFolders,
};

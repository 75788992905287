import { ComponentProps, useEffect, useRef, useState } from "react";
import * as R from "remeda";
import { SecondaryVariableViewNeedsProvider } from "./generic-secondary-variable-view";
import {
  useOperatingLimitsQueries,
  useOperatingLimitsQuery,
  useSlopes,
} from "../../../hooks/tanstack-query";
import { useMemo } from "use-memo-one";
import { DateTime } from "luxon";
import { YYYY_MM_DD } from "../../../lib/validators";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { useAtomValue, useStore } from "jotai";
import { Atoms } from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useEnterSlopingTrends, useExitSlopingTrends } from "./bottom-controls";
import { useGetUseProfileBookStoreNotRequired } from "../../pb/use-profile-book-store";
import { isVariableVariant } from "../../../shared-ui/time-series-2/draw/draw";
import { useTzAwareDateStateDomain } from "./chart-range/use-tz-aware-date-state-domain";
import { minutesToMilliseconds } from "date-fns";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";

/**
 * Given a date that looks like `2024-07-01T00:00:00.000Z` (12AM UTC),
 * return a date that is 12 AM in the timezone provided
 *
 * For EST this would return `2024-07-01T04:00:00.000Z`
 *
 * UTC is 4 hours ahead of EST, so EST's 12AM happens 4 hours later
 */
function utcToLocal(d: Date | string | number, tz: string) {
  return DateTime.fromJSDate(new Date(d), {
    zone: "utc",
  })
    .setZone(tz, {
      keepLocalTime: true, // keep it 7/1/2024 12AM in the timezone provided
    })
    .toMillis();
}

type WrappedComponentProps = ComponentProps<
  typeof SecondaryVariableViewNeedsProvider
>;

type DontPassOn = keyof Pick<
  WrappedComponentProps,
  | "stillUseColorForAnomalyColorationOff"
  | "overrideXAxis"
  | "loading"
  | "slopes"
  | "limitsMap"
>;

type AvailableProps = Omit<WrappedComponentProps, DontPassOn>;

/**
 * We'll use this hook to decide what to query.
 *
 * We don't necessarily want to query the entire range of the chart
 * due to zooming and resolutions.
 */
function useDebouncedEffectiveRangeForTimeseriesQuery(delay = 1000) {
  const jotaiStore = useStore();
  const [domain, setDomain] = useState(() => {
    /**
     * This is the original domain of the chart unzoomed.
     *
     * When this hook initializes, we want to use the original
     * domain of the chart.
     */
    const initial = jotaiStore.get(Atoms.getDomainAtom);
    return initial;
  });

  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const drawnDomainAtom = useAtomValue(Atoms.drawnDomainAtomAtom);

  useEffect(
    function handleDomainUpdateDueToZoomingButDebounceIt() {
      // immediately sync because it changed
      setDomain(jotaiStore.get(drawnDomainAtom));

      const debounced = () => {
        clearTimeout(timerRef.current);
        /**
         * drawnDomain will tell us what the user has zoomed into.W
         * hen it updates, sync it with our local state, but debounce
         */
        timerRef.current = setTimeout(() => {
          const newDrawnDomain = jotaiStore.get(drawnDomainAtom);
          newDrawnDomain && setDomain(newDrawnDomain);
        }, delay);
      };

      const cleanup = jotaiStore.sub(drawnDomainAtom, debounced);

      return () => {
        cleanup();
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      };
    },
    [jotaiStore, delay, drawnDomainAtom]
  );

  return domain;
}

function useSyncWithGlobalSlopingTrendsToggle() {
  const s = useStore();
  const enterSlopingTrendsView = useEnterSlopingTrends();
  const exitSlopingTrendsView = useExitSlopingTrends();

  /**
   * not required because this chart isn't exclusively
   * rendered on pb, but if it is we need to sync up
   */
  const useProfileBookStore = useGetUseProfileBookStoreNotRequired();
  useEffect(
    function syncIfWeAreOnProfileBookPage() {
      // use presence of store to determine if we're on pb
      if (!useProfileBookStore) return;

      const clean = useProfileBookStore.subscribe(
        (s) => s.showSlopingTrends,
        (isSlopingTrendsViewGlobal) => {
          isSlopingTrendsViewGlobal
            ? enterSlopingTrendsView()
            : exitSlopingTrendsView();
        }
      );

      return clean;
    },
    [useProfileBookStore, s, enterSlopingTrendsView, exitSlopingTrendsView]
  );
}

export function useSlopesForDrawFunctionIfEnabled(enabledOverride = true) {
  const [start, end] = useAtomValue(Atoms.getDomainAtom);
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const primaryIsVariableTrendLine = isVariableVariant(primaryTrendLine);

  const isPossibleToShowSlopingTrends = primaryIsVariableTrendLine;

  const stEnabled =
    useAtomValue(Atoms.slopingTrendsAtom) && isPossibleToShowSlopingTrends;

  const tz = useTimezone();

  const slopesQuery = useSlopes(
    primaryIsVariableTrendLine
      ? primaryTrendLine.bv.slice(24)
      : "dummy-value-not-used",
    YYYY_MM_DD.parse(DateTime.fromMillis(start, { zone: tz }).toISODate()),
    YYYY_MM_DD.parse(DateTime.fromMillis(end, { zone: tz }).toISODate()),
    {
      enabled: stEnabled && enabledOverride,
      staleTime: minutesToMilliseconds(10),
    }
  );

  return useMemo(() => {
    if (!slopesQuery.data || !stEnabled || !enabledOverride) return undefined;

    const slopes = slopesQuery.data.map((x) => {
      const first = x.data[0];
      const last = x.data[x.data.length - 1] ?? x.data[1];

      type Point = {
        t: number;
        v: number;
      };

      const out: { extent: [Point, Point]; _id: string; anom: number } = {
        anom: x.anom,
        extent: [
          {
            t: utcToLocal(first.date, tz),
            v: first.value,
          },
          {
            t: utcToLocal(last.date, tz),
            v: last.value,
          },
        ],
        _id: x._id,
      };

      return out;
    });

    return slopes;
  }, [slopesQuery.data, tz, stEnabled, enabledOverride]);
}

/**
 * Use this if you want to provide your own store provider. See
 * the component below for an example of how to provide your
 * own store
 *
 * If you don't want to provide your own store provider, use the
 * component below.
 */
function GenericSecondaryVariableViewNeedsProvider(
  props: Readonly<AvailableProps>
) {
  useSyncWithGlobalSlopingTrendsToggle();

  const s = useStore();
  const [tzAwareStart, tzAwareEnd] = useTzAwareDateStateDomain(); // a derivate of datestate, will change when datestate does

  const prevDomain = useRef<[number, number]>([tzAwareStart, tzAwareEnd]);

  /**
   * If the date state changes, we must sync up
   * the chart with the new date state.
   *
   * It must be done using a ref so that we do not
   * run it on mount. We only want to run it when
   * the date state changes after the component has
   * already mounted.
   *
   * As a counter-example, let's say you have this chart on some
   * page and you change the `local` date range to 34 days
   * (arbitrary). Then you click the FS button.
   *
   * What happens is the FS component is actually
   * just a copy of this component rendered in a Dialog.
   *
   * It receives the same exact store, so we do see its
   * 34 days (for a split second), but the useEffect kicks
   * in and sets it back to the date state's domain, which
   * is not 34 days (impossible). It is probably 30 or
   * whatever the default is.
   *
   * So, we're trying to avoid the syncing behavior in
   * some instances where it doesnt make sense, that is:
   * on mount of this component.
   */
  useEffect(
    function handleDateStateChange() {
      const prev = prevDomain.current;

      const dateStateHasChanged =
        prev[0] !== tzAwareStart || prev[1] !== tzAwareEnd;

      if (dateStateHasChanged) {
        const d = s.get(Atoms.getDomainAtom);
        const isDifferingFromChartState =
          d[0] !== tzAwareStart || d[1] !== tzAwareEnd;
        isDifferingFromChartState &&
          s.set(Atoms.setDomainAtom, [tzAwareStart, tzAwareEnd]);
      }

      prevDomain.current = [tzAwareStart, tzAwareEnd];
    },
    [tzAwareStart, tzAwareEnd, s]
  );

  const [start, end] = useAtomValue(Atoms.getDomainAtom);
  const canRequestData = useAtomValue(Atoms.chartInViewportAtom);
  const showLimitLines = useAtomValue(Atoms.showLimitLinesAtom);

  const allBvs = useAtomValue(Atoms.allBvsAtom);
  const allVids = useMemo(() => allBvs.map((x) => x.slice(24)), [allBvs]);

  const atLeastOneVariableTrendLine = allVids.length > 0;

  const opts = useMemo(() => {
    return {
      enabled: showLimitLines && canRequestData && atLeastOneVariableTrendLine,
      staleTime: minutesToMilliseconds(5),
    } as const;
  }, [showLimitLines, canRequestData, atLeastOneVariableTrendLine]);

  const olQueries = useOperatingLimitsQueries(allVids, opts);

  const tz = useTimezone();

  const stData = useSlopesForDrawFunctionIfEnabled(canRequestData);

  const overrideXAxis = useMemo(
    (): [number, number] => [start, end],
    [start, end]
  );

  const data = olQueries.map((x) => x.data);

  const almost = useMemo(() => {
    for (const x of data) {
      if (!x) return undefined;
      // all or nothing
    }

    const tuples = data
      .filter((x) => x !== undefined)
      .filter((x) => x.length > 0)
      .map((limits) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const vid = limits[0]!.variableId;

        return [
          vid,
          assertMinLen1(
            limits.map((x) => {
              return {
                level: x.level,
                data: x.data.map((y): typeof y => ({
                  end: y.end === null ? null : utcToLocal(y.end, tz),
                  start: utcToLocal(y.start, tz),
                  value: y.value,
                })),
              };
            })
          ),
        ] as const;
      });

    /**
     * Keys are variableIds, values are the limits formatted the way we need
     */
    const limitsMap = R.fromEntries(tuples);

    const out: typeof limitsMap = {};

    for (const bv of allBvs) {
      const vid = bv.slice(24);

      const limits = limitsMap[vid];

      if (!limits) continue;

      out[bv] = limits; // i need them keyed by bvs
    }

    return out;
  }, [allBvs, ...data]);

  return (
    <SecondaryVariableViewNeedsProvider
      limitsMap={showLimitLines ? almost : undefined}
      loading={false}
      stillUseColorForAnomalyColorationOff
      overrideXAxis={overrideXAxis}
      slopes={stData}
      {...props}
    />
  );
}

export {
  GenericSecondaryVariableViewNeedsProvider,
  useDebouncedEffectiveRangeForTimeseriesQuery,
  type AvailableProps,
};

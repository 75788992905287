import {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
  PropsWithChildren,
  Fragment,
} from "react";
import PetalChart from "../../charts/PetalChart";
import Footer from "../../nav/Footer";
import useAPI from "../../../lib/useAPI";
import useResizeObserver from "../../common/useResizeObserver";
import debounce from "../../common/debounce";
import PetalLegend from "../petal-legend";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import MainLayout from "../../layouts/MainLayout";
import _ from "lodash";
import { FaArrowRight, FaDownload } from "react-icons/fa";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import useCurrentUnitObject from "../../common/hooks/useCurrentUnitObject";
import { useDateState } from "../../../zustand/useDateState";
import { LinkWithQuery } from "../../nav/LinkWithQuery2";
import { useUnitOverviewQuery } from "./use-unit-ov-query";
import { motion } from "framer-motion";
import { Button } from "../../../shared-ui/frontend/button";
import { cn } from "../../../lib/utils";
import { ViewModeSelectors } from "../../common/view-mode-selectors";
import { useGetUseViewModeStore } from "../../../shared-ui/time-series-2/grid-view-store";
import { useGroupsWithSections } from "../../groups/manager/use-groups-with-sections";
import { Sections } from "../../../lib/api-schema/sections";
import { ChevronRight, Maximize2, Minimize2 } from "lucide-react";
import { atom, Provider, useSetAtom, useStore } from "jotai";
import { PropsWithCn } from "../../../shared-ui/frontend/cn";

/**
 * don't care about the count, just need
 * something to signal changes.
 */
export const expandCountAtom = atom(0);
export const collapseCountAtom = atom(0);

function UnitOverview() {
  const groupsWithSections = useGroupsWithSections();

  const api = useAPI();
  const currentUnit = useCurrentUnitObject();
  const unitName = useBaseUrlExperimental();

  const groups = useUnitOverviewQuery();
  const [petalChartDimensions, setPetalChartDimensions] = useState<
    [number, number]
  >([0, 0]);

  // const sections = useSectionsQuery().data;

  const {
    axisRangeFrom: { dateString: axisFrom },
    axisRangeTo: { dateString: axisTo },
  } = useDateState();

  const pageTitle = `${currentUnit?.shortName.toUpperCase() ?? ""} Overview`;

  useDocumentTitle(pageTitle, [pageTitle]);

  const useViewModeStore = useGetUseViewModeStore();
  const viewMode = useViewModeStore((s) => s.viewMode);
  const setViewMode = useViewModeStore((s) => s.setViewMode);
  const numCols = useViewModeStore((s) => s.numCols);
  const setNumCols = useViewModeStore((s) => s.setNumCols);

  useEffect(() => {
    const numGroups = groups?.length || 0;
    const dynamicallySetNumCols = (screenWidth: number, numGroups: number) => {
      if (screenWidth < 768) {
        setNumCols(2);
      } else if (screenWidth < 1024) {
        setNumCols(3);
      } else if (numGroups < 20) {
        setNumCols(4);
      } else {
        setNumCols(5);
      }
    };

    const handleResize = () => {
      dynamicallySetNumCols(window.innerWidth, numGroups);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [groups, setNumCols]);

  // the next 3 consts are used to resize petal charts when we resize
  const firstPetalRef = useRef<HTMLDivElement>();
  const debouncedSetPetalDims = useMemo(
    () =>
      debounce((elt: HTMLDivElement) => {
        const { clientWidth } = elt;
        setPetalChartDimensions([clientWidth, clientWidth / 2]);
      }, 200),
    []
  );
  useResizeObserver(firstPetalRef, debouncedSetPetalDims);

  // this is used to set the initial petal chart size, after its container renders
  // useCallback is necessary so that react calls it once
  // passing the arrow function to ref instead will cause it to be called infinitely
  const measuredRef = useCallback((elt: HTMLDivElement | null) => {
    if (!elt) {
      return;
    }
    firstPetalRef.current = elt;
    const { clientWidth } = elt;
    setPetalChartDimensions((curr) => {
      if (curr[0] === clientWidth) {
        return curr;
      }
      return [clientWidth, clientWidth / 2];
    });
  }, []);

  async function downloadRisks() {
    const start = axisFrom;
    const end = axisTo;
    if (!groups) return;

    const data = await Promise.all(
      groups.map((g) => api.getRisks(g._id, start, end))
    ).then((risks) => {
      const groupIdToRisksArray = _.reduce(
        groups,
        (obj, group, idx) => {
          obj[group._id] = risks[idx];
          return obj;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {} as Record<string, any>
      );

      return groupIdToRisksArray;
    });

    let csvContent = "";

    for (let g of groups) {
      const key = g._id;
      if (csvContent == "") {
        csvContent += ",";
        for (var i = 0; i < data[key].length; i++) {
          if (i == data[key].length - 1) {
            csvContent += data[key][i].date.split("T")[0] + "\r\n";
          } else {
            csvContent += data[key][i].date.split("T")[0] + ",";
          }
        }
      }
      if (data.hasOwnProperty(key)) {
        csvContent +=
          '"' +
          g.name +
          '",' +
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data[key].map(function (item: any) {
            return item.index;
          }) +
          "\r\n";
      }
    }

    // @ts-ignore
    const downloadCSV = (content, fileName, mimeType) => {
      const a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";
      // @ts-ignore
      if (navigator.msSaveBlob) {
        // IE10
        // @ts-ignore
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    };

    downloadCSV(csvContent, "dri_list.csv", "text/csv;encoding:utf-8");
  }

  const oneChart = (
    g: NonNullable<typeof groups>[number],
    shouldRef: boolean
  ) => (
    <motion.div
      initial={{ opacity: 0.3, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      key={g._id}
      // className="2xl:col-span-3 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 group/container shadow-md"
      className="group/container shadow-md"
    >
      <div
        className="card bg-white dark:bg-xslate-3 rounded-md border group-hover/container:border-xslate-7 border-xslate-6 group-hover/container:shadow-xl shadow-sm"
        ref={shouldRef ? measuredRef : undefined}
      >
        <LinkWithQuery
          className="card-body pb-2 pt-2 px-2 hover:cursor-pointer hover:bg-zinc-200 hover:dark:bg-xslate-4 group/petaltitle"
          set={{ g: g.short_id.toString() }}
          pick={{
            d: true,
            mo: true,
            y: true,
            z: true,
            cd: true,
          }}
          to={"../dri"}
          relative="path"
        >
          <div className="flex justify-between">
            <div
              className={cn(
                "text-xslate-11 max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-sm leading-none"
              )}
            >
              {g.name.toUpperCase()}
            </div>
            <div className="invisible group-hover/petaltitle:visible">
              <FaArrowRight />
            </div>
          </div>
        </LinkWithQuery>
        <PetalChart
          className={"bg-white dark:bg-xslate-2"}
          style={{ slim: true }}
          groupId={g._id}
          width={petalChartDimensions[0]}
          height={petalChartDimensions[1]}
          unitName={unitName.replace("/", "")}
          scaleText={undefined}
        />
      </div>
    </motion.div>
  );

  const shouldRefFirstRemainingGroup = groupsWithSections
    ? groupsWithSections.remainingGroups.length > 0
    : false;

  const shouldRefFirstSectionGroup = !shouldRefFirstRemainingGroup;

  const showExpandCollapseAll =
    groupsWithSections && groupsWithSections.sectionsWithGroups.length > 0;

  return (
    <div className="flex flex-col min-h-screen">
      <MainLayout showDateNav={true}>
        <div className="flex justify-between py-2 items-center mb-1 px-4 gap-1">
          <span className="tracking-tight text-2xl font-semibold page-header">
            {pageTitle}
          </span>
          <ViewModeSelectors
            withLabels
            className={"ml-auto"}
            variant={"default"}
            enabledModes={["grid"]}
            viewMode={viewMode}
            setViewMode={setViewMode}
            numCols={numCols}
            setNumCols={setNumCols}
          />
          <PetalLegend bordered />
        </div>
        {/* the page content begins here  */}
        {groupsWithSections && (
          <div className="px-8 flex flex-col gap-3">
            {groupsWithSections.remainingGroups.length > 0 && (
              <>
                <div
                  className={`grid grid-cols-${numCols} auto-rows-max gap-8 my-4`}
                >
                  {groupsWithSections.remainingGroups.map((g, i) => (
                    <Fragment key={g._id}>
                      {oneChart(g, i === 0 && shouldRefFirstRemainingGroup)}
                    </Fragment>
                  ))}
                </div>
                <HorizontalLine />
              </>
            )}
            {groupsWithSections?.sectionsWithGroups.map(
              ({ section, groups }, idx) => {
                return (
                  <SectionContainer key={section._id} section={section}>
                    <div
                      className={`grid grid-cols-${numCols} auto-rows-max gap-8 my-4`}
                    >
                      {groups.map((g, i) => (
                        <Fragment key={g._id}>
                          {oneChart(
                            g,
                            shouldRefFirstSectionGroup && idx === 0 && i === 0
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </SectionContainer>
                );
              }
            )}

            <div className="flex flex-row gap-3 place-items-center">
              {showExpandCollapseAll && (
                <>
                  <ExpandAllButton />
                  <CollapseAllButton />
                </>
              )}
              <Button variant={"outline"} onClick={downloadRisks} size="xs">
                <FaDownload className="size-3 mr-2" />
                {`Download DRI values (${groupsWithSections.remainingGroups.length + groupsWithSections.sectionsWithGroups.reduce((sum, x) => sum + x.groups.length, 0)} groups)`}
              </Button>
            </div>
          </div>
        )}
      </MainLayout>

      <Footer />
    </div>
  );
}

export function ExpandAllButton({ className }: PropsWithCn) {
  const setCount = useSetAtom(expandCountAtom);
  return (
    <Button
      size={"xs"}
      variant={"outline"}
      onClick={() => setCount((c) => c + 1)}
      className={className}
    >
      <Maximize2 className="mr-1 size-3.5" />
      Expand All Sections
    </Button>
  );
}

export function CollapseAllButton({ className }: PropsWithCn) {
  const setCount = useSetAtom(collapseCountAtom);
  return (
    <Button
      size={"xs"}
      variant={"outline"}
      onClick={() => setCount((c) => c + 1)}
      className={className}
    >
      <Minimize2 className="mr-1 size-3.5" />
      Collapse All Sections
    </Button>
  );
}

export function SectionContainer({
  section,
  children,
  hasHorizontalLine = true,
}: PropsWithChildren<{
  section: Sections["sections"][number];
  hasHorizontalLine?: boolean;
}>) {
  const [open, setOpen] = useState(true);

  const j = useStore();

  useEffect(() => {
    const c1 = j.sub(expandCountAtom, () => {
      setOpen(true);
    });
    const c2 = j.sub(collapseCountAtom, () => {
      setOpen(false);
    });

    return () => {
      c1();
      c2();
    };
  }, [j, setOpen]);

  return (
    <>
      <div className="flex items-end gap-2">
        <span className="text-xl tracking-tighter leading-8">
          {section.name}
        </span>
        <Button
          className="text-xslate-10"
          variant={"ghost"}
          size={"icon"}
          onClick={() => setOpen((c) => !c)}
          type="button"
        >
          <ChevronRight className={cn("size-4", open && "rotate-90")} />
        </Button>
      </div>
      {open && (
        <>
          {children}
          {hasHorizontalLine && <HorizontalLine />}
        </>
      )}
    </>
  );
}

function HorizontalLine() {
  return <div className="h-[1px] bg-xslate-6" />;
}

export default function Wrapped() {
  return (
    <Provider>
      <UnitOverview />;
    </Provider>
  );
}

import {
  FaultTreeDetailsStore,
  useGetUseFaultTreeDetailsStore,
} from "../details/ft-details-store";
import { useGetOnTreeNodeClick } from "../details/use-get-tree-node-data";
import { FaultTreeNodeFiles } from "../editor/use-ft-node-files-query";
import { FilesPills } from "../editor/files-pills";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import { useNavigate } from "react-router";
import useBaseUrl from "../../../zustand/useBaseUrl";
import { variableSchema } from "../../../lib/api-validators";

type SetSelectedNode = (nodeId: string) => void | Promise<void>;

function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function splitStringWithDelimiters(str: string, delimiters: string[]) {
  // Sort delimiters array in descending order of length
  const escapedDels = [...delimiters]
    .sort((a, b) => b.length - a.length)
    .map((s) => escapeRegExp(s));

  // Build regex pattern from delimiters array
  const pattern = new RegExp(`(${escapedDels.join("|")})`);

  // Split string using regex pattern
  return str.split(pattern);
}

const linkNodes = (
  expression: string,
  nodes: NonNullable<FaultTreeDetailsStore["tree"]>["nodes"],
  setSelectedNode: SetSelectedNode,
  externalNodes?: variableSchema[],
  setExternalNode?: (faultTreeId: string, nodeId: string) => void
) => {
  const nodeNames = nodes.map((node) => `"${node.name}"`);
  const parts = splitStringWithDelimiters(expression, nodeNames);
  const externalNodeNames =
    externalNodes?.map((node) => `"${node.name}"`) || [];
  return parts.flatMap((part, i) => {
    const includes = nodeNames.includes(part);
    if (!includes) {
      // check if part contains an external node
      const parts2 = splitStringWithDelimiters(part, externalNodeNames);
      return parts2.map((part2, j) => {
        const includes2 = externalNodeNames.includes(part2);
        return (
          <span
            key={`part-${i}-${j}`}
            onClick={
              includes2
                ? () => {
                    const withoutQuotes = part2.slice(1, -1);
                    const node = externalNodes?.find(
                      (n) => n.name === withoutQuotes
                    );
                    node &&
                      setExternalNode &&
                      setExternalNode(
                        node.ft_node_fault_tree_id || "",
                        node._id
                      );
                  }
                : undefined
            }
            className={
              includes2 ? "text-blue-600 cursor-pointer underline" : undefined
            }
          >
            {part2}
          </span>
        );
      });
    }

    return (
      <span
        key={`part-${i}`}
        onClick={() => {
          const withoutQuotes = part.slice(1, -1);
          const node = nodes.find((n) => n.name === withoutQuotes);
          node && setSelectedNode(node._id);
        }}
        className={"text-blue-600 cursor-pointer underline"}
      >
        {part}
      </span>
    );
  });
};

export const FaultTreeNodeDetailsInfo2 = () => {
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();
  const selectedNode = useFaultTreeDetailsStore((s) => s.selectedNode);
  const nodes = useFaultTreeDetailsStore((s) => s.tree?.nodes);
  const externalNodes = useVariablesArrayQuery().data?.filter(
    (v) =>
      v.type === "FaultTreeNode" &&
      v.ft_node_fault_tree_id !== selectedNode?.faultTreeId
  );
  const setSelectedNode = useGetOnTreeNodeClick();
  const navigate = useNavigate();
  const b = useBaseUrl();
  const setExternalNode = (faultTreeId: string, nodeId: string) => {
    const params = new URLSearchParams(window.location.search);
    params.set("selectednode", nodeId);
    navigate(`${b}/ft/details/${faultTreeId}?${params}`);
  };

  if (!selectedNode) throw new Error("dont render this component if no node");
  const { expression, recommendation } = selectedNode;

  return (
    <div
      className={
        "faultTree-nodeDetails-info bg-white border border-bordgrey2 mt-3 rounded-lg"
      }
    >
      <div className={"faultTree-nodeDetails-info-label"}>Node expression</div>
      <div className={"mono faultTree-nodeDetails-info-expression"}>
        {nodes &&
          linkNodes(
            expression,
            nodes,
            setSelectedNode,
            externalNodes,
            setExternalNode
          )}
      </div>
      {recommendation && (
        <>
          <br />
          <div className={"faultTree-nodeDetails-info-label"}>
            Recommendation
          </div>
          <div className={"faultTree-nodeDetails-info-recommendation"}>
            {recommendation}
          </div>
        </>
      )}
      <FilesSection />
    </div>
  );
};

function FilesSection() {
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();
  const selectedNode = useFaultTreeDetailsStore((s) => s.selectedNode);
  const filesQuery = FaultTreeNodeFiles.useQuery(selectedNode?._id);

  if (filesQuery.isError) throw filesQuery.error;

  if (!selectedNode || !filesQuery.data) return null;

  if (filesQuery.data.length === 0) return null;

  return (
    <>
      <br />
      <div className={"faultTree-nodeDetails-info-label"}>Files</div>
      <div className={"faultTree-nodeDetails-info-selectedNode"}>
        <FilesPills files={filesQuery.data} className="mt-1" />
      </div>
    </>
  );
}

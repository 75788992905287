import { AtSign, Check } from "lucide-react";
import { FaHashtag, FaTag } from "react-icons/fa";
import { useEffect, useId, useRef, useState } from "react";
import { Button } from "../../../../shared-ui/frontend/button";
import { Textarea } from "../../../../shared-ui/frontend/text-area";
import * as C from "../../../../shared-ui/frontend/comment-form-components";
import { cn, iife } from "../../../../lib/utils";
import { MultiSelect } from "../../../../shared-ui/frontend/multi-select";
import {
  useLabelsQuery,
  useTeamsQuery,
  useUsersQuery,
  useVariablesArrayQuery,
} from "../../../../hooks/tanstack-query";
import { getFullName } from "../../../../shared-ui/lib/user-utils";
import { useStore } from "zustand";
import { useTimezone } from "../../../../zustand/config/useConfigStore";
import { DateTime } from "luxon";
import { useAtomValue } from "jotai";
import { Atoms } from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { Controller, useForm } from "react-hook-form";
import { useUserRequired } from "../../../../zustand/auth/useAuthStore";
import { addUnknownErrorToast } from "../../../toast/use-toast-store";
import Color from "color";

type Base = {
  comment: string;
  taggedUsers: string[];
  taggedLabels: string[];
  taggedVariables: [string, ...string[]];
  taggedTeams: string[];
  issueResolution: boolean;
};

// stored internally
type FormState = Base & {
  isIssue: boolean;
  isMyNote: boolean;
};

// what this component returns to consumer
type SubmitData = Base & {
  type: "Comment" | "Issue" | "Private";
};

type OnCommentCreate = (data: SubmitData) => void;

function GenericCommentForm({
  close,
  onSubmit,
  isLoading,
  defaults,
  placeholder,
  className,
  onIssueToggle,
  showIssueAndMyNote,
  showRangeLabel,
  showIssueCloseOrReopenButton,
  closeLabel,
  noPortalMultiSelects,
  isPrivate,
}: {
  close?: () => void;
  onSubmit: OnCommentCreate;
  isLoading: boolean;
  defaults: Pick<
    FormState,
    | "taggedLabels"
    | "taggedUsers"
    | "taggedVariables"
    | "taggedTeams"
    | "comment"
    | "issueResolution"
  >;
  placeholder?: string;
  className?: string;
  onIssueToggle?: (isIssue: boolean) => void;
  showIssueAndMyNote?: boolean;
  showRangeLabel?: boolean;
  showIssueCloseOrReopenButton?: boolean;
  closeLabel?: string;
  noPortalMultiSelects?: true;
  isPrivate?: boolean;
}) {
  const { register, handleSubmit, control, resetField, watch, setValue } =
    useForm<FormState>({
      shouldUseNativeValidation: true,
      defaultValues: {
        taggedLabels: defaults.taggedLabels,
        taggedUsers: defaults.taggedUsers,
        taggedVariables: defaults.taggedVariables,
        taggedTeams: defaults.taggedTeams,
        comment: defaults.comment,
        isIssue: false,
        isMyNote: false,
        issueResolution: defaults.issueResolution,
      } satisfies FormState, // it's a good idea to provide default values for all fields, react-hook-form isn't the most type-safe as I've found.
    });

  const variablesQuery = useVariablesArrayQuery();
  const usersQuery = useUsersQuery();
  const labelsQuery = useLabelsQuery();
  const teamsQuery = useTeamsQuery();
  const users = usersQuery.data;
  const labels = labelsQuery.data;
  console.log("labels are", labels);
  const variables = variablesQuery.data;
  const teams = teamsQuery.data;

  const [showMultiSelects, setShowMultiSelects] = useState({
    taggedUsersAndTeams:
      defaults.taggedUsers.length > 0 && defaults.taggedTeams.length > 0,
    taggedLabels: defaults.taggedLabels.length > 0,
    taggedVariables: defaults.taggedVariables.length > 1, // only if its more than the default vid
  } satisfies Partial<{ [K in keyof FormState]: boolean }> & {
    taggedUsersAndTeams: boolean;
  });

  const onSubmitForm = handleSubmit((data) => {
    const {
      comment,
      isIssue,
      isMyNote,
      taggedLabels,
      taggedTeams,
      taggedUsers,
      taggedVariables,
      issueResolution,
    } = data;

    const trimmed = comment.trim();

    if (trimmed.length === 0) {
      return addUnknownErrorToast("Comment cannot be empty");
    }

    if (taggedVariables.length < 1) throw new Error("Impossible");

    onSubmit({
      issueResolution,
      comment,
      taggedLabels,
      taggedTeams,
      taggedUsers,
      taggedVariables,
      type: iife(() => {
        if (isIssue) return "Issue";
        if (isMyNote) return "Private";
        return "Comment";
      }),
    });
  });

  const ref = useRef<HTMLFormElement>(null);
  useEffect(function scrollTextAreaIntoViewOnMount() {
    const form = ref.current;

    if (!form) throw new Error("no form");

    const r = form.getBoundingClientRect();

    if (r.bottom > window.innerHeight) {
      form.scrollIntoView({ behavior: "instant", block: "end" });
    }

    form.focus();
  }, []);

  const me = useUserRequired();
  const { first: firstName, last: lastName } = me;

  const onMultiSelectToggle = (key: keyof typeof showMultiSelects) => {
    setShowMultiSelects((prev) => {
      if (!prev[key]) {
        if (key !== "taggedUsersAndTeams") {
          resetField(key); // if multi select is closed then reset field
        } else {
          resetField("taggedUsers");
          resetField("taggedTeams");
        }
      }
      return { ...prev, [key]: !prev[key] };
    });
  };

  /**
   * we need to subscribe to changes on these because we conditionally
   * render the disabled props below, based on these values.
   */
  const isIssue = watch("isIssue");
  const isMyNote = watch("isMyNote");

  useEffect(() => {
    onIssueToggle?.(isIssue);
  }, [isIssue, onIssueToggle]);

  const specialButtonId = useId();

  return (
    <C.Form
      className={className}
      onSubmit={(event) => {
        try {
          const sE = event.nativeEvent as SubmitEvent;
          const btn = sE.submitter as HTMLButtonElement;
          const didClickIssueSubmitButton = btn.id === specialButtonId;

          didClickIssueSubmitButton &&
            setValue("issueResolution", !defaults.issueResolution);
        } catch {}
        onSubmitForm(event);
      }}
      ref={ref}
    >
      <div className="flex items-center gap-3">
        <div
          className={`bg-zinc-300 text-base-100 rounded-full size-10 grid place-content-center shrink-0`}
        >
          <span className="text-lg">
            {firstName[0]?.toUpperCase()}
            {lastName[0]?.toUpperCase()}
          </span>
        </div>
        <span className="text-sm font-medium">{getFullName(me)}</span>
        {showRangeLabel && (
          <span className="text-sm tracking-tight font-medium text-xslate-11 ml-auto">
            <RangeLabel />
          </span>
        )}
      </div>

      <Textarea
        {...register("comment", {
          required: true,
          minLength: 1,
          disabled: isLoading,
        })}
        className="bg-white dark:bg-transparent my-4"
        placeholder={placeholder ?? "Your comment..."}
      />
      {users && teams && showMultiSelects.taggedUsersAndTeams && (
        <>
          <Controller
            control={control}
            disabled={isLoading}
            name="taggedUsers"
            render={({ field: { ref, onChange, value, ...rest } }) => {
              return (
                <MultiSelect
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                      maxHeight: "200px",
                      overflowY: "hidden",
                    }),
                  }}
                  isDisabled={isMyNote}
                  noPortal={noPortalMultiSelects}
                  {...rest}
                  closeMenuOnSelect={false}
                  options={users.map((x) => ({
                    label: getFullName(x),
                    value: x._id,
                  }))}
                  onChange={(users) => {
                    onChange(users.map((x) => x.value));
                  }}
                  value={value
                    .map((id) => {
                      const user = users.find((x) => x._id === id);
                      return (
                        user && { label: getFullName(user), value: user._id }
                      );
                    })
                    .filter((x) => x !== undefined)}
                  forwardedRef={ref}
                  placeholder="Select users"
                />
              );
            }}
          />
          <Controller
            control={control}
            disabled={isLoading}
            name="taggedTeams"
            render={({ field: { ref, onChange, value, ...rest } }) => {
              return (
                <MultiSelect
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                      maxHeight: "200px",
                      overflowY: "hidden",
                    }),
                  }}
                  noPortal={noPortalMultiSelects}
                  isDisabled={isMyNote}
                  {...rest}
                  closeMenuOnSelect={false}
                  options={teams.map((x) => ({
                    label: x.name,
                    value: x._id,
                  }))}
                  onChange={(teams) => {
                    onChange(teams.map((x) => x.value));
                  }}
                  value={value
                    .map((id) => {
                      const team = teams.find((x) => x._id === id);
                      return team && { label: team.name, value: team._id };
                    })
                    .filter((x) => x !== undefined)}
                  forwardedRef={ref}
                  placeholder="Select teams"
                />
              );
            }}
          />
        </>
      )}
      {showMultiSelects.taggedVariables && variables && (
        <Controller
          control={control}
          disabled={isLoading}
          name="taggedVariables"
          render={({ field: { ref, onChange, value, disabled, ...rest } }) => {
            return (
              <MultiSelect
                {...rest}
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                    maxHeight: "200px",
                    overflowY: "hidden",
                  }),
                }}
                noPortal={noPortalMultiSelects}
                isDisabled={disabled}
                closeMenuOnSelect={false}
                options={variables.map((x) => ({
                  label: x.nameWithDescription,
                  value: x._id,
                  isFixed: defaults.taggedVariables.includes(x._id),
                }))}
                onChange={(values) => {
                  const out = values.map((x) => x.value);

                  // The user can "clear all", but we should always have at least one
                  if (!out.length) {
                    onChange(defaults.taggedVariables);
                    return;
                  }
                  onChange(values.map((x) => x.value));
                }}
                value={value
                  .map((id) => {
                    const v = variables.find((x) => x._id === id);
                    return (
                      v && {
                        label: v.trimmedName,
                        value: v._id,
                        isFixed: defaults.taggedVariables.includes(v._id),
                      }
                    );
                  })
                  .filter((x) => x !== undefined)}
                forwardedRef={ref}
                placeholder="Select tags"
              />
            );
          }}
        />
      )}
      {labels && showMultiSelects.taggedLabels && (
        <Controller
          control={control}
          disabled={isLoading}
          name="taggedLabels"
          render={({
            field: { name, onBlur, onChange, value, ref, disabled },
          }) => {
            return (
              <div className="flex flex-row gap-2 border border-xslate-6 rounded-lg px-2 py-1">
                {labels.map((label) => {
                  const isSelected = value.includes(label._id);
                  const darkened = Color(label.color).darken(0.2).toString();
                  return (
                    <div
                      key={label._id}
                      onClick={() => {
                        const newValue = isSelected
                          ? value.filter((x) => x !== label._id)
                          : [...value, label._id];
                        onChange(newValue);
                      }}
                      className="cursor-pointer rounded-full border px-2 py-1 text-xs flex flex-row items-center gap-1"
                      style={
                        isSelected
                          ? {
                              backgroundColor: label.color,
                              borderColor: darkened,
                              color: "white",
                            }
                          : { color: label.color, borderColor: darkened }
                      }
                    >
                      {isSelected && <Check className="size-4" />}
                      {label.name}
                    </div>
                  );
                })}
              </div>
            );
          }}
        />
      )}

      <div className="flex items-center gap-1">
        {users && teams && (
          <C.MultiSelectIconToggle
            onClick={() => {
              onMultiSelectToggle("taggedUsersAndTeams");
            }}
            shown={showMultiSelects.taggedUsersAndTeams}
            disabled={isLoading || isMyNote || isPrivate === true}
          >
            <AtSign className="size-3.5" />
          </C.MultiSelectIconToggle>
        )}
        {variables && (
          <C.MultiSelectIconToggle
            onClick={() => {
              onMultiSelectToggle("taggedVariables");
            }}
            shown={showMultiSelects.taggedVariables}
            disabled={isLoading}
          >
            <FaHashtag className="size-3.5" />
          </C.MultiSelectIconToggle>
        )}
        {labels && (
          <C.MultiSelectIconToggle
            shown={showMultiSelects.taggedLabels}
            onClick={() => {
              onMultiSelectToggle("taggedLabels");
            }}
            disabled={isLoading}
          >
            <FaTag className="size-3" />
          </C.MultiSelectIconToggle>
        )}

        <div className="inline-flex grow items-center gap-2">
          {showIssueAndMyNote && (
            <>
              <input
                type="checkbox"
                className={cn(
                  "ml-3 size-4 enabled:cursor-pointer appearance-none rounded-sm border-2 border-red-600 checked:bg-red-600 bg-transparent enabled:active:scale-95 transition-transform disabled:opacity-50 disabled:border-xslate-6"
                )}
                {...register("isIssue", { disabled: isMyNote })}
              />
              <span className="text-xslate-11 text-sm">Open Issue</span>
              <input
                type="checkbox"
                className={cn(
                  "size-4 enabled:cursor-pointer appearance-none rounded-sm border-2 border-amber-500 checked:bg-amber-500 bg-transparent enabled:active:scale-95 transition-transform disabled:opacity-50 disabled:border-xslate-6"
                )}
                {...register("isMyNote", {
                  disabled: isIssue,
                })}
              />
              <span className="text-xslate-11 text-sm">Add to my notes</span>
            </>
          )}

          {close && (
            <C.CloseButton
              onClick={close}
              className={cn("ml-auto")}
              disabled={isLoading}
            >
              {closeLabel}
            </C.CloseButton>
          )}
          {showIssueCloseOrReopenButton && (
            <Button
              id={specialButtonId}
              type="submit"
              variant={"default"}
              size={"sm"}
              className={cn(!close && "ml-auto")}
              disabled={isLoading}
            >
              Submit & {defaults.issueResolution ? "Reopen" : "Close"} Issue
            </Button>
          )}
          <Button
            type="submit"
            variant={"default"}
            size={"sm"}
            className={cn(!close && !showIssueCloseOrReopenButton && "ml-auto")}
            disabled={isLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </C.Form>
  );
}

export function RangeLabel() {
  const brushState = useAtomValue(Atoms.brushStoreAtom);
  if (!brushState) throw new Error("no brush store");

  // they're unordered so I don't wanna call them start, end
  const [t_x, t_y] = useStore(brushState.brushStore, (s) => s.unorderedRange);

  const tz = useTimezone();
  const fmt = (t: number) =>
    DateTime.fromMillis(t, { zone: tz }).toFormat("LLL d yyyy, h:mm a");

  if (t_y === undefined) {
    return `${fmt(t_x)} - Ongoing`;
  }

  return `${fmt(Math.min(t_x, t_y))} — ${fmt(Math.max(t_x, t_y))}`;
}

export { GenericCommentForm, type OnCommentCreate };

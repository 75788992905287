import { useStore } from "zustand";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "../../../shared-ui/frontend/drawer";
import { useGroupsQuery } from "../../../hooks/tanstack-query";
import { AxisRangeButtonGroupAndLabelToLeft } from "../../dateSelector/axis-range-button-group";
import { Button } from "../../../shared-ui/frontend/button";
import { X } from "lucide-react";
import {
  chartDimensionsConfig,
  DRASecondaryVariableViewNeedsProvider,
  DRASecondaryVariableViewProvider,
} from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import { useToggleFullscreen } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { ComponentProps, useEffect, useState } from "react";
import { cn } from "../../../lib/utils";
import { VIEW_MODE, ViewModeSelectors } from "../../common/view-mode-selectors";
import {
  ChartVariant,
  InitialTrendLine,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { Badge } from "../../../shared-ui/frontend/badge";
import { ellipsify } from "../../utils/stylable";
import {
  useGroupDrawerStore,
  useGroupDrawerStoreNotRequired,
} from "./use-group-drawer-store";

function Internal({ isOperatingLimits }: { isOperatingLimits?: boolean }) {
  const store = useGroupDrawerStore();
  const [viewMode, setViewMode] = useState<VIEW_MODE>("grid");
  const groupsQuery = useGroupsQuery();
  const { groupId, setGroupId } = useStore(store);
  const selectedGroup = groupsQuery.data?.find((x) => x._id === groupId);
  const numCharts = selectedGroup?.variables.length || 0;
  const [numCols, setNumCols] = useState<number>(0);

  useEffect(() => {
    setNumCols(numCharts > 9 ? 4 : numCharts <= 5 ? 2 : 3);
  }, [numCharts]);

  const toggleFullscreen = useToggleFullscreen();

  const chartDims = chartDimensionsConfig(
    viewMode === "grid" ? { isGridView: true, numCols } : { isGridView: false }
  );

  return (
    <Drawer
      dismissible={false}
      direction="right"
      open={!!groupId}
      onOpenChange={(open) => {
        if (!open) {
          setGroupId(undefined);
        }
      }}
    >
      <DrawerContent className="w-[90dvw] h-[100dvh] right-0 left-auto overflow-scroll rounded-none p-0 bg-zinc-100 !select-text">
        <div className="overflow-auto p-6">
          {selectedGroup && (
            <>
              <div className="flex items-center justify-between mt-1 flex-nowrap">
                <div className="flex flex-nowrap items-center">
                  <span className="text-2xl font-semibold tracking-tight whitespace-nowrap">
                    {ellipsify(
                      selectedGroup.name ?? "",
                      Math.floor(window.innerWidth * 0.018)
                    )}
                  </span>
                  <Badge
                    variant={"secondary"}
                    className="ml-3 relative min-w-max h-full"
                  >
                    {selectedGroup.variables.length} tags
                  </Badge>
                </div>
                <div className="min-w-max">
                  <div className="inline-flex mr-2">
                    <ViewModeSelectors
                      withLabels
                      enabledModes={["grid", "list", "chart"]}
                      viewMode={viewMode}
                      setViewMode={setViewMode}
                      numCols={numCols}
                      setNumCols={setNumCols}
                    />
                  </div>
                  <AxisRangeButtonGroupAndLabelToLeft />
                  <DrawerClose asChild>
                    <Button size="icon" variant={"ghost"}>
                      <X className="size-4" />
                    </Button>
                  </DrawerClose>
                </div>
              </div>

              <div
                className={cn(
                  "grid gap-4 py-4",
                  viewMode === "grid" ? `grid-cols-${numCols}` : "grid-cols-1"
                )}
              >
                {viewMode !== "chart" ? (
                  selectedGroup.variables.map((vid, _, arr) => {
                    return (
                      <DRASecondaryVariableViewProvider
                        key={vid}
                        initialBatchVariables={[
                          {
                            type: "variable",
                            bv: "0".repeat(24) + vid,
                          },
                        ]}
                        initialExpanded={arr.length === 1}
                        variant={
                          isOperatingLimits
                            ? ChartVariant.OperatingLimits
                            : undefined
                        }
                      >
                        <DRASecondaryVariableViewNeedsProvider
                          noPortalMultiSelects
                          onLineClick={
                            toggleFullscreen &&
                            (() =>
                              ({ jotaiStore: s }) => {
                                toggleFullscreen(s);
                              })
                          }
                          ableToGetTaller
                          {...chartDims}
                          padding={{
                            left: 45,
                          }}
                          expandable
                          onlyMinMaxYAxes={viewMode === "grid"}
                        />
                      </DRASecondaryVariableViewProvider>
                    );
                  })
                ) : (
                  <DRASecondaryVariableViewProvider
                    initialBatchVariables={
                      selectedGroup.variables.map((vid) => ({
                        type: "variable",
                        bv: "0".repeat(24) + vid,
                      })) as [InitialTrendLine, ...InitialTrendLine[]]
                    }
                    initialExpanded={false}
                    variant={
                      isOperatingLimits
                        ? ChartVariant.OperatingLimits
                        : undefined
                    }
                  >
                    <DRASecondaryVariableViewNeedsProvider
                      onLineClick={
                        toggleFullscreen &&
                        (() =>
                          ({ jotaiStore: s }) => {
                            toggleFullscreen(s);
                          })
                      }
                      commentsOnlyInFullscreen={false}
                      respondToSelectedCommentId
                      ableToGetTaller
                      noPortalMultiSelects
                      {...chartDims}
                      padding={{
                        left: 45,
                      }}
                      expandable
                      numTicks={undefined}
                      closeAndDisableLimitStatusSeries={false}
                    />
                  </DRASecondaryVariableViewProvider>
                )}
              </div>
            </>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
}

function GroupDrawer(props: ComponentProps<typeof Internal>) {
  const s = useGroupDrawerStoreNotRequired();

  if (!s) return null;

  return <Internal {...props} />;
}

export { GroupDrawer };

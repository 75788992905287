import { z } from "zod";
import { MongoIdUpdatedAtCreatedAtDeleted } from "./common";

export const UserRole = z.enum(["User", "Admin", "Root"]);
export type UserRole = z.infer<typeof UserRole>;
export type SortOrder = {
  ft: string;
  pov: string[];
};

export const userSchema = MongoIdUpdatedAtCreatedAtDeleted.extend({
  email: z.string(),
  first: z.string(),
  last: z.string(),
  receive_reports: z.boolean(),
  preferred_order: z
    .object({
      ft: z.string(),
      pov: z.string().array(),
    })
    .optional(),
  reports: z.string().array(),
  units: z.string().array(),
  role: UserRole,
});

export type userSchema = z.infer<typeof userSchema>;

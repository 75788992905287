import { LocalDateSelector } from "../../../instant-calculator/local-date-selector";
import { DateTime } from "luxon";
import { useTimezone } from "../../../../zustand/config/useConfigStore";
import { useAtomValue, useSetAtom } from "jotai";
import { Atoms } from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import moment from "moment";
import { RANGES } from "../../../../constants/dateState";
import { useDateState } from "../../../../zustand/useDateState";
import { cn, type PropsWithCn } from "../../../../shared-ui/frontend/cn";
import { useMemo, useState } from "react";
import useMaxDate from "../../../common/hooks/useMaxDate";
import useCurrentUnitObject from "../../../common/hooks/useCurrentUnitObject";

const fmtWithTime = (t: DateTime) => t.toFormat("dd-LLL-yyyy hh:mm a");
const fmtWithoutTime = (t: DateTime) => t.toFormat("dd-LLL-yyyy");

/**
 * YO ALL OF THIS CODE ABOUT TIMEZONES IS CONFUSING
 *
 * I SHOULD WRITE SOME NOTES ABOUT IT
 */

function DateSelector({ className }: PropsWithCn) {
  /**
   * The chart range is range that we query the data for.
   */
  const chartDomain = useAtomValue(Atoms.getDomainAtom);
  const setChartDomain = useSetAtom(Atoms.setDomainAtom);
  const zone = useTimezone();
  const ds = useDateState();

  /**
   * After drawing the data that comes back from the
   * chart range query, the user can zoom, so drawn
   * domain can be a subset of the chart range.
   *
   * If it is defined, show the drawn domain instead
   * in the date selector.
   */
  const drawnDomain = useAtomValue(useAtomValue(Atoms.drawnDomainAtomAtom));

  /**
   * The drawn domain is given back to us using the actual
   * data's times, so we first need to parse it using the user's
   * tz, extract the "local time", and convert it to a local date
   * with the same time because all of our calendar components
   * use local time.
   */
  const [cdStart, cdEnd] = chartDomain;
  const startAsLocal = useMemo(
    () =>
      DateTime.fromMillis(cdStart, { zone }).setZone("local", {
        keepLocalTime: true,
      }),
    [zone, cdStart]
  );
  const endAsLocal = useMemo(
    () =>
      DateTime.fromMillis(cdEnd, { zone }).setZone("local", {
        keepLocalTime: true,
      }),
    [zone, cdEnd]
  );

  const startHasNonStandardTime = !startAsLocal.equals(
    startAsLocal.startOf("day")
  );

  const endHasNonStandardTime = !endAsLocal.equals(
    endAsLocal.endOf("day").startOf("minute")
  );

  const isCustom =
    ds.isCustom ||
    startHasNonStandardTime ||
    endHasNonStandardTime ||
    !RANGES.some(({ days }) =>
      startAsLocal
        .plus({ days: days - 1 })
        .endOf("day")
        .startOf("minute")
        .equals(endAsLocal)
    );

  const rangeToDisplay =
    drawnDomain[0] !== chartDomain[0] && drawnDomain[1] !== chartDomain[1]
      ? `${fmtWithTime(DateTime.fromMillis(drawnDomain[0], { zone }))} — ${fmtWithTime(DateTime.fromMillis(drawnDomain[1], { zone }))}`
      : isCustom
        ? `${fmtWithTime(startAsLocal)} — ${fmtWithTime(endAsLocal)}`
        : `${fmtWithoutTime(startAsLocal)} — ${fmtWithoutTime(endAsLocal)}`;

  const visibleRange = moment
    .utc(drawnDomain ? drawnDomain[1] : endAsLocal)
    .add(1, "minute")
    .diff(
      moment.utc(drawnDomain ? drawnDomain[0] : startAsLocal),
      "days",
      true
    );

  const visibleRoundedRange = Math.round(visibleRange * 10) / 10;

  const maxDate = useMaxDate().getTime();
  const currentUnit = useCurrentUnitObject();
  const [pastToday, setPastToday] = useState(false);
  const isRT = currentUnit && currentUnit.addons?.["realTime"];

  return (
    <div className={cn(className, "flex flex-col")}>
      <LocalDateSelector
        className={cn(pastToday && "border border-red-500 rounded-lg")}
        end={endAsLocal.toMillis()}
        start={startAsLocal.toMillis()}
        todoRefactorThisPropLabel={`${rangeToDisplay} (${visibleRoundedRange} days)`}
        onChange={(newRange) => {
          const newStart =
            newRange.start !== undefined
              ? DateTime.fromMillis(newRange.start)
                  .setZone(zone, {
                    keepLocalTime: true,
                  })
                  .toMillis()
              : chartDomain[0];
          const newEnd =
            newRange.end !== undefined
              ? DateTime.fromMillis(newRange.end)
                  .setZone(zone, {
                    keepLocalTime: true,
                  })
                  .toMillis()
              : chartDomain[1];

          if (newEnd > maxDate || newStart > maxDate) {
            setPastToday(true);
          } else {
            setPastToday(false);
            setChartDomain([newStart, newEnd]);
          }
        }}
      />
      {pastToday && (
        <div className="text-red-500 text-xs mt-1">
          You can't select a date past {isRT ? "today" : "yesterday"}
        </div>
      )}
    </div>
  );
}

export { DateSelector };

import React, { useEffect, useRef } from "react";
import draw from "./FaultTreeChartRenderer.js";
import getTreeTemplate from "./FaultTreeTemplates.ts";

function FaultTreeTemplate(props) {
  const ref = useRef();
  let maxLevel = 1;
  let offset = 0;
  if (props.template === 0) {
    maxLevel = 5;
    offset = 0;
  } else if (props.template === 1) {
    maxLevel = 15;
    offset = 70;
  } else if (props.template === 2) {
    maxLevel = 50;
    offset = 75;
  } else {
    throw new Error("Invalid template number");
  }
  const h = window.innerHeight * 0.6,
    w = window.innerWidth * 0.8;
  let options = {
    label: (d) => d.name,
    width: w / 3,
    padding: 1,
    height: h,
    panEnabled: false,
    zoomEnabled: false,
    dx: Math.max(h / maxLevel / 2, 10),
    r: Math.max(1.5, 7 - Math.floor(maxLevel / 10)),
    strokeWidth: Math.max(1, 2.5 - Math.floor(maxLevel / 20)),
    fontSize: Math.max(3, 15 - Math.floor(maxLevel / 10)),
    haloWidth: Math.max(3, 15 - Math.floor(maxLevel / 10)) / 4,
  };
  const tree = getTreeTemplate(props.template);

  useEffect(() => draw(tree, {}, options, ref, console.log, undefined));

  return (
    <div
      className="border m-2 rounded cursor-pointer border-bdgrey hover:border-zinc-400 w-1/3"
      onClick={props.onClick}
    >
      <h2 className="text-center">
        Fault Tree{" "}
        {
          ["2-Layer Template", "3-Layer Template", "4-Layer Template"][
            props.template
          ]
        }
      </h2>
      <div style={{ clear: "both", height: `${offset}px` }}></div>
      <svg ref={ref}></svg>
    </div>
  );
}

export default FaultTreeTemplate;

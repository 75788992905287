import moment from "moment";
import { Loader } from "@mantine/core";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { faultTreeNodeStatusSeriesSchema } from "../../../lib/api-schema/ft/fault-tree-node-status-series";
import { useDateState } from "../../../zustand/useDateState";
import { cn } from "../../../lib/utils";
import { useAcknowledgementsQuery } from "../../../hooks/tanstack-query";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import useBaseUrl from "../../../zustand/useBaseUrl";

export function FaultTreeCardStatusSeries2({
  className,
  height,
  showDateRow,
  series,
  variableId,
  treeId,
  compact,
}: {
  series: faultTreeNodeStatusSeriesSchema[] | undefined;
  showDateRow?: boolean;
  height: string;
  className?: string;
  variableId: string;
  treeId: string;
  compact?: boolean;
}) {
  const baseUrl = useBaseUrl();
  const $ds = useDateState();
  const axisFrom = $ds.axisRangeFrom.dateString;
  const axisTo = $ds.axisRangeTo.dateString;

  const acksQuery = useAcknowledgementsQuery({
    variableId,
    start: axisFrom,
    end: axisTo,
  });

  if (!series) {
    return (
      <div className="flex justify-center">
        <Loader color="dark" size={25} />
      </div>
    );
  }

  let gap = "1";
  let numberOfElements = $ds.axisRange.days;

  if (numberOfElements > 160) {
    gap = "[2px]";
  }

  if (numberOfElements > 250) {
    gap = "[1px]";
  }

  const getShowDatesFlags = () => {
    const numOfDataPoints = series.length;

    let out = 13; // show 13 dates at most

    // reduce the number of points as needed
    if (numOfDataPoints <= 32) {
      out = 10;
    }
    if (numOfDataPoints <= 14) {
      out = 7;
    }

    out = Math.min(out, numOfDataPoints); // what if we have less data than out?

    if (out === 0) {
      return []; // avoid divide by 0 below
    }

    const getDateEveryIndex = Math.floor(numOfDataPoints / out);

    return series.map((_, idx) => idx % getDateEveryIndex === 0);
  };

  const flags = showDateRow ? getShowDatesFlags() : null;

  const statusSeriesArr = series.map((day, idx) => {
    const date = moment.utc(day.date).startOf("day");
    const dateString = moment.utc(date).format("M/DD");

    const ack = acksQuery.data?.find((ack) =>
      moment.utc(ack.start).isSame(date, "day")
    );

    return {
      value: day.processing ? "Processing" : day.status,
      key: dateString,
      showDate: flags && flags[idx],
      ack,
    };
  });

  const atLeastOneDateShown = statusSeriesArr.some(
    ({ showDate }) => !!showDate
  );

  const isCompact = compact === true;

  return (
    <div className={cn("flex flex-col", className)}>
      <div className={`flex ${isCompact ? "" : `gap-${gap}`}`}>
        {statusSeriesArr.map((s) => {
          const shortDate = s.key;
          const day = s.key.split("/")[1] || "";
          const month = s.key.split("/")[0] || "";
          const isFocusedDay =
            moment($ds.axisRangeTo.dateString).format("M/DD") === shortDate;

          return (
            <Tooltip key={shortDate}>
              <TooltipContent>
                {s.ack &&
                s.ack.unacknowledgment === false &&
                s.ack.type === "normal" ? (
                  <>
                    <FaCheck className="inline" /> {shortDate}
                  </>
                ) : s.ack &&
                  s.ack.unacknowledgment === false &&
                  s.ack.type === "issue" ? (
                  <>
                    <FaExclamationTriangle className="inline" /> {shortDate}
                  </>
                ) : (
                  shortDate
                )}
              </TooltipContent>
              <TooltipTrigger
                className={cn(
                  `SeriesContainer_${s.value}`,
                  "flex-1",
                  isFocusedDay && "border-neutral border-2",
                  isCompact
                    ? "first:rounded-l-md last:rounded-r-md"
                    : "rounded-md border border-transparent hover:border-black hover:shadow-xl"
                )}
                style={{ height }}
                onClick={(e) => {
                  e.stopPropagation(); // important because this component is encompassed by a link which should not be triggered
                  const url = new URL(window.location.href);
                  url.searchParams.set("mo", month);
                  url.searchParams.set("d", day);
                  url.searchParams.set("selectednode", variableId);
                  url.pathname = `${baseUrl}/ft/details/${treeId}`;
                  window.location.href = url.toString();
                }}
              ></TooltipTrigger>
            </Tooltip>
          );
        })}
      </div>
      {atLeastOneDateShown ? (
        <div className="flex gap-1 mt-1">
          {statusSeriesArr.map(({ showDate, key }) => (
            <div
              key={key}
              className="text-[0.7rem] flex-1 text-neutral text-center"
            >
              {showDate ? key : null}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

import React from "react";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import useCurrentUnitObject from "../common/hooks/useCurrentUnitObject";
import useAuthStore, { useUserRequired } from "../../zustand/auth/useAuthStore";
import { Tooltip } from "@mantine/core";
import Button from "../common/Button/Button";
import { LinkWithQuery } from "./LinkWithQuery2";
import { CenterNavButtons } from "./center-nav-buttons";
import { PLANT_OVERVIEW_URL } from "./constants";
import { UnitSelectDropdown } from "./unit-select-dropdown";
import { cn } from "../../lib/utils";
import { useIsOnHelpPage } from "../../constants/routerPaths";
import { defaultRangeIndex } from "../../constants/dateState";
import { LogoutButton } from "./logout-button";
import { ThemeToggle } from "./theme-toggle";
import { DynamicRiskAnalyzerWithNMLogo } from "../nmm-branding/dynamic-risk-analyzer-with-nm-logo";

function Nav({
  managerPage,
  goBackToDRAButton,
  helpPage,
}: {
  managerPage?: boolean;
  goBackToDRAButton?: boolean;
  helpPage?: boolean;
}) {
  const location = useLocation();
  const path = location.pathname;
  const isOnPlantOverview = useIsOnPlantOverview();
  const isOnHelpPage = useIsOnHelpPage();

  const user = useAuthStore((s) => s.user);

  const isRootOrAdmin = useAuthStore((s) => s.user?.hasEditPermission);

  const currentUnit = useCurrentUnitObject();

  const shortNav = _.filter(
    [
      {
        name: "comments",
        label: "Comments",
        icon: "fa-comment",
        url: `/${currentUnit?.name}/comments`,
        disabled: isOnPlantOverview || helpPage || !currentUnit,
      },
      {
        name: "instant-calculator",
        label: "Instant Calculator",
        icon: "fa-calculator",
        url: `/${currentUnit?.name}/instant-calculator`,
        disabled: isOnPlantOverview || helpPage || !currentUnit,
      },
      {
        name: "notifications",
        label: "Notifications",
        icon: "fa-bell",
        url: `/${currentUnit?.name}/notifications`,
        disabled:
          !currentUnit ||
          !currentUnit.addons?.realTime ||
          isOnPlantOverview ||
          helpPage,
      },
      {
        name: "control",
        label: `${isRootOrAdmin ? "Advanced " : ""}Settings`,
        icon: user && user.numericRole === 1 ? "fa-cog" : "fa-cogs",
        url: `/${currentUnit?.name}/control/tag`,
        active: managerPage,
        disabled: !currentUnit || isOnPlantOverview || helpPage,
      },
      {
        name: "help",
        label: "Help",
        icon: "fa-question",
        url: "/help",
      },
    ],
    (item) => !item.disabled
  );

  return (
    <div
      className={classNames(
        "md:sticky top-0 navbar p-0 m-0 min-h-10 max-h-10 z-[15]",
        {
          "bg-xslate-2": isOnPlantOverview,
          "bg-[#2C2C2C] text-neutral-content": !isOnPlantOverview,
        }
      )}
      id="main-navbar"
    >
      <div className="navbar-start">
        <LinkWithQuery
          to={PLANT_OVERVIEW_URL}
          className="btn px-0 sm:px-[0.5rem] btn-ghost btn-sm normal-case text-xl mr-0 pr-0"
          pick={{ d: true, mo: true, y: true }}
          set={{
            z: defaultRangeIndex.toString(), // when going to plant overview, reset axis range
          }}
        >
          <DynamicRiskAnalyzerWithNMLogo
            color={isOnPlantOverview ? "colored" : "white"}
            className="h-3"
          />
        </LinkWithQuery>
        {goBackToDRAButton ? (
          <Link to="/">
            <Button className="text-white bg-[#252228] border-[#252228] my-1 flex-nowrap">
              Go back to DRA
            </Button>
          </Link>
        ) : null}

        {isOnPlantOverview ? null : goBackToDRAButton ? null : (
          <div className="dropdown text-base-content ml-0 pl-0">
            <label
              tabIndex={0}
              className="btn btn-sm text-white bg-[#252228] border-[#252228] my-1 flex-nowrap"
            >
              {currentUnit ? currentUnit.shortName : "Select Unit"}{" "}
              <svg
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
              </svg>
            </label>
            <UnitSelectDropdown />
          </div>
        )}
      </div>
      {!isOnPlantOverview && !isOnHelpPage && <CenterNavButtons />}
      <div className="navbar-end h-10">
        <ThemeToggle />
        <InitialsButton />
        {(shortNav || []).map((item) => {
          // const isActive = page === item.name;
          const isActive = path.startsWith(item.url);
          return (
            <LinkWithQuery
              key={item.url}
              to={item.url}
              pick={{
                d: true,
                mo: true,
                y: true,
                z: true,
                cd: true,
              }}
              className={cn(
                "!h-full border-y-[5px] border-b-transparent rounded-b-sm w-[3.5rem] transition",
                isActive
                  ? "border-t-primary bg-zinc-700"
                  : "border-t-transparent hover:border-t-primary",
                !isActive &&
                  (isOnPlantOverview
                    ? "hover:bg-zinc-400"
                    : "hover:bg-zinc-700")
              )}
            >
              <Tooltip label={item.label} position="bottom" withArrow>
                <div className="flex h-full justify-center items-center">
                  <i className={`fa ${item.icon}`} />
                </div>
              </Tooltip>
            </LinkWithQuery>
          );
        })}
        <LogoutButton />
      </div>
    </div>
  );
}

function useIsOnPlantOverview() {
  const location = useLocation();
  const path = location.pathname;
  return PLANT_OVERVIEW_URL === path;
}

function InitialsButton() {
  const user = useUserRequired();
  const isOnPlantOverview = useIsOnPlantOverview();

  if (!isOnPlantOverview) return null;

  const getInitials = () => {
    const getFirstLetter = (s: string) => {
      return s.length ? s.charAt(0) : "";
    };
    const initials = getFirstLetter(user.first) + getFirstLetter(user.last);
    if (initials === "") {
      return null;
    } else {
      return initials.toUpperCase();
    }
  };

  const capitalizeName = () => {
    let first = user.first;
    first = first ? _.capitalize(first) : "";
    first = user.last ? first + " " + _.capitalize(user.last) : first;

    return first ? first : null;
  };
  return (
    <Tooltip label={capitalizeName()} position="left" withArrow>
      <Button className="gap-1 btn-ghost" icon="user">
        {getInitials()}
      </Button>
    </Tooltip>
  );
}

export default Nav;

import React, { useState, useRef, useMemo } from "react";
import useSearch from "../../common/useSearch";
import ClusterCard from "./ClusterCard";
import SearchBar from "../../common/SearchBar";
import CreateEntityForm from "../../common/manager/CreateEntityForm";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import CreateNewEntityButton from "../../common/manager/CreateNewEntityButton";
import { Collapse } from "@mantine/core";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import {
  useAriaClustersQuery,
  useClusterMutation,
  useGroupsQuery,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { clusterSchema } from "../../../lib/api-schema/cluster";
import { useProcStartDate } from "../../../lib/useProcStartDate";
import { FullscreenProvider } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { TimeseriesChartTooltipStoreProvider } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { ClickedLineControlsNeedsTimeseriesTooltipStoreProvider } from "../../time-series/secondary-variable-view/control-buttons";
import { GlobalTooltip } from "../../time-series/global-tooltip";
import { ProfileBookFullscreenChart } from "../../pb/profile-book";
import { OVERALL_GROUP_NAME } from "../../../lib/api-schema/misc";
import { ClusterScoreBadge } from "../../aria/cluster-score-badge";

const PAGE_NAME = "Custom Clusters";
const MAX_LEN_CLUSTER_NAME = 60;

export type ClusterCardType = Omit<clusterSchema, "variables"> & {
  variables: {
    _id: string;
    name: string;
    trimmedName: string;
    nameWithDescription: string;
  }[];
  pairwise_score?: {
    tag_id1: string;
    tag_id2: string;
    score: number;
  }[];
};

export function useFormOptions() {
  const clusterMutation = useClusterMutation();
  const procStart = useProcStartDate().toISOString();
  const tagsQuery = useVariablesArrayQuery();
  const tags = tagsQuery.data || [];
  const groupsQuery = useGroupsQuery();
  const overallGroup = (groupsQuery.data || []).find(
    (g) => g.name === OVERALL_GROUP_NAME
  );
  const overallGroupId = overallGroup?._id || "";
  const formOptions = {
    submission: {
      submit: (
        cluster: Omit<clusterSchema, "variables"> & {
          variables: { value: string; label: string }[];
        }
      ) => {
        return clusterMutation.mutateAsync({
          cluster: {
            ...cluster,
            aria_enabled: false,
            type: "static",
            variables: cluster.variables.map((v) => v.value),
            start: procStart,
          },
        });
      },
      successMessage: (newPayload: clusterSchema) => {
        return `Cluster created: ${newPayload.name}${
          newPayload.description ? ` ${newPayload.description}` : ""
        } (${newPayload.variables.length} members)`;
      },
    },
    validation: (o: clusterSchema) => {
      if (o["name"].length > MAX_LEN_CLUSTER_NAME) {
        return `Cluster name must be less than ${MAX_LEN_CLUSTER_NAME} characters`;
      }

      if (o["variables"].length < 2) {
        return "Select two or more variables";
      }
      if (!o["name"]?.trim?.()) {
        return "Enter a cluster name";
      }
      return false;
    },
    form: [
      {
        datakey: "name",
        label: "Cluster Name",
        _return: true,
        returnOnlyIfDifferent: false,
        classes: {
          Input__label: "ClusterManager__entityEdit__label",
          Input: "ClusterManager__entityEdit__input",
        },
        defaultValue: "",
        type: "text",
      },
      {
        datakey: "description",
        label: "Description",
        _return: true,
        returnOnlyIfDifferent: false,
        classes: {
          Input__label: "ClusterManager__entityEdit__label",
          Input: "ClusterManager__entityEdit__input",
        },
        defaultValue: "",
        type: "text",
      },
      {
        hint: "Select two or more variables",
        label: "Associated Variables",
        type: "multipleselect",
        datakey: "variables",
        _return: true,
        returnOnlyIfDifferent: false,
        defaultValue: [],
        options: tags
          .filter((t) => t.groups.includes(overallGroupId))
          .map((t) => ({
            value: t._id,
            label: t.nameWithDescription,
          })),
      },
    ],
  };
  return formOptions;
}

function ClustersManager() {
  usePermissionBasedDocumentTitleForSettingsPage();
  const hasEditAccess = useAuthStore((s) => s.user?.hasEditPermission);
  const clustersQuery = useAriaClustersQuery({
    filterType: "dynamic",
  });
  const tagsQuery = useVariablesArrayQuery();
  const [createMode, setCreateMode] = useState(false);

  const sortOptions = useRef({
    options: {
      "last updated": (a: clusterSchema, b: clusterSchema) => {
        const diff =
          new Date(b.updated_at || "").getTime() -
          new Date(a.updated_at || "").getTime();
        if (diff === 0) return a.name.localeCompare(b.name);
        return diff;
      },
      alphabetical: (a: ClusterCardType, b: ClusterCardType) =>
        a.name.localeCompare(b.name),
      "# of members (ascending)": (a: ClusterCardType, b: ClusterCardType) => {
        const diff = a.variables.length - b.variables.length;
        if (diff === 0) return a.name.localeCompare(b.name);
        return diff;
      },
      "# of members (descending)": (a: ClusterCardType, b: ClusterCardType) => {
        const diff = b.variables.length - a.variables.length;
        if (diff === 0) return a.name.localeCompare(b.name);
        return diff;
      },
    },
  }).current;

  const searchQueryLabelsAndKeys: Record<string, { keys: string[] }> = {
    "cluster name": { keys: ["name" satisfies keyof ClusterCardType] },
    description: { keys: ["description" satisfies keyof ClusterCardType] },
    "associated tags": {
      keys: [
        "variables.*.nameWithDescription" satisfies `${keyof ClusterCardType}.*.${keyof ClusterCardType["variables"][number]}`,
      ],
    },
  };

  // map the potential search keys' display names to object keys (this is the format match-sorter uses)
  const searchOptions = useRef({
    options: {
      "All attributes": {
        keys: Object.values(searchQueryLabelsAndKeys)
          .map((b) => b.keys)
          .flat(),
      },
      ...searchQueryLabelsAndKeys,
    },
  }).current;

  // when we search, we want to search on tags too, but the clusters does not have that info
  // we need to map

  const clustersWithTagNames = useMemo(() => {
    const clusters = clustersQuery.data || [];
    const tags = tagsQuery.data || [];
    return clusters.map((c) => {
      return {
        ...c,
        variables: c.variables
          .map((v) => {
            const tagFound = tags.find((t) => t._id === v);
            // Javascript will take in whole Tag object even if we cast it differently in TS
            return {
              _id: tagFound?._id,
              name: tagFound?.name,
              trimmedName: tagFound?.trimmedName,
              nameWithDescription: tagFound?.nameWithDescription,
            };
          })
          .filter((x) => x !== undefined),
      };
    });
  }, [clustersQuery.data, tagsQuery.data]);

  // @ts-ignore
  const { matches, filter, search, sort } = useSearch(clustersWithTagNames, {
    search: searchOptions,
    sort: sortOptions,
  });

  const formOptions = useFormOptions();

  return (
    <>
      <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-[2rem] sm:text-[1.75rem] mr-2">
            {PAGE_NAME}
          </span>

          {hasEditAccess && (
            <CreateNewEntityButton onClick={() => setCreateMode(true)} />
          )}
        </div>
        <div className="flex flex-row items-center italic">
          <span className="text-xslate-11 whitespace-nowrap text-lg mr-4 relative top-0.5">
            {matches.length} Clusters
          </span>
          <SearchBar
            filter={filter}
            search={search}
            sort={sort}
            children={null}
          />
        </div>
      </div>
      <Collapse in={createMode}>
        <CreateEntityForm
          {...formOptions}
          close={() => setCreateMode(false)}
          bordered={true}
          rounded={true}
        />
      </Collapse>

      <div className="drop-shadow-sm my-3">
        {matches?.length ? (
          (matches as ClusterCardType[]).map((cluster) => {
            return (
              <ClusterCard
                score={false}
                cluster={cluster}
                key={cluster._id}
                className={
                  cluster.type === "dynamic" ? "bg-blue-50" : undefined
                }
                heatmap={false}
              />
            );
          })
        ) : (
          <div className="flex text-textgrey justify-center text-[1.5rem] mt-5">
            No {PAGE_NAME.toLowerCase()} to show
          </div>
        )}
      </div>
    </>
  );
}

export default function Wrapped() {
  return (
    <FullscreenProvider>
      <TimeseriesChartTooltipStoreProvider>
        {() => (
          <>
            <ClickedLineControlsNeedsTimeseriesTooltipStoreProvider />
            <GlobalTooltip />
            {/* must match pb  */}
            <ProfileBookFullscreenChart />
            <ClustersManager />
          </>
        )}
      </TimeseriesChartTooltipStoreProvider>
    </FullscreenProvider>
  );
}

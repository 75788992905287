import React, { useState } from "react";
import Fragment from "../../common/Fragment";
import First from "../../common/First";
import CreateEntityForm from "../../common/manager/CreateEntityForm";
import PillRow from "../../common/manager/PillRow";
import UpdatedAt from "../../common/manager/UpdatedAt";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import { Tooltip } from "@mantine/core";
import Button from "../../common/Button/Button";
import { Badge } from "../../ui/badge";
import { addSuccessToast } from "../../toast/use-toast-store";
import { clusterSchema } from "../../../lib/api-schema/cluster";
import {
  useClusterMutation,
  useDeleteClusterMutation,
  useVariablesArrayQuery,
} from "../../../hooks/tanstack-query";
import { Dialog, DialogContent } from "../../../shared-ui/frontend/dialog";
import { IoDuplicate } from "react-icons/io5";
import {
  BsFillGrid3X3GapFill,
  BsFillLightningChargeFill,
} from "react-icons/bs";
import { cn } from "../../../lib/utils";
import { useProcStartDate } from "../../../lib/useProcStartDate";
import { ClusterScoreBadge } from "../../aria/cluster-score-badge";
import { ClusterCardType } from "./ClustersManager";
import { FaChartLine } from "react-icons/fa6";
import { useToggleFullscreen } from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { createJotaiStoreForChartSync } from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import { useTimezone } from "../../../zustand/config/useConfigStore";
import { assertMinLen1 } from "../../../shared-ui/lib/utils";
import { HeatMap } from "./HeatMap";

function ClusterCard({
  cluster,
  controls,
  className,
  score,
  heatmap,
}: {
  cluster: ClusterCardType;
  controls?: React.ReactNode; // the ARIA settings page passes its own controls
  className?: string;
  score?: boolean;
  heatmap?: boolean;
}) {
  const hasEditPermission = useAuthStore((s) => s.user?.hasEditPermission);
  const [editMode, setEditMode] = useState(false);
  const [heatmapOpen, setHeatmapOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const clusterMutation = useClusterMutation();
  const deleteClusterMutation = useDeleteClusterMutation();
  const tagsQuery = useVariablesArrayQuery();
  const tags = tagsQuery.data || [];
  const procStart = useProcStartDate().toISOString();
  const toggleFs = useToggleFullscreen();
  const zone = useTimezone();

  // using CreateEntityForm as edit mode, NOT create mode
  const formOptions = (withId = true) => ({
    submission: {
      submit: (
        payload: Partial<
          Omit<clusterSchema, "variables"> & {
            variables: { value: string; label: string }[];
          }
        >
      ) => {
        return clusterMutation.mutateAsync({
          clusterId: withId ? cluster._id : undefined,
          cluster: {
            ...payload,
            type: "static",
            start: procStart,
            aria_enabled: false,
            variables: payload.variables?.map((v) => v.value) || [],
          },
        });
      },
      successMessage: () => "Successfully updated",
    },
    validation: () => false,
    form: [
      {
        datakey: "name",
        label: "Cluster Name",
        _return: true,
        returnOnlyIfDifferent: false,
        defaultValue: cluster.name,
        type: "text",
      },
      {
        datakey: "description",
        label: "Description",
        _return: true,
        returnOnlyIfDifferent: false,
        defaultValue: withId ? cluster.description : "",
        type: "text",
      },
      {
        hint: "Select two or more tags",
        label: "Associated Tags",
        type: "multipleselect",
        datakey: "variables",
        _return: true,
        returnOnlyIfDifferent: false,
        defaultValue: cluster.variables.map((vid) => {
          const tag = tags.find((t) => t._id === vid._id);
          return { value: tag?._id, label: tag?.trimmedName };
        }),
        options: tags.map((t) => ({
          value: t._id,
          label: t.trimmedName,
        })),
      },
    ],
  });

  return (
    <div
      className={cn(
        "mt-4 border border-solid border-[#dbdbdb] bg-white rounded-lg z-0",
        className
      )}
    >
      {/* name and description  */}
      <div className="text-xslate-12 flex items-center justify-between text-md py-[0.15em] px-[0.5em] border-b border-solid border-bordgrey rounded-t-xl">
        <div className="grow inline-flex flex-row items-center gap-2">
          <span className="font-medium px-0 my-[0.2em] mx-0 break-words">
            {cluster.type === "dynamic" ? (
              <>
                <BsFillLightningChargeFill className="size-3.5 mr-1 inline" />
                {cluster.name}
              </>
            ) : (
              `${cluster.name}${cluster.description ? " | " + cluster.description : ""}`
            )}
            {cluster.variables && (
              <Badge variant={"secondary"} className="ml-3 whitespace-nowrap">
                {`${cluster.variables.length || 0} member${
                  cluster.variables.length === 1 ? "" : "s"
                }`}
              </Badge>
            )}
          </span>
          {score !== false && <ClusterScoreBadge score={cluster.score} />}
        </div>

        {heatmap !== false &&
          cluster.pairwise_score &&
          cluster.pairwise_score.length > 0 && (
            <>
              <Tooltip label="Relationship Heatmap" withArrow>
                <Button
                  className="btn-ghost text-xslate-11"
                  onClick={() => setHeatmapOpen(true)}
                >
                  <BsFillGrid3X3GapFill />
                </Button>
              </Tooltip>
              <Dialog open={heatmapOpen} onOpenChange={setHeatmapOpen}>
                <DialogContent className="min-w-fit">
                  <HeatMap scores={cluster.pairwise_score} />
                </DialogContent>
              </Dialog>
            </>
          )}

        <Tooltip label="Open chart" withArrow>
          <Button
            className="btn-ghost text-xslate-11"
            onClick={() => {
              toggleFs?.(
                createJotaiStoreForChartSync({
                  zone,
                  initialBatchVariables: assertMinLen1(
                    cluster.variables.map((x) => ({
                      bv: x._id.padStart(48, "0"),
                      type: "variable",
                    }))
                  ),
                  decideAnomBooleanForNewlyAddedVariableTrendLines: () => false,
                  initialExpanded: false,
                })
              );
            }}
          >
            <FaChartLine />
          </Button>
        </Tooltip>
        {/* edit and delete buttons  */}
        {hasEditPermission && cluster.type === "static" && (
          <div className="shrink-0">
            <Tooltip label="Delete" withArrow>
              <Button
                icon="trash-o"
                className="ml-auto btn-ghost text-xslate-11"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete cluster ${cluster.name}${cluster.description ? " | " + cluster.description : ""}?`
                    )
                  ) {
                    deleteClusterMutation.mutate(cluster._id);
                    addSuccessToast("Successfully deleted");
                  }
                }}
              />
            </Tooltip>

            {!editMode && (
              <Tooltip label="Edit" withArrow>
                <Button
                  icon="pencil"
                  className="ml-auto btn-ghost text-xslate-11"
                  onClick={() => setEditMode(true)}
                />
              </Tooltip>
            )}
          </div>
        )}
        {hasEditPermission && cluster.type === "dynamic" && (
          <Dialog open={drawerOpen} onOpenChange={setDrawerOpen}>
            <DialogContent>
              <h2 className="text-xl pt-2 -mb-4 font-semibold text-center">
                Save as Custom Cluster
              </h2>
              <CreateEntityForm
                {...formOptions(false)}
                close={() => setDrawerOpen(false)}
                bordered={false}
              />
            </DialogContent>
            <Tooltip label="Save as Custom Cluster">
              <Button
                className="ml-auto btn-ghost"
                onClick={() => setDrawerOpen(true)}
              >
                <IoDuplicate className="h-4 w-4" />
              </Button>
            </Tooltip>
          </Dialog>
        )}
        {controls}
      </div>

      <First>
        <Fragment match={!editMode}>
          <div>
            <First>
              <Fragment match={!!cluster.variables.length}>
                <div className="px-2">
                  <PillRow
                    className="py-0"
                    items={cluster.variables
                      .map((v) => v.nameWithDescription)
                      .filter((x) => x !== undefined)}
                    ulClassName="inline-flex text-xs"
                  />
                </div>
              </Fragment>
            </First>
          </div>
          {cluster.updated_at && (
            <div className="mr-2 italic text-[0.7rem] mb-0.5 text-zinc-400 flex justify-end">
              <UpdatedAt noContainer={true} timestamp={cluster.updated_at} />
            </div>
          )}
        </Fragment>

        {/* edit form  */}
        <CreateEntityForm
          {...formOptions(true)}
          close={() => setEditMode(false)}
          bordered={false}
        />
      </First>
    </div>
  );
}

export default ClusterCard;

import {
  Atoms,
  isExpressionTrendLine,
  isVariableTrendLine,
  type SelectedVariable,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { RelativeClampsPopover } from "../../../shared-ui/time-series-2/clamps/relative-clamps-popover";
import * as Controls from "../../../shared-ui/time-series-2/secondary-variable-view/control-buttons";
import { TooltipPopoverContent } from "../../charts/DTC/variable-info-tooltip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shared-ui/frontend/popover";
import { Button } from "../../../shared-ui/frontend/button";
import { Bold, Info } from "lucide-react";
import {
  atom,
  PrimitiveAtom,
  Provider,
  useAtom,
  useAtomValue,
  useStore,
} from "jotai";
import { GroupStatistics } from "../../variability-view/header/components/GroupStatistics";
import { mapToObj } from "remeda";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";
import { GlobalControlButtonsForClickedLine } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/global-clicked-line-controls";
import { useMemo } from "use-memo-one";
import { focusAtom } from "jotai-optics";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../shared-ui/frontend/tooltip";
import { modifyColor } from "../../../shared-ui/time-series-2/utils";

function ControlButtons({
  identifier,
  onClose,
}: {
  identifier: PrimitiveAtom<SelectedVariable> | string;
  // we use this component 2 diff ways
  onClose?: () => void;
}) {
  const jot = useStore();

  const selectedVariablesAtoms = useAtomValue(Atoms.selectedVariableAtomsAtom);

  const svAtom = useMemo(() => {
    if (typeof identifier === "string") {
      return selectedVariablesAtoms.find((a) => {
        const x = jot.get(a);
        return isExpressionTrendLine(x)
          ? x.id === identifier
          : x.bv === identifier;
      });
    }

    return identifier;
  }, [selectedVariablesAtoms, identifier, jot]);

  if (!svAtom) throw new Error("no svAtom");

  return <ControlButtonsInner svAtom={svAtom} onClose={onClose} />;
}

function BoldButton({ svAtom }: { svAtom: PrimitiveAtom<SelectedVariable> }) {
  const boldAtom = useMemo(() => {
    return focusAtom(svAtom, (o) => o.prop("bold"));
  }, [svAtom]);

  const [isBold, setBold] = useAtom(boldAtom);

  const label = isBold ? "Remove Shadow" : "Apply Shadow";

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant={isBold ? "default" : "ghost"}
          size="icon-sm"
          className={cn(
            !isBold &&
              "text-xslate-11 hover:bg-inherit hover:border-xslate-11 border border-transparent"
          )}
          onClick={(e) => {
            e.stopPropagation();
            setBold((x) => !x);
          }}
        >
          <Bold className="size-3.5" />
        </Button>
      </TooltipTrigger>
      <TooltipContent
        className={cn(isBold && "bg-xred-3 text-xred-11 border-xred-8 border")}
        side="left"
      >
        {label}
      </TooltipContent>
    </Tooltip>
  );
}

function ControlButtonsInner({
  svAtom,
  onClose,
}: {
  svAtom: PrimitiveAtom<SelectedVariable>;
  onClose?: () => void;
}) {
  const batchVariableObj = useAtomValue(svAtom);
  const canShowVariableInfo = isVariableTrendLine(batchVariableObj);

  return (
    <>
      <BoldButton svAtom={svAtom} />

      <RelativeClampsPopover
        batchVariable={batchVariableObj}
        className="text-xslate-11 hover:bg-inherit hover:border-xslate-11 border border-transparent"
        size={"icon-sm"}
      />

      {/* <Controls.EyeButton
        size={"icon-sm"}
        batchVariable={batchVariableObj}
        className="text-xslate-11"
      /> */}
      {canShowVariableInfo && (
        <>
          <Popover>
            <PopoverTrigger asChild onClick={(e) => e.stopPropagation()}>
              <Button
                variant={"ghost"}
                size="icon-sm"
                className={
                  "text-xslate-11 hover:bg-inherit hover:border-xslate-11 border border-transparent"
                }
              >
                <Info className="size-3.5" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="text-xs p-3.5 min-w-[300px] max-w-[50vw]">
              <TooltipPopoverContent
                variableId={batchVariableObj.bv.slice(24)}
              />
            </PopoverContent>
          </Popover>
          <VariabilityStatisticsPopover
            variableId={batchVariableObj.bv.slice(24)}
            color={batchVariableObj.color}
            className={
              "text-xslate-11 hover:bg-inherit hover:border-xslate-11 border border-transparent"
            }
          />
        </>
      )}

      <Controls.DeleteButton
        size={"icon-sm"}
        className="text-xslate-11"
        onClose={onClose}
        batchVariable={batchVariableObj}
      />
    </>
  );
}
/**
 * Default implementation of what to show when a line is
 * clicked in the chart. Right now, we show the control
 * buttons for the clicked line, but you can take what
 * this component returns and make something new as
 * requirements change.
 */
function ClickedLineControlsNeedsTimeseriesTooltipStoreProvider() {
  return (
    <GlobalControlButtonsForClickedLine>
      {(onClosePopupDialog, clickedLineData) => {
        return (
          <Provider store={clickedLineData.jotaiStore}>
            <ControlButtons
              identifier={clickedLineData.bvOrId}
              onClose={onClosePopupDialog}
            />
          </Provider>
        );
      }}
    </GlobalControlButtonsForClickedLine>
  );
}

function VariabilityStatisticsPopover({
  className,
  variableId,
  color,
}: PropsWithCn<{ variableId: string; color: string | undefined }>) {
  const [start, end] = useAtomValue(Atoms.getDomainAtom);
  const excludedModes = useAtomValue(Atoms.excludedModesAtom);
  // everywhere else shutdowns represented as 24 0s
  // but variability stats uses "Shutdown" as the key
  if (excludedModes.has("000000000000000000000000")) {
    excludedModes.add("Shutdown");
  } else {
    excludedModes.delete("Shutdown");
  }

  return (
    <GroupStatistics
      className={className}
      showLimitStats={false}
      variableId={variableId}
      color={color}
      start={start}
      end={end}
      excludedModes={mapToObj([...excludedModes], (excludedMode) => [
        excludedMode,
        true,
      ])}
    />
  );
}

export {
  ControlButtons,
  VariabilityStatisticsPopover,
  ClickedLineControlsNeedsTimeseriesTooltipStoreProvider,
};

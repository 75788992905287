import { useAtomValue } from "jotai";
import * as Sidebar from "../../../../shared-ui/time-series-2/secondary-variable-view/left-sidebar";
import { Atoms } from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { CommentThread } from "./comment-thread";
import { CreateCommentReplyForm } from "./create-reply-form";
import { cn } from "../../../../lib/utils";
import { ReactNode } from "react";
import { CreateCommentForm } from "./create-comment-form";
import { useIsRenderedAsFullscreen } from "../../../../shared-ui/time-series-2/secondary-variable-view/secondary-variable-view";
import {
  NotificationsArea,
  showNotificationsPanelAtom,
} from "../../../notifications/dropdown/notifications-area";

function FullscreenCommentsNotificationsSidebar() {
  const thisChartIsFullscreen = useIsRenderedAsFullscreen();

  const brushState = useAtomValue(Atoms.brushStoreAtom);
  const isCreating = brushState && brushState.for === "comment-create";
  const selectedCommentId = useAtomValue(Atoms.selectedCommentIdAtom);
  const showNotificationsPanel = useAtomValue(showNotificationsPanelAtom);

  if (!thisChartIsFullscreen) return; // this component should only render in fullscreen view

  const container = (children: ReactNode) => {
    return (
      <Sidebar.Container className="w-[30%] border-l p-0">
        <div className={cn("slide-up-fade-in flex shrink-0 flex-col gap-2")}>
          {children}
        </div>
      </Sidebar.Container>
    );
  };

  if (isCreating) return container(<CreateCommentForm noPortalMultiSelects />);

  if (selectedCommentId)
    return container(
      <CommentThread commentId={selectedCommentId} noPortalMultiSelects />
    );

  /**
   * If we're not showing any comments, check if we want to show notifications panel
   */

  if (showNotificationsPanel)
    return container(<NotificationsArea className="p-2" />);
  return undefined;
}

export { FullscreenCommentsNotificationsSidebar };

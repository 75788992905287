/**
 * DRA Param 'dv' is not used on this page because v dictates what is shown for dv anyway
 */

// TODO ensure that whatever actions you do, there is always a chart selected
// for example on page load, just select the first one

import React, { useState, useEffect, useMemo } from "react";
import "./DynamicRiskIndex.scss";
import _ from "lodash";
import useDocumentTitle from "../common/hooks/useDocumentTitle";
import useSearchParamsEnhanced from "../boundaries/hooks/useSearchParamsEnhanced";
import { views } from "./constants";
import { useHandleAnomaliesChange } from "./hooks/use-handle-anomalies-change";
import {
  useGetUseDriStore,
  useInitDriStoreData,
} from "./hooks/create-use-dri-store";
import * as R from "remeda";
import { ANOMALY_LEVELS_DESC } from "../../types/api/Anomaly";
import {
  useVariablesArrayQuery,
  useVariablesMappedByIdQuery,
} from "../../hooks/tanstack-query";
import { VariabilityDrawer } from "../variability-view/variability-drawer";
import { chartDimensionsConfig } from "../time-series/secondary-variable-view/dra-secondary-variable.view";
import { ClickedLineControlsNeedsTimeseriesTooltipStoreProvider } from "../time-series/secondary-variable-view/control-buttons";
import { cn, iife } from "../../lib/utils";
import { ViewModeSelectors } from "../common/view-mode-selectors";
import { ClusterDrawer } from "../clusters/cluster-drawer/cluster-drawer";
import { useToggleFullscreen } from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { TimeseriesChartTooltipStoreProvider } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "../time-series/global-tooltip";
import ClusterInfoTooltip from "../clusters/ClusterInfoTooltip";
import { useGetUseViewModeStore } from "../../shared-ui/time-series-2/grid-view-store";
import { ProfileBookFullscreenChart } from "../pb/profile-book";
import { useTagsFilterStoreContext } from "../tags/use-tags-filter-store";
import { useSelectedGroup } from "./use-selected-group";
import { Input } from "../../shared-ui/frontend/input";
import { Check, X } from "lucide-react";
import { Button } from "../../shared-ui/frontend/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../shared-ui/frontend/popover";
import { FaEllipsisH, FaFilter, FaTimesCircle } from "react-icons/fa";
import { useTagsFilter } from "../tags/use-tags-filter";
import { useSearchParams } from "react-router-dom";
import { DATESTATE_SP_KEYS } from "../../zustand/useDateState";
import { defaultRangeIndex } from "../../constants/dateState";
import { DRAParamsMap } from "../boundaries/hooks/useDRAParams2";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "../../shared-ui/frontend/tooltip";
import { ClusterScoreBadge } from "../aria/cluster-score-badge";
import { Badge } from "../../shared-ui/frontend/badge";
import {
  BsFillGrid3X3GapFill,
  BsFillLightningChargeFill,
} from "react-icons/bs";
import { DynamicRiskIndexWrapper } from "./dynamic-risk-index";
import { Dialog, DialogContent } from "../../shared-ui/frontend/dialog";
import { HeatMap } from "../clusters/manager/HeatMap";
import Button2 from "../common/Button/Button";
import { DriCharts } from "./charts";

function DynamicRiskIndex() {
  useInitDriStoreData();
  useDocumentTitle("Dynamic Risk Index > DRA");
  const useDriStore = useGetUseDriStore();

  const [heatmapOpen, setHeatmapOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParamsEnhanced();
  const [showingCluster, setShowingCluster] = useState();
  const clusters = useDriStore((s) => s.clusters);
  const variables = useDriStore((s) => s.variables);
  const anomalies = useDriStore((s) => s.anomalies);
  const viewMode = useDriStore((s) => s.viewMode); // i.e. "plain" or "cluster" (for sidebar)
  const useViewModeStore = useGetUseViewModeStore();
  const chartViewMode = useViewModeStore((s) => s.viewMode); // i.e. "chart", "grid", "list" (for charts layout)
  const setChartViewMode = useViewModeStore((s) => s.setViewMode);
  const gridModeCols = useViewModeStore((s) => s.numCols);
  const setGridModeCols = useViewModeStore((s) => s.setNumCols);
  const showAllMode = useDriStore((s) => s.showAllMode);
  const loading = useDriStore((s) => s.loading);
  const tags = useVariablesArrayQuery().data || [];

  const selectedGroup = useSelectedGroup();

  useHandleAnomaliesChange(); // the bulk of the logic is here

  const tagsFilterStore = useTagsFilterStoreContext();
  const tagFilters = tagsFilterStore((s) => s.tagFilters);
  const anomTimes = tagsFilterStore((s) => s.anomalyTimeFilter);

  // use this key and the cache at any point to get data for cluster/plain view if you need it

  const dvString = searchParams.get("dv");
  const vString = searchParams.get("v")?.split("-");
  const vStringFirst = vString?.[0];
  const vStringSecond = vString?.[1];

  useEffect(() => {
    if (clusters && vStringSecond) setShowingCluster(clusters[vStringSecond]);
    return () => {
      setShowingCluster(null);
    };
  }, [clusters, vStringSecond]);

  const canShowVariable = useTagsFilter(tagFilters, anomTimes);
  const chartsToDisplay = useMemo(() => {
    if (!variables || !dvString || loading) {
      return [];
    }

    const shortIdToVariableId = R.mapToObj(
      Object.values(variables),
      (variable) => {
        return [variable.short_id.toString(), variable._id];
      }
    );

    const par = _.partition(dvString.split(","), (shortIdString) =>
      showAllMode ? false : vStringFirst === shortIdString
    );

    const [showFirst, rest] = par; // only if we click on a folder, we want to show something first (basically exempt from sorting)

    const [partOfThisGroup, notPartOfThisGroup] = _.partition(
      rest.map((s) => shortIdToVariableId[s]),
      (vid) => selectedGroup.variables.includes(vid)
    );

    const metaDataMap = anomalies?.metaDataMap;

    function sortAndMerge(arr) {
      // split into levels
      const levelsToArrayOfVids = _.groupBy(
        arr,
        (vid) => metaDataMap[vid]?.level ?? 0
      ); // key by level

      _.forEach(levelsToArrayOfVids, (vids) =>
        vids.sort(
          (vidA, vidB) =>
            (metaDataMap[vidB]?.time ?? 0) - (metaDataMap[vidA]?.time ?? 0)
        )
      );

      const tuples = _.map(levelsToArrayOfVids, (vids, level) => [level, vids]);

      tuples.sort((t1, t2) => parseInt(t2[0]) - parseInt(t1[0]));

      return _.flatten(tuples.map(([_, vids]) => vids));
    }

    const finalPartOf = sortAndMerge(partOfThisGroup);
    const finalNotPartOf = sortAndMerge(notPartOfThisGroup);

    const out = [];
    if (showFirst.length) {
      const firstToShow = shortIdToVariableId[showFirst[0]];

      if (!firstToShow) {
      } else {
        out.push(firstToShow);
      }
    }

    if (finalPartOf.length) {
      out.push(...finalPartOf);
    }
    if (finalNotPartOf.length) {
      out.push(null, ...finalNotPartOf);
    }

    if (_.some(out, (out) => out === undefined)) {
      throw new Error(out);
    }

    return out.filter(canShowVariable);
  }, [
    variables,
    dvString,
    loading,
    clusters,
    selectedGroup,
    showAllMode,
    canShowVariable,
    tagFilters,
    anomTimes,
  ]);

  const numCharts = chartsToDisplay.length;
  useEffect(() => {
    setGridModeCols(numCharts > 9 ? 4 : numCharts <= 5 ? 2 : 3);
  }, [numCharts, setGridModeCols]);

  const urlVariableId = searchParams.get("v");

  function getHyphenSeparatedVariableAndClusterId(anom) {
    return `${anom.short_id}-${anom.associatedFamilyId}`;
  }

  const variablesMapQuery = useVariablesMappedByIdQuery();

  useEffect(() => {
    if (!anomalies || variablesMapQuery.data) return;
    const anomsData = anomalies?.[viewMode];

    if (loading || !anomsData) return;

    /**
     * Get all the things in the sidebar that aren't part of the watchlist (for this viewMode)
     */
    const notWatchlist = ANOMALY_LEVELS_DESC.map((level) => {
      return anomsData[level];
    }).flat();

    const nothingInTheUrl = urlVariableId === null || !urlVariableId.length;

    if (nothingInTheUrl) {
      /**
       * Randomly (not actually) pick a thing in the sidebar to select
       */
      if (anomsData.watch.length) {
        const s = anomsData.watch[0].short_id.toString();
        setSearchParams({ dv: s, v: s });
        return;
      }

      if (notWatchlist.length) {
        const firstOthers = notWatchlist[0];
        if (viewMode === views.plain) {
          const s = firstOthers.short_id.toString();
          setSearchParams({ dv: s, v: s });
          return;
        }

        // cluster view
        if (firstOthers.associatedFamilyId) {
          setSearchParams({
            dv: clusters[firstOthers.associatedFamilyId].variables.map(
              (vid) => {
                return variablesMapQuery.data[vid].short_id.toString();
              }
            ),
            v: getHyphenSeparatedVariableAndClusterId(firstOthers),
          });
          return;
        }

        const s = firstOthers.short_id.toString();
        setSearchParams({ dv: s, v: s });
        return;
      }

      // there is no data to show in the sidebar
      setSearchParams({ dv: "" });
      return;
    }

    if (urlVariableId.includes("-")) {
      // even though we see a hyphen, the searchparams api replaced it with a comma? idk why
      const [first, second] = urlVariableId.split("-");

      const fp = parseInt(first);
      const sp = parseInt(second);

      if (isNaN(fp) || isNaN(sp)) {
        setSearchParams({ v: "", dv: "" }, false, true);
        return;
      }

      if (viewMode === views.plain) {
        // use fp to find something in anomalies to check

        const watchFound = anomsData.watch.find((v) => v.short_id === fp);
        if (watchFound) {
          setSearchParams({ v: fp.toString(), dv: fp.toString() });
          return;
        }

        const othersFound = notWatchlist.find((v) => v.short_id === fp);

        if (othersFound) {
          setSearchParams({ v: fp.toString(), dv: fp.toString() });
          return;
        }

        // select one by default
        const first = anomsData.watch.length
          ? anomsData.watch[0]
          : notWatchlist.length
            ? notWatchlist[0]
            : null;

        if (first) {
          const shortId = first.short_id.toString();
          setSearchParams({ v: shortId, dv: shortId });
          return;
        }

        // nothing was found, clear it
        setSearchParams({ v: "", dv: "" });

        return;
      } else {
        const [first, second] = urlVariableId.split("-");

        const fp = parseInt(first);
        const sp = parseInt(second);

        if (isNaN(fp) || isNaN(sp)) {
          setSearchParams({ v: "", dv: "" }, false, true);
          return;
        }

        const found = notWatchlist.find(
          (a) =>
            !!a.associatedFamilyId &&
            a.associatedFamilyId === sp &&
            a.short_id === fp
        );

        if (found) {
          const dvs = clusters[found.associatedFamilyId].variables.map(
            (vid) => {
              return variablesMapQuery.data[vid].short_id.toString();
            }
          );
          setSearchParams({ dv: dvs, v: `${fp}-${sp}` });
        }

        // pick one by default
        const firstFromWatch = anomsData.watch.length
          ? anomsData.watch[0]
          : null;

        if (firstFromWatch) {
          const shortId = firstFromWatch.short_id.toString();
          setSearchParams({ v: shortId, dv: shortId });
          return;
        }

        const firstFromOthers = notWatchlist.length ? notWatchlist[0] : null;

        if (firstFromOthers) {
          if (firstFromOthers.associatedFamilyId) {
            const dvs =
              clusters[firstFromOthers.associatedFamilyId].variablesShortIds;
            const vNew =
              firstFromOthers.short_id.toString() +
              "-" +
              firstFromOthers.associatedFamilyId.toString();
            setSearchParams({ dv: dvs, v: vNew });
          } else {
            const vNew = firstFromOthers.short_id.toString();
            setSearchParams({ v: vNew, dv: vNew });
          }
          return;
        }

        // nothing was found
        setSearchParams({ v: "", dv: "" });
        return;
      }
    } else {
      const shortId = parseInt(urlVariableId);

      if (isNaN(shortId)) {
        setSearchParams({ v: "", dv: "" }, false, true);
        return;
      }

      const watchFound = anomsData.watch.find((a) => a.short_id === shortId);

      if (watchFound) {
        const s = shortId.toString();
        setSearchParams({ dv: s });
        return;
      }

      const othersFound = notWatchlist.find(
        (a) => a.short_id === shortId && !a.associatedFamilyId
      );

      if (othersFound) {
        const s = shortId.toString();
        setSearchParams({ dv: s });
        return;
      }

      // select one by default
      const firstWatch = anomsData.watch.length ? anomsData.watch[0] : null;

      if (firstWatch) {
        const s = firstWatch.short_id.toString();
        setSearchParams({ dv: s, v: s });
        return;
      }

      const firstOthers = notWatchlist.length ? notWatchlist[0] : null;

      if (firstOthers) {
        if (viewMode === views.plain) {
          const s = firstOthers.short_id.toString();
          setSearchParams({ dv: s, v: s });
          return;
        } else {
          if (firstOthers.associatedFamilyId) {
            const dvs = clusters[firstOthers.associatedFamilyId].variables.map(
              (vid) => {
                return variablesMapQuery.data[vid].short_id.toString();
              }
            );
            const vNew =
              firstOthers.short_id.toString() +
              "-" +
              firstOthers.associatedFamilyId.toString();
            setSearchParams({ dv: dvs, v: vNew });
            return;
          } else {
            const s = firstOthers.short_id.toString();
            setSearchParams({ dv: s, v: s });
            return;
          }
        }
      }

      // nothing was found, clear it
      setSearchParams({ v: "", dv: "" });
    }
  }, [anomalies, variablesMapQuery.data]);

  const toggleFullscreen = useToggleFullscreen();

  const chartDimsProps = chartDimensionsConfig(
    chartViewMode === "grid"
      ? { isGridView: true, numCols: gridModeCols }
      : { isGridView: false }
  );

  const globalAnomaliesToggled = useDriStore((s) => s.showAnomalies);
  const setGlobalAnomalyToggle = useDriStore((s) => s.setShowAnomalies);
  const globalShutdownOff = useDriStore((s) => s.globalShutdownToggle);
  const globalModeTransparencyToggled = useDriStore(
    (s) => s.globalModeTransparencyToggle
  );

  const isDefaultState =
    Object.values(tagFilters).every((v) => v === undefined) &&
    anomTimes.gt === "0m" &&
    anomTimes.lt === "24h";
  // const setGt = (el: HTMLInputElement) => {
  const setGt = (el) => {
    tagsFilterStore.getState().setAnomalyTimeFilter({
      gt: el.value,
      lt: anomTimes.lt,
    });
  };
  // const setLt = (el: HTMLInputElement) => {
  const setLt = (el) => {
    tagsFilterStore.getState().setAnomalyTimeFilter({
      gt: anomTimes.gt,
      lt: el.value,
    });
  };

  const [, setSp] = useSearchParams();

  return (
    // <FullscreenProvider>
    <TimeseriesChartTooltipStoreProvider>
      {(store) => (
        <>
          <ClickedLineControlsNeedsTimeseriesTooltipStoreProvider />
          <GlobalTooltip />
          <VariabilityDrawer />
          <ClusterDrawer />

          <div
            className={cn(
              "flex items-center pb-2 grow-0",
              showingCluster ? "justify-between" : "justify-end"
            )}
          >
            {showingCluster && (
              <div className="inline-flex items-center grow">
                <h2 className="text-lg grow flex place-items-center">
                  {showingCluster.type === "dynamic" && (
                    <BsFillLightningChargeFill className="size-4 mr-1 inline" />
                  )}
                  <span className="inline-block truncate max-w-md">
                    {showingCluster.name}
                  </span>
                  <Badge className="select-none ml-2 h-min" variant="secondary">
                    {showingCluster.variables.length} Tags
                  </Badge>
                  <ClusterScoreBadge
                    score={showingCluster.score}
                    className="ml-1"
                  />
                  {showingCluster.pairwise_score &&
                    showingCluster.pairwise_score.length > 0 && (
                      <>
                        <Tooltip label="Relationship Heatmap" withArrow>
                          <Button2
                            className="btn-ghost text-xslate-11"
                            onClick={() => setHeatmapOpen(true)}
                          >
                            <BsFillGrid3X3GapFill />
                          </Button2>
                        </Tooltip>
                        <Dialog
                          open={heatmapOpen}
                          onOpenChange={setHeatmapOpen}
                        >
                          <DialogContent className="min-w-fit">
                            <HeatMap
                              scores={showingCluster.pairwise_score.map((x) => {
                                return {
                                  tag_id1:
                                    tags.find((t) => t._id === x.tag_id1)
                                      ?.trimmedName || x.tag_id1,
                                  tag_id2:
                                    tags.find((t) => t._id === x.tag_id2)
                                      ?.trimmedName || x.tag_id2,
                                  score: x.score,
                                };
                              })}
                            />
                          </DialogContent>
                        </Dialog>
                      </>
                    )}
                  <ClusterInfoTooltip
                    className="ml-2 flex-shrink-0"
                    cluster={showingCluster}
                  />
                </h2>
              </div>
            )}

            <div className="flex flex-row gap-2 shrink-0">
              <ViewModeSelectors
                viewMode={chartViewMode}
                setViewMode={setChartViewMode}
                enabledModes={["chart", "grid", "list"]}
                withLabels={true}
                setNumCols={setGridModeCols}
                numCols={gridModeCols}
                className="flex flex-row-reverse"
              />
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={isDefaultState ? "ghost" : "default"}
                    size={"sm"}
                  >
                    <FaFilter className="size-3 mr-1.5" /> Filter
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  className="p-2 flex flex-col gap-1.5"
                  align="start"
                >
                  <h4 className="text-[0.9rem] font-semibold">Filter Tags</h4>
                  <Button
                    size={"xs"}
                    className="justify-normal"
                    onClick={() => {
                      tagsFilterStore.getState().clearTagFilters();
                    }}
                    variant={isDefaultState ? "default" : "outline"}
                  >
                    {isDefaultState ? (
                      <Check className="size-4 mr-1.5" />
                    ) : (
                      <X className="size-4 mr-1.5" />
                    )}
                    All Tags
                  </Button>
                  {Object.entries(tagFilters)
                    .map(([filter, value]) => {
                      return (
                        <div className="btn-group" key={filter}>
                          {[true, false].map((bool) => {
                            return (
                              <Button
                                key={`${filter}-${bool ? "on" : "off"}`}
                                size={"xs"}
                                className={cn(
                                  "justify-normal w-60",
                                  bool
                                    ? "rounded-r-none border-r-0"
                                    : "rounded-l-none"
                                )}
                                onClick={() => {
                                  Object.keys(tagFilters).forEach((f) => {
                                    tagsFilterStore.getState().setTagFilters(
                                      f, // f as keyof typeof tagFilters,
                                      undefined
                                    );
                                  });
                                  tagsFilterStore.getState().setTagFilters(
                                    filter, //filter as keyof typeof tagFilters,
                                    bool
                                  );
                                }}
                                variant={
                                  value === undefined
                                    ? "outline"
                                    : bool === value
                                      ? "default"
                                      : "outline"
                                }
                              >
                                {isDefaultState || value === bool ? (
                                  <Check className="size-4 mr-1.5" />
                                ) : (
                                  <X className="size-4 mr-1.5" />
                                )}
                                {filter === "Clusters"
                                  ? bool
                                    ? "in Clusters"
                                    : "not in Clusters"
                                  : (bool
                                      ? `with ${filter}`
                                      : `without ${filter}`
                                    ).replace("Anomalies", "Fresh Anomalies")}
                              </Button>
                            );
                          })}
                        </div>
                      );
                    })
                    .flat()}
                  <p className="inline-flex">Anomaly time between:</p>
                  <div className="flex flex-row">
                    <div className="rounded-l border border-xslate-7 h-8 text-sm p-1">
                      min:
                    </div>
                    <Input
                      type="text"
                      placeholder="e.g. 5m"
                      className="rounded-none border-x-0 h-8"
                      value={tagsFilterStore((s) => s.anomalyTimeFilter.gt)}
                      // onInput={(e) => setGt(e.target as HTMLInputElement)}
                      onInput={(e) => setGt(e.target)}
                    />
                    <div className="border border-xslate-7 h-8 text-sm p-1">
                      max:
                    </div>
                    <Input
                      type="text"
                      placeholder="e.g. 2h 10m"
                      className="rounded-l-none border-l-0 h-8"
                      value={tagsFilterStore((s) => s.anomalyTimeFilter.lt)}
                      // onInput={(e) => setLt(e.target as HTMLInputElement)}
                      onInput={(e) => setLt(e.target)}
                    />
                  </div>
                </PopoverContent>
              </Popover>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant={"ghost"} size={"sm"}>
                    <FaEllipsisH className="size-3 mr-1.5" /> More
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  className="p-2 flex flex-col gap-1.5"
                  align="start"
                >
                  <Button
                    size={"sm"}
                    variant={globalAnomaliesToggled ? "default" : "destructive"}
                    className="justify-normal"
                    onClick={() => {
                      setGlobalAnomalyToggle(!globalAnomaliesToggled);
                    }}
                  >
                    {iife(() => {
                      const Icon = globalAnomaliesToggled ? Check : X;
                      return <Icon className="size-4 mr-1.5" />;
                    })}
                    Anomalies
                  </Button>
                  <Button
                    size={"sm"}
                    variant={!globalShutdownOff ? "default" : "destructive"}
                    className="justify-normal"
                    onClick={() => {
                      useDriStore
                        .getState()
                        .setGlobalShutdownToggle(!globalShutdownOff);
                    }}
                  >
                    {iife(() => {
                      const Icon = !globalShutdownOff ? Check : X;
                      return <Icon className="size-4 mr-1.5" />;
                    })}
                    Shutdowns
                  </Button>
                  <Button
                    size={"sm"}
                    variant={
                      globalModeTransparencyToggled ? "default" : "destructive"
                    }
                    className="justify-normal"
                    onClick={() => {
                      useDriStore
                        .getState()
                        .setGlobalModeTransparencyToggle(
                          !globalModeTransparencyToggled
                        );
                    }}
                  >
                    {iife(() => {
                      const Icon = globalModeTransparencyToggled ? Check : X;
                      return <Icon className="size-4 mr-1.5" />;
                    })}
                    Mode Transparency
                  </Button>
                </PopoverContent>
              </Popover>
              <TooltipProvider delayDuration={50}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant={"ghost"}
                      size={"sm"}
                      onClick={() => {
                        tagsFilterStore.getState().clearTagFilters();
                        setSp((curr) => {
                          const copy = new URLSearchParams(curr);
                          copy.set(
                            DATESTATE_SP_KEYS.AXIS_RANGE_INDEX,
                            defaultRangeIndex.toString()
                          );
                          copy.delete(DRAParamsMap.selectedCommentId);
                          copy.delete(DRAParamsMap.v);
                          copy.delete(DRAParamsMap.dv);
                          copy.delete(DRAParamsMap.g);
                          return copy;
                        });
                      }}
                    >
                      <FaTimesCircle className="size-3 mr-1.5" />
                      Clear
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Remove charts from view</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
          <div
            className={cn(
              "grid mt-1",
              chartViewMode === "grid"
                ? `grid-cols-${gridModeCols} gap-4`
                : "grid-cols-1"
            )}
          >
            {chartsToDisplay.length === 0 ? (
              <div className="flex flex-col justify-center gap-1 -translate-y-1/2 absolute top-1/2 left-1/2 -translate-x-1/2">
                <div className="relative bottom-20">
                  <p className="text-[22px] flex font-semibold justify-center">
                    No variables to display
                  </p>
                  <p className="flex justify-center items-center text-zinc-500">
                    Select variables from the list.
                  </p>
                </div>
              </div>
            ) : (
              <DriCharts
                chartViewMode={chartViewMode}
                chartsToDisplay={chartsToDisplay}
                gridModeCols={gridModeCols}
                store={store}
              />
            )}
          </div>

          <FullscreenChartNeedsProvider />
        </>
      )}
    </TimeseriesChartTooltipStoreProvider>
    // </FullscreenProvider>
  );
}

const FullscreenChartNeedsProvider = ProfileBookFullscreenChart;

export default function Wrapped() {
  return (
    <DynamicRiskIndexWrapper>
      <DynamicRiskIndex />
    </DynamicRiskIndexWrapper>
  );
}

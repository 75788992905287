import { useMemo } from "react";
import { Group } from "../../../lib/api-schema/group";
import { faultTreeSchema } from "../../../lib/api-schema/ft/fault-tree";
import {
  BaseQueryOptions,
  useFaultTreesQuery,
  useFtSectionsQuery,
  useGroupsQuery,
  useSectionsQuery,
} from "../../../hooks/tanstack-query";
import { Sections } from "../../../lib/api-schema/sections";
import * as R from "remeda";
import { minLen1 } from "../../../shared-ui/lib/utils";

export type SectionWithGroups = {
  section: Sections["sections"][number];
  groups: [Group, ...Group[]];
};

export type SectionWithFaultTrees = {
  section: Sections["sections"][number];
  faultTrees: [faultTreeSchema, ...faultTreeSchema[]];
};

export function useGroupsWithSections(opts?: BaseQueryOptions) {
  const groups = useGroupsQuery(opts).data;
  const sections = useSectionsQuery(opts).data;

  return useMemo(():
    | {
        sectionsWithGroups: SectionWithGroups[];
        remainingGroups: Group[];
      }
    | undefined => {
    if (sections === undefined || !groups)
      // still loading
      return undefined;

    if (sections === null) {
      return {
        sectionsWithGroups: [],
        remainingGroups: groups,
      };
    }

    const [partOfASection, notPartOfASection] = R.partition(groups, (group) => {
      return sections.sections.some((s) => s.groups.includes(group._id));
    });

    return {
      /**
       * The ordering of the sections are determined by the
       * lowest display_idx of the groups in that section.
       *
       * Sections by themselves have no ordering unless they
       * have groups in them.
       */
      sectionsWithGroups: sections.sections
        .map((x): SectionWithGroups | undefined => {
          const groupsPartOfThisSection = partOfASection
            .filter((group) => x.groups.includes(group._id))
            .sort((a, b) => a.display_id - b.display_id);

          if (!minLen1(groupsPartOfThisSection)) return undefined;

          return {
            section: x,
            groups: groupsPartOfThisSection,
          };
        })
        .filter((x) => x !== undefined)
        .sort((a, b) => a.groups[0].display_id - b.groups[0].display_id),
      remainingGroups: notPartOfASection.sort(
        (a, b) => a.display_id - b.display_id
      ),
    };
  }, [groups, sections]);
}

export function useFaultTreesWithSections() {
  const faultTrees = useFaultTreesQuery().data;
  const sections = useFtSectionsQuery().data;

  return useMemo(():
    | {
        sectionsWithFaultTrees: SectionWithFaultTrees[];
        remainingFaultTrees: faultTreeSchema[];
      }
    | undefined => {
    if (sections === undefined || !faultTrees)
      // still loading
      return undefined;

    if (sections === null) {
      return {
        sectionsWithFaultTrees: [],
        remainingFaultTrees: faultTrees,
      };
    }

    const [partOfASection, notPartOfASection] = R.partition(
      faultTrees,
      (ft) => {
        return sections.sections.some((s) => s.groups.includes(ft._id));
      }
    );

    return {
      /**
       * The ordering of the sections are determined by the
       * lowest display_idx of the groups in that section.
       *
       * Sections by themselves have no ordering unless they
       * have groups in them.
       */
      sectionsWithFaultTrees: sections.sections
        .map((x): SectionWithFaultTrees | undefined => {
          const faultTreesPartOfThisSection = partOfASection
            .filter((ft) => x.groups.includes(ft._id))
            .sort((a, b) => a.displayId - b.displayId);

          if (!minLen1(faultTreesPartOfThisSection)) return undefined;

          return {
            section: x,
            faultTrees: faultTreesPartOfThisSection,
          };
        })
        .filter((x) => x !== undefined)
        .sort((a, b) => a.faultTrees[0].displayId - b.faultTrees[0].displayId),
      remainingFaultTrees: notPartOfASection.sort(
        (a, b) => a.displayId - b.displayId
      ),
    };
  }, [faultTrees, sections]);
}

import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from "react";
import { createStore } from "zustand";

function createGroupDrawerStore() {
  return createStore<{
    groupId: string | undefined;
    setGroupId: (groupId: string | undefined) => void;
  }>((set) => ({
    groupId: undefined,
    setGroupId: (groupId) => set({ groupId }),
  }));
}

type GroupDrawerStore = ReturnType<typeof createGroupDrawerStore>;

const GroupDrawerStoreContext = createContext<GroupDrawerStore | undefined>(
  undefined
);

function GroupDrawerStoreProvider({ children }: PropsWithChildren) {
  const [store] = useState(createGroupDrawerStore);
  return (
    <GroupDrawerStoreContext.Provider value={store}>
      {children}
    </GroupDrawerStoreContext.Provider>
  );
}

function useGroupDrawerStoreNotRequired() {
  return useContext(GroupDrawerStoreContext);
}

function useGroupDrawerStore() {
  const store = useContext(GroupDrawerStoreContext);

  if (!store) {
    throw new Error("useGroupStore must be used within a GroupStoreProvider");
  }

  return store;
}

export {
  GroupDrawerStoreProvider,
  useGroupDrawerStore,
  useGroupDrawerStoreNotRequired,
};

import { FaEye, FaEyeSlash, FaTrashAlt } from "react-icons/fa";
import { Button } from "../../frontend/button";
import { type SelectedVariable, Atoms } from "../svv-store/use-svv-store";
import { cn, PropsWithCn } from "../../frontend/cn";
import { type ComponentProps } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { assertMinLen1 } from "../../lib/utils";
import {
  isExpressionVariant,
  isVariableVariant,
  trendDataMatches,
  TrendLineVariant,
} from "../draw/draw";

type ControlButtonSize = Pick<ComponentProps<typeof Button>, "size">;

const setAnomColorationAtom = atom(null, (get, set, bv: string) => {
  const selectedVariables = get(Atoms.selectedVariablesAtom);

  const newSelectedVariables = selectedVariables.map((bvObj) => {
    if (isExpressionVariant(bvObj)) return bvObj;

    if (bvObj.bv === bv) {
      const newAnomOn = !bvObj.anom;

      return {
        ...bvObj,
        anom: newAnomOn,
      };
    }
    return bvObj;
  });

  set(Atoms.selectedVariablesAtom, assertMinLen1(newSelectedVariables));
});

function EyeButton({
  batchVariable,
  className,
  size,
}: {
  batchVariable: SelectedVariable;
} & PropsWithCn<ControlButtonSize>) {
  const isVariableTrendLine = isVariableVariant(batchVariable);
  const isAnomalyColoration = isVariableTrendLine ? batchVariable.anom : false;
  const ColorationIcon = isAnomalyColoration ? FaEye : FaEyeSlash;
  const onAnomalyColorationToggle = useSetAtom(setAnomColorationAtom);

  if (!isVariableTrendLine) return;
  return (
    <Button
      variant={"ghost"}
      size={size ?? "icon"}
      className={cn(
        "hover:bg-inherit hover:border-xslate-11 border border-transparent",
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        onAnomalyColorationToggle(batchVariable.bv);
      }}
    >
      <ColorationIcon className="size-3" />
    </Button>
  );
}

const removeBatchVariableAtom = atom(null, (get, set, bv: SelectedVariable) => {
  const selectedVariables = get(Atoms.selectedVariablesAtom);
  const newSelected = selectedVariables.filter(
    (x) => !trendDataMatches(x, bv)
  ) as typeof selectedVariables;
  set(Atoms.selectedVariablesAtom, newSelected);
});

const canDeleteAtom = atom((get) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const first = get(Atoms.selectedVariablesAtom)[0]!;
  return (bv: TrendLineVariant) => !trendDataMatches(bv, first);
});

function DeleteButton({
  onClose,
  batchVariable,
  className,
  size,
}: {
  onClose?: () => void;
  batchVariable: SelectedVariable;
} & PropsWithCn<ControlButtonSize>) {
  const canDelete = useAtomValue(canDeleteAtom)(batchVariable);

  const setHoveredLine = useSetAtom(Atoms.hoveredLineAtom);
  const removeBatchVariable = useSetAtom(removeBatchVariableAtom);

  return (
    <Button
      variant={"ghost"}
      size={size ?? "icon"}
      disabled={!canDelete}
      onClick={
        canDelete
          ? (e) => {
              e.stopPropagation();
              onClose?.();
              removeBatchVariable(batchVariable);
              setHoveredLine(undefined);
            }
          : undefined
      }
      className={cn(
        "hover:bg-inherit hover:border-xslate-11 border border-transparent",
        className
      )}
    >
      <FaTrashAlt className="size-3" />
    </Button>
  );
}

export { EyeButton, DeleteButton };

import "../pb/ProfileBook.scss";
import DateSelectorsGroupSelect from "../dateSelector/DateSelectorsGroupSelect";
import { DRAParamsMap } from "../boundaries/hooks/useDRAParams2";
import {
  useAriaClustersQuery,
  useGroupsQuery,
  usePersonalFoldersQuery,
  useVariablesArrayQuery,
} from "../../hooks/tanstack-query";
import { ComponentProps, memo, useEffect, useState } from "react";
import { OVERALL_GROUP_NAME } from "../../lib/api-schema/misc";
import { useBaseUrlExperimental } from "../../zustand/useBaseUrl";
import {
  VariabilityDrawer,
  VariabilityDrawerStoreProvider,
} from "../variability-view/variability-drawer";
import {
  TimeseriesChartTooltipStoreProvider,
  useGetUseTimeseriesChartsTooltipStore,
  useShowClickedLine,
} from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "../time-series/global-tooltip";
import { ClickedLineControlsNeedsTimeseriesTooltipStoreProvider } from "../time-series/secondary-variable-view/control-buttons";
import {
  FullscreenDrawer,
  FullscreenProvider,
  useToggleFullscreen,
} from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import {
  createJotaiStoreForChartSync,
  chartDimensionsConfig,
  DRASecondaryVariableViewNeedsProvider,
} from "../time-series/secondary-variable-view/dra-secondary-variable.view";
import { ClusterDrawer } from "../clusters/cluster-drawer/cluster-drawer";
import { ClusterDrawerStoreProvider } from "../clusters/cluster-drawer/use-cluster-drawer-store";
import { Provider, useAtomValue } from "jotai";
import { FullscreenChartContainer } from "../../shared-ui/time-series-2/secondary-variable-view/secondary-variable-view";
import {
  useGetUseViewModeStore,
  UseViewModeStoreProvider,
} from "../../shared-ui/time-series-2/grid-view-store";
import { useSearchParams } from "react-router-dom";
import { ProfileBookSidebar } from "./sidebar/profile-book-sidebar";
import useDocumentTitle from "../common/hooks/useDocumentTitle";
import { cn, iife } from "../../lib/utils";
import { ProfileBookControlButtons } from "./profile-book-control-buttons";
import { useCallback, useMemoOne as useMemo } from "use-memo-one";
import {
  UseCreateOrEditFolderStoreProvider,
  useGetUseCreateOrEditFolderStore,
} from "./create-or-edit-folder-store";
import {
  useGetUseProfileBookStoreRequired,
  UseProfileBookStoreProvider,
} from "./use-profile-book-store";
import { FaFolder, FaSearch } from "react-icons/fa";
import {
  UseDisplayedChartsStoreProvider,
  useGetUseDisplayedChartsStores,
} from "./use-displayed-charts-store";
import {
  Atoms,
  isVariableTrendLine,
} from "../../shared-ui/time-series-2/svv-store/use-svv-store";
import { assertMinLen1, minLen1 } from "../../shared-ui/lib/utils";
import { useTimezone } from "../../zustand/config/useConfigStore";
import { v4 } from "uuid";
import { useTagsFilter } from "../tags/use-tags-filter";
import { CorrelatedTagsDrawer } from "../clusters/cluster-drawer/correlated-tags-drawer";
import { CorrelatedTagsDrawerStoreProvider } from "../clusters/cluster-drawer/use-correlated-tags-drawer-store";
import ClusterInfoTooltip from "../clusters/ClusterInfoTooltip";
import {
  TagsFilterStoreProvider,
  useTagsFilterStoreContext,
} from "../tags/use-tags-filter-store";
import { ClusterScoreBadge } from "../aria/cluster-score-badge";
import { Badge } from "../../shared-ui/frontend/badge";
import {
  BsFillGrid3X3GapFill,
  BsFillLightningChargeFill,
} from "react-icons/bs";
import Nav from "../nav/Nav";
import DateStateNav from "../dateSelector/date-state-nav";
import {
  globalGroupsSidebarOpenAtom,
  SelectedGroupContextProvider,
  ToggleGlobalSidebarButton,
} from "../common/sidebar/generic-global-sidebar-with-groups";
import { GlobalDriSidebar } from "../dri/global-dri-sidebar";
import { Group } from "../../lib/api-schema/group";
import { Tooltip } from "@mantine/core";
import Button from "../common/Button/Button";
import { Dialog, DialogContent } from "../../shared-ui/frontend/dialog";
import { HeatMap } from "../clusters/manager/HeatMap";
import { GroupDrawerStoreProvider } from "../groups/group-drawer/use-group-drawer-store";
import { GroupDrawer } from "../groups/group-drawer/group-drawer";

function useHandleGroupSearchParam() {
  const groupsQuery = useGroupsQuery();
  const [sp, setSp] = useSearchParams();
  const groups = groupsQuery.data;
  const groupId = sp.get(DRAParamsMap.g)?.trim() || null;

  useEffect(() => {
    if (!groups) {
      return;
    }

    const needToSetAValidGroupId =
      !groupId || !groups.find((g) => g.short_id === parseInt(groupId));

    if (!needToSetAValidGroupId) {
      return;
    }

    const groupToSet =
      groups.find((g) => g.name === OVERALL_GROUP_NAME) ?? groups[0];

    if (!groupToSet) {
      return;
    }

    setSp((curr) => {
      const copy = new URLSearchParams(curr);
      copy.set(DRAParamsMap.g, groupToSet.short_id.toString());

      return copy;
    });
  }, [groupId, groups, setSp]);
}

function useOnGroupSelect() {
  const [, setSp] = useSearchParams();
  return useCallback(
    (groupShort: string) => {
      setSp((curr) => {
        const copy = new URLSearchParams(curr);
        copy.set(DRAParamsMap.g, groupShort);
        return copy;
      });
    },
    [setSp]
  );
}

export function GroupSelect({
  onChange,
}: {
  onChange: (groupShort: string) => void;
}) {
  const groupsQuery = useGroupsQuery();
  const groups = groupsQuery.data;

  const [sp] = useSearchParams();

  const groupShortRaw = sp.get(DRAParamsMap.g)?.trim() || null;

  return (
    groups && (
      <DateSelectorsGroupSelect
        action={(_id) => {
          const g = groups.find((x) => x._id === _id);
          g && onChange(g.short_id.toString());
        }}
        options={(groups ?? []).map((g) => ({
          value: g._id,
          label: g.name,
        }))}
        value={
          groupShortRaw
            ? groups?.find((x) => x.short_id === parseInt(groupShortRaw))?._id
            : undefined
        }
      />
    )
  );
}

export function useListOfChartsFromSearchParams() {
  const [sp] = useSearchParams();
  const dv = sp.get(DRAParamsMap.dv)?.trim();

  type Chart = [number, ...number[]];

  const charts: [Chart, ...Chart[]] | undefined = useMemo(() => {
    if (!dv) return undefined;

    const spl = dv.split(",").map((x) => x.trim());

    const out: Chart[] = spl
      .map((thing): Chart | undefined => {
        const shorts = thing
          .split("-")
          .map((x) => parseInt(x))
          .filter((x) => !isNaN(x));

        if (!shorts.length) return undefined;
        if (shorts.length === 1) return [shorts[0] as number];
        return shorts as Chart;
      })
      .filter((x) => x !== undefined);

    if (out.length === 0) return undefined;

    return out as [Chart, ...Chart[]];
  }, [dv]);

  return charts;
}

const GlobalSidebar = memo(function () {
  const [sp] = useSearchParams();
  const onGroupChange = useOnGroupSelect();

  const groupsQuery = useGroupsQuery();
  const groups = groupsQuery.data;
  const gShort = sp.get(DRAParamsMap.g)?.trim();

  const group = useMemo(
    () => groups?.find((x) => x.short_id.toString() === gShort),
    [gShort, groups]
  );

  return (
    <SelectedGroupContextProvider
      selectedGroup={group}
      onSelect={useCallback(
        (g: Group) => onGroupChange(g.short_id.toString()),
        [onGroupChange]
      )}
    >
      <GlobalDriSidebar />
    </SelectedGroupContextProvider>
  );
});
GlobalSidebar.displayName = "GlobalSidebar";

const SecondNav = memo(function () {
  const globalSidebarOpen = useAtomValue(globalGroupsSidebarOpenAtom);
  const onGroupChange = useOnGroupSelect();
  return (
    <DateStateNav
      leftmostChildren={
        <>
          <ToggleGlobalSidebarButton />
          {!globalSidebarOpen && <GroupSelect onChange={onGroupChange} />}
        </>
      }
    />
  );
});
SecondNav.displayName = "SecondNav";

function DummyLeaf() {
  /**
   * Put expensive hooks that will cause re-renders often in
   * the leaves of component trees.
   */
  useHandleGroupSearchParam();
  return undefined;
}

const ProfileBook = memo(function () {
  return (
    <>
      <DummyLeaf />
      <Nav />
      <div
        id="interactible-container"
        className={
          "relative MIN_H_SCREEN animate-in slide-in-from-bottom-7 duration-300"
        }
      >
        <div className="flex">
          <GlobalSidebar />
          <div className="grow">
            <SecondNav />
            <div className="flex">
              <ProfileBookSidebar />
              <div
                className={
                  "inline-flex grow flex-col gap-3 px-10 pt-4 pb-8 min-h-[calc(100vh-3rem)] relative"
                }
              >
                <div className="flex w-full items-center">
                  <Title />
                  <div className="inline-flex gap-1 ml-auto flex-wrap">
                    <ProfileBookControlButtons />
                  </div>
                </div>
                <CreateOrEditFolderCharts />
                <Charts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
ProfileBook.displayName = "ProfileBook";

function Title() {
  const usePbStore = useGetUseProfileBookStoreRequired();
  const useCreateOrEditFolderStore = useGetUseCreateOrEditFolderStore();
  const isCreatingOrEditing = useCreateOrEditFolderStore((s) => s.data);
  const charts = useListOfChartsFromSearchParams();
  const title = usePbStore((s) => s.title);
  const groups = useGroupsQuery().data;
  const folders = usePersonalFoldersQuery().data;
  const clusters = useAriaClustersQuery().data;
  const tags = useVariablesArrayQuery().data || [];
  const [heatmapOpen, setHeatmapOpen] = useState(false);

  let isCluster;

  const t = iife(() => {
    if (isCreatingOrEditing) {
      if (isCreatingOrEditing.editingFolderId) {
        return (
          folders?.folder.find(
            (x) => x._id === isCreatingOrEditing.editingFolderId
          )?.name ?? "Editing Folder"
        );
      }
      return "New Folder";
    }

    if (title.type === "personal-folders") {
      const folderId = title._id;
      return folders?.folder.find((x) => x._id === folderId)?.name ?? "Folder";
    }

    if (title.type === "groups") {
      const groupId = title._id;
      return groups?.find((x) => x._id === groupId)?.name ?? "";
    }

    if (title.type === "clusters") {
      const clusterId = title._id;
      const foundCluster = clusters?.find((x) => x._id === clusterId);
      isCluster = foundCluster;
      return foundCluster?.name ?? "";
    }

    if (title.type === "current-tags") return "Current Tags";

    return title.name;
  });

  // all this just to get the number of charts with tag filters applied (otherwise could just do charts.length)
  const tagsFilterStore = useTagsFilterStoreContext();
  const tagFilters = tagsFilterStore((s) => s.tagFilters);
  const anomalyTimeFilter = tagsFilterStore((s) => s.anomalyTimeFilter);
  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();
  const chartStores = useDisplayedChartsStore((s) => s.charts);
  const tagsFilter = useTagsFilter(tagFilters, anomalyTimeFilter);
  const filteredTags = (chartStores || [])
    .map((store) => {
      const primaryVariable = store.store.get(Atoms.primaryBatchVariableAtom);
      if (!isVariableTrendLine(primaryVariable))
        throw new Error("not possible on pb");
      const v = primaryVariable?.bv.slice(24);
      return v;
    })
    .filter(tagsFilter);

  const withNumCharts =
    isCreatingOrEditing || isCluster || !charts || !charts.length
      ? undefined
      : filteredTags.length;

  const cluster = clusters?.find(
    (x) => title.type === "clusters" && x._id === title._id
  );

  return (
    <>
      <h2
        key={t}
        className="text-2xl font-semibold animate-in slide-in-from-left-4 fade-in-15 truncate max-w-md flex flex-row gap-2 place-items-center"
      >
        {isCluster && cluster?.type === "dynamic" && (
          <BsFillLightningChargeFill className="size-4 inline" />
        )}
        <div>{t}</div>
        {withNumCharts && (
          <Badge
            size="sm"
            variant="secondary"
            className="select-none h-min whitespace-nowrap"
          >
            {withNumCharts} Tags
          </Badge>
        )}
      </h2>
      {isCluster && (
        <div className="flex flex-row gap-2 mr-2 place-items-center">
          <Badge
            className="select-none ml-2 h-min whitespace-nowrap"
            variant="secondary"
          >
            {cluster?.variables.length} Tags
          </Badge>
          <ClusterScoreBadge
            className="mt-1 relative bottom-0.5"
            score={cluster?.score}
          />
          {cluster &&
            cluster.pairwise_score &&
            cluster.pairwise_score.length > 0 && (
              <>
                <Tooltip label="Relationship Heatmap" withArrow>
                  <Button
                    className="btn-ghost text-xslate-11"
                    onClick={() => setHeatmapOpen(true)}
                  >
                    <BsFillGrid3X3GapFill />
                  </Button>
                </Tooltip>
                <Dialog open={heatmapOpen} onOpenChange={setHeatmapOpen}>
                  <DialogContent className="min-w-fit">
                    <HeatMap scores={cluster.pairwise_score} />
                  </DialogContent>
                </Dialog>
              </>
            )}
          <ClusterInfoTooltip cluster={isCluster} />
        </div>
      )}
    </>
  );
}

const chartDimsNoGrid = chartDimensionsConfig({ isGridView: false });

function CreateOrEditFolderCharts() {
  const useCreateOrEditFolderStore = useGetUseCreateOrEditFolderStore();
  const folderCharts = useCreateOrEditFolderStore((s) => s.data);
  const removeChart = useCreateOrEditFolderStore((s) => s.removeChart);
  const showClickedLine = useShowClickedLine();

  if (!folderCharts) return;

  const { charts } = folderCharts;

  return (
    <div className="grid gap-4">
      {charts.map(({ chartJotaiStore, primaryVariableId }) => {
        return (
          <Provider store={chartJotaiStore}>
            <DRASecondaryVariableViewNeedsProvider
              onLineClick={(numVars) => {
                if (numVars < 2) return undefined;

                return ({ bvOrId, clientX, clientY, jotaiStore }) => {
                  showClickedLine({
                    clientX,
                    clientY,
                    jotaiStore,
                    bvOrId,
                  });
                };
              }}
              respondToSelectedCommentId
              onClose={() => removeChart(primaryVariableId)}
              ableToGetTaller
              {...chartDimsNoGrid}
              padding={{
                left: 45,
              }}
              expandable
            />
          </Provider>
        );
      })}
    </div>
  );
}

function Charts() {
  const usePbStore = useGetUseProfileBookStoreRequired();
  const setTitle = usePbStore((s) => s.setTitle);
  const useViewModeStore = useGetUseViewModeStore();
  const numCols = useViewModeStore((s) => s.numCols);
  const isGridView = useViewModeStore((s) => s.viewMode === "grid");
  const isEditingOrCreating = useGetUseCreateOrEditFolderStore()(
    (s) => !!s.data
  );

  const toggleFullscreen = useToggleFullscreen();
  const showClickedLine = useShowClickedLine();

  const useDisplayedChartsStore = useGetUseDisplayedChartsStores();
  const chartStores = useDisplayedChartsStore((s) => s.charts);
  const zone = useTimezone();

  useEffect(() => {
    const currViewMode = useViewModeStore.getState().viewMode;

    const handle = (v: typeof currViewMode) => {
      const isChartView = v === "chart";
      if (!isChartView) {
        useDisplayedChartsStore.setState({
          overlayChart: undefined,
        });
        return;
      }

      // we are in overlay view

      if (!chartStores) {
        useDisplayedChartsStore.setState({
          overlayChart: undefined,
        });
        return;
      }

      const maybeDuplicates = chartStores.flatMap(({ store }) => {
        const variableIds = store
          .get(Atoms.onlyVariableTrendLinesAtom)
          .map(({ bv }) => bv.slice(24));

        return variableIds;
      });

      const uniqueVids = [...new Set(maybeDuplicates)];

      if (!minLen1(uniqueVids)) {
        // must have 1 or more in the chart
        useDisplayedChartsStore.setState({
          overlayChart: undefined,
        });
        return;
      }

      const store = createJotaiStoreForChartSync({
        initialBatchVariables: assertMinLen1(
          uniqueVids.map((vid) => {
            return {
              type: "variable",
              bv: vid.padStart(48, "0"),
            };
          })
        ),
        initialExpanded: true, // chart view shows 1 chart, so make it expanded
        zone,
      });

      store.set(Atoms.setSlopingTrendsAtom, {
        on: usePbStore.getState().showSlopingTrends,
      });

      useDisplayedChartsStore.setState({ overlayChart: { store, id: v4() } });
    };

    handle(currViewMode);

    return useViewModeStore.subscribe((s) => s.viewMode, handle);
    // useDisplayedChartsStore.subscribe((s) => s.charts);
  }, [
    useDisplayedChartsStore,
    useViewModeStore,
    usePbStore,
    zone,
    chartStores,
  ]);

  const chartViewStore = useDisplayedChartsStore((s) => s.overlayChart);

  const tagsFilterStore = useTagsFilterStoreContext();
  const tagFilters = tagsFilterStore((s) => s.tagFilters);
  const anomalyTimeFilter = tagsFilterStore((s) => s.anomalyTimeFilter);
  const canShowVariable = useTagsFilter(tagFilters, anomalyTimeFilter);
  const filteredCharts = chartStores?.filter((store) => {
    const primaryVariable = store.store.get(Atoms.primaryBatchVariableAtom);
    if (!isVariableTrendLine(primaryVariable))
      throw new Error("not possible on pb");
    const v = primaryVariable?.bv.slice(24);
    return canShowVariable(v);
  });

  const numCharts = filteredCharts?.length || 0;
  const setNumCols = useViewModeStore((s) => s.setNumCols);
  useEffect(() => {
    setNumCols(numCharts > 15 ? 4 : numCharts <= 5 ? 2 : 3);
  }, [numCharts, setNumCols]);

  if (isEditingOrCreating) return;
  if (!filteredCharts?.length)
    return (
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col gap-1">
          <p className="text-[22px] flex font-semibold justify-center">
            No variables to display
          </p>
          <p className="flex justify-center items-center text-zinc-500">
            Select&nbsp;&nbsp;
            <FaFolder />
            &nbsp;Folders or&nbsp;&nbsp;
            <FaSearch />
            &nbsp;SEARCH to add variables.
          </p>
        </div>
      </div>
    );

  const chartProps = chartDimensionsConfig(
    isGridView ? { isGridView: true, numCols } : { isGridView: false }
  );

  return (
    <div className={cn("grid gap-4", isGridView && `grid-cols-${numCols}`)}>
      {chartViewStore ? (
        <Provider store={chartViewStore.store} key={chartViewStore.id}>
          <DRASecondaryVariableViewNeedsProvider
            /**
             * If you're in grid view, opening a comment will open the fullscreen
             * view with the comment open.
             */
            commentsOnlyInFullscreen={isGridView}
            onLineClick={iife(() => {
              if (isGridView) {
                return (
                  toggleFullscreen &&
                  (() =>
                    ({ jotaiStore }) =>
                      toggleFullscreen(jotaiStore))
                );
              }

              return (numVars) => {
                if (numVars < 2) return undefined;

                return ({ bvOrId, clientX, clientY, jotaiStore }) => {
                  showClickedLine({
                    clientX,
                    clientY,
                    jotaiStore,
                    bvOrId,
                  });
                };
              };
            })}
            onVariabilityClick={iife(() => {
              if (!isGridView) return;

              return (
                toggleFullscreen &&
                (() => toggleFullscreen(chartViewStore.store))
              );
            })}
            respondToSelectedCommentId
            ableToGetTaller={!isGridView}
            {...chartProps}
            padding={
              isGridView
                ? { left: 45, right: 115, bottom: 100 }
                : {
                    left: 45,
                  }
            }
            expandable
            numTicks={isGridView ? 4 : undefined}
            onlyMinMaxYAxes={isGridView}
          />
        </Provider>
      ) : (
        filteredCharts.map((store) => {
          return (
            <Provider store={store.store} key={store.id}>
              <DRASecondaryVariableViewNeedsProvider
                /**
                 * If you're in grid view, opening a comment will open the fullscreen
                 * view with the comment open.
                 */
                commentsOnlyInFullscreen={isGridView}
                onLineClick={iife(() => {
                  if (isGridView) {
                    return (
                      toggleFullscreen &&
                      (() =>
                        ({ jotaiStore }) =>
                          toggleFullscreen(jotaiStore))
                    );
                  }

                  return (numVars) => {
                    if (numVars < 2) return undefined;

                    return ({ bvOrId, clientX, clientY, jotaiStore }) => {
                      showClickedLine({
                        clientX,
                        clientY,
                        jotaiStore,
                        bvOrId,
                      });
                    };
                  };
                })}
                onVariabilityClick={iife(() => {
                  if (!isGridView) return;

                  return (
                    toggleFullscreen && (() => toggleFullscreen(store.store))
                  );
                })}
                respondToSelectedCommentId
                onClose={() => {
                  setTitle({ type: "current-tags" });
                  useDisplayedChartsStore.getState().removeChart(store.id);
                }}
                ableToGetTaller={!isGridView}
                {...chartProps}
                padding={
                  isGridView
                    ? { left: 45, right: 115, bottom: 100 }
                    : {
                        left: 45,
                      }
                }
                expandable
                numTicks={isGridView ? 4 : undefined}
                onlyMinMaxYAxes={isGridView}
              />
            </Provider>
          );
        })
      )}
    </div>
  );
}

/**
 * Trial and error, but these numbers make the chart look
 * decent and we use it in a few places.
 *
 * Must be a function because innerHeight and innerWidth
 * can change so we want these values on-use, not evaluated
 * once.
 *
 * They technically aren't reactive values, but we'll cross
 * that bridge when we get there.
 */
export const FULLSCREEN_PROPS = () =>
  ({
    height: window.innerHeight * 0.9 * 2,
    width: window.innerWidth * 2,
    lineWidthScale: 0.3,
    axesFontScale: 0.7,
  }) as const satisfies Pick<
    ComponentProps<typeof DRASecondaryVariableViewNeedsProvider>,
    "height" | "width" | "lineWidthScale" | "axesFontScale"
  >;

export function ProfileBookFullscreenChart() {
  const useTooltipStore = useGetUseTimeseriesChartsTooltipStore();
  return (
    <FullscreenDrawer>
      {(store) => (
        <FullscreenChartContainer>
          <Provider store={store}>
            <DRASecondaryVariableViewNeedsProvider
              onLineClick={
                useTooltipStore &&
                ((numVars) => {
                  if (numVars < 2) return undefined;

                  return ({ bvOrId, clientX, clientY, jotaiStore: _ }) => {
                    useTooltipStore.getState().setClickedLineData({
                      clientX,
                      clientY,
                      jotaiStore: store,
                      bvOrId,
                    });
                  };
                })
              }
              ableToGetTaller={false}
              {...FULLSCREEN_PROPS()}
            />
          </Provider>
        </FullscreenChartContainer>
      )}
    </FullscreenDrawer>
  );
}

export function ProfileBookWithProviders() {
  const baseUrl = useBaseUrlExperimental();

  useDocumentTitle("Profile Book > DRA");

  return (
    <UseProfileBookStoreProvider key={baseUrl}>
      <VariabilityDrawerStoreProvider>
        <GroupDrawerStoreProvider>
          <ClusterDrawerStoreProvider>
            <CorrelatedTagsDrawerStoreProvider>
              <TagsFilterStoreProvider>
                <UseViewModeStoreProvider>
                  <FullscreenProvider>
                    <TimeseriesChartTooltipStoreProvider>
                      {() => (
                        <>
                          <ClickedLineControlsNeedsTimeseriesTooltipStoreProvider />
                          <GlobalTooltip />
                          <VariabilityDrawer />
                          <ClusterDrawer />
                          <GroupDrawer />
                          <CorrelatedTagsDrawer />
                          <UseCreateOrEditFolderStoreProvider>
                            <UseDisplayedChartsStoreProvider>
                              <Provider>
                                <ProfileBook />
                              </Provider>
                            </UseDisplayedChartsStoreProvider>
                          </UseCreateOrEditFolderStoreProvider>
                          <ProfileBookFullscreenChart />
                        </>
                      )}
                    </TimeseriesChartTooltipStoreProvider>
                  </FullscreenProvider>
                </UseViewModeStoreProvider>
              </TagsFilterStoreProvider>
            </CorrelatedTagsDrawerStoreProvider>
          </ClusterDrawerStoreProvider>
        </GroupDrawerStoreProvider>
      </VariabilityDrawerStoreProvider>
    </UseProfileBookStoreProvider>
  );
}

import { GripVertical, Pencil, Trash2 } from "lucide-react";
import { cn, type PropsWithCn } from "../../../shared-ui/frontend/cn";
import { Button } from "../../ui/button";
import { GroupCard } from "./group-card";
import {
  SectionWithGroups,
  useGroupsWithSections,
} from "./use-groups-with-sections";
import {
  DndContext,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMutation } from "@tanstack/react-query";
import {
  editSection,
  reorderAllGroupsForAUnit,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  useInvalidateGroupsQuery,
  useInvalidateSectionsQuery,
} from "../../../hooks/tanstack-query";
import {
  addSuccessToast,
  addUnknownErrorToast,
} from "../../toast/use-toast-store";
import { useEffect, useId, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../shared-ui/frontend/dialog";
import { CreateOrEditSectionForm } from "./create-section-button";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import { GenericDeleteTriggerWithConfirmationPopup } from "../../../shared-ui/frontend/generic-delete-confirm";
import { Badge } from "../../ui/badge";

export function SectionCard({
  className,
  section: sectionAndGroups,
  disabled,
  showDragHandle,
}: PropsWithCn<{
  section: SectionWithGroups;
  disabled?: boolean;
  showDragHandle: boolean;
}>) {
  const hasEditAccess = useHasEditPermission();

  const { groups, section } = sectionAndGroups;

  const dragDisabled = !hasEditAccess || disabled;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: section._id,
    disabled: dragDisabled,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        // see https://github.com/clauderic/dnd-kit/issues/44
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      {...listeners}
      {...(dragDisabled ? undefined : attributes)} // this will remove the pointer finger cursor when it's disabled
      className={cn(
        "rounded-md border border-xslate-7 my-3 bg-white select-none",
        isDragging && "ring-2 ring-offset-2 ring-purple-500 opacity-50",
        className
      )}
    >
      <div className="p-3 relative">
        {showDragHandle && !dragDisabled && (
          <GripVertical className="size-5 text-xslate-9 absolute left-0 top-1/2 -translate-x-[130%] -translate-y-1/2" />
        )}
        <div className="flex item">
          <span className="font-medium">{section.name}</span>
          <Badge variant={"secondary"} className="ml-3">
            {`${section.groups.length} group${section.groups.length === 1 ? "" : "s"}`}
          </Badge>
          {hasEditAccess && (
            <div className="inline-flex gap-1.5 ml-auto">
              <DeleteSectionButton section={sectionAndGroups} />
              <EditSectionButton section={sectionAndGroups} />
            </div>
          )}
        </div>
        {groups.map((group) => {
          return (
            <GroupCard
              className={"bg-xslate-2"}
              key={group._id}
              group={group}
            />
          );
        })}
      </div>
    </div>
  );
}

function DeleteSectionButton({ section }: { section: SectionWithGroups }) {
  const b = useBaseUrlExperimental();
  const refreshSections = useInvalidateSectionsQuery();
  const refreshGroups = useInvalidateGroupsQuery();
  const refresh = () => {
    refreshGroups();
    refreshSections();
  };

  const deleteMut = useMutation({
    mutationFn: async () => {
      await editSection(b, section.section._id, {
        name: "doesn't really matter cuz it gets deleted",
        groups: [], // empty for deletion
        type: "groups",
      });
    },
    onError: (e) => {
      refresh();
      addUnknownErrorToast(e);
    },
    onSuccess: () => {
      refresh();
      addSuccessToast(`${section.section.name} deleted`);
    },
  });

  return (
    <GenericDeleteTriggerWithConfirmationPopup
      title={`Are you sure you want to delete ${section.section.name}?`}
      onConfirm={deleteMut.mutate}
      confirmDisabled={deleteMut.isLoading}
    >
      <Button variant={"ghost"} size={"icon-sm"}>
        <Trash2 className="size-3.5" />
      </Button>
    </GenericDeleteTriggerWithConfirmationPopup>
  );
}

export function useEditSectionMutation(opts?: { onSuccess?: () => void }) {
  const b = useBaseUrlExperimental();
  const refreshSections = useInvalidateSectionsQuery();
  const refreshGroups = useInvalidateGroupsQuery();
  const refresh = () => {
    refreshGroups();
    refreshSections();
  };
  const mut = useMutation({
    mutationFn: async ({
      groupIds,
      name,
      sectionId,
    }: {
      sectionId: string;
      name: string;
      groupIds: [string, ...string[]];
    }) => editSection(b, sectionId, { name, groups: groupIds, type: "groups" }),
    onError: (e) => {
      refresh();
      addUnknownErrorToast(e);
    },
    onSuccess: ({ sections }, { sectionId }) => {
      const found = sections.find((x) => x._id === sectionId);
      found && addSuccessToast(`${found.name} updated`);
      opts?.onSuccess?.();
      refresh();
    },
  });

  return mut;
}

function EditSectionButton({
  className,
  section,
}: PropsWithCn<{ section: SectionWithGroups }>) {
  const [isEditing, setIsEditing] = useState(false);

  const close = () => setIsEditing(false);
  const mut = useEditSectionMutation({
    onSuccess: close,
  });

  return (
    <Dialog open={isEditing} onOpenChange={setIsEditing}>
      <DialogTrigger asChild>
        <Button variant={"ghost"} size={"icon-sm"} className={className}>
          <Pencil className="size-3.5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-max p-5">
        <DialogHeader>
          <DialogTitle>Edit Section</DialogTitle>
          <DialogDescription>
            Add groups to section. Drag and drop to reorder.
          </DialogDescription>
        </DialogHeader>
        <CreateOrEditSectionForm
          close={close}
          defaults={section}
          onSubmit={(p) =>
            mut.mutate({
              ...p,
              sectionId: section.section._id,
            })
          }
          disabled={mut.isLoading}
        />
      </DialogContent>
    </Dialog>
  );
}

export function SectionsArea() {
  const hasEditAccess = useHasEditPermission();
  const groupsWithSections = useGroupsWithSections();
  const sections = groupsWithSections?.sectionsWithGroups;

  const [copied, setCopied] = useState(sections);
  useEffect(() => {
    setCopied(sections);
  }, [sections]);

  const refresh = useInvalidateSectionsQuery();
  const refreshGroups = useInvalidateGroupsQuery();
  const b = useBaseUrlExperimental();

  const mut = useMutation({
    mutationFn: async (sectionIds: string[]) => {
      if (!hasEditAccess) return;
      if (!groupsWithSections) throw new Error("Please try again.");

      const theseGoOnTop = groupsWithSections.remainingGroups;
      const theseGoOnTheBottom = groupsWithSections.sectionsWithGroups
        .slice()
        .sort((a, b) => {
          return (
            sectionIds.indexOf(a.section._id) -
            sectionIds.indexOf(b.section._id)
          );
        })
        .flatMap((x) =>
          x.groups.slice().sort((a, b) => a.display_id - b.display_id)
        );

      const newDisplayIdsOrdering = [...theseGoOnTop, ...theseGoOnTheBottom];

      await reorderAllGroupsForAUnit(
        b,
        newDisplayIdsOrdering.map((x) => x._id)
      );
    },
    onSuccess: () => {
      refresh();
      refreshGroups();
    },
    onError: (e) => {
      refresh();
      refreshGroups();
      addUnknownErrorToast(e);
    },
  });

  const droppableContainerId = useId();
  const { setNodeRef } = useDroppable({
    id: droppableContainerId,
  });
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  if (!copied) return null;
  if (!copied.length) {
    return null;
  }

  const currentOrder = copied.map((x) => x.section._id);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={(e) => {
        const theThingDragging = e.active.id.toString();

        const over = e.over?.id.toString();

        if (over === undefined) {
          return; // no-op
        }

        if (over === droppableContainerId) return;

        if (theThingDragging === over) return; // no-op because same element

        const idxA = currentOrder.indexOf(theThingDragging);
        const idxB = currentOrder.indexOf(over);

        if (idxA === -1 || idxB === -1) {
          throw new Error("unhandled case onDragEnd 2");
        }

        const newOrder = currentOrder.filter((x) => x !== theThingDragging);
        newOrder.splice(idxB, 0, theThingDragging);

        // mutate
        mut.mutate(newOrder);

        /**
         * set copied state immediately so user sees
         * instant re-ordering instead of waiting
         * for mutation to complete
         */
        setCopied(
          copied.slice().sort((a, b) => {
            const idxA = newOrder.indexOf(a.section._id);
            const idxB = newOrder.indexOf(b.section._id);

            return idxA - idxB;
          })
        );
      }}
    >
      <SortableContext items={currentOrder}>
        <div ref={setNodeRef}>
          {copied.map((x, _, arr) => {
            return (
              <SectionCard
                key={x.section._id}
                section={x}
                disabled={mut.isLoading || arr.length === 1 || !hasEditAccess}
                showDragHandle={hasEditAccess && arr.length > 1}
              />
            );
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
}

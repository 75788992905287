import { createStore, Provider, useAtomValue } from "jotai";
import { useMemo } from "use-memo-one";
import {
  Atoms,
  ChartVariant,
} from "../../shared-ui/time-series-2/svv-store/use-svv-store";
import {
  TrendChartQueryType,
  useOperatingLimitsQuery,
  useTrendChartQuery,
} from "../../hooks/tanstack-query";
import { chartFormat } from "../../shared-ui/time-series-2/fetcher-utils";
import { TimeseriesChart } from "../../shared-ui/time-series-2/timeseries-chart";
import { useTimezone } from "../../zustand/config/useConfigStore";
import { useToggleFullscreen } from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { assertMinLen1, iife } from "../../shared-ui/lib/utils";
import Comment from "../../types/api/Comment";
import { DateTime } from "luxon";
import { isVariableVariant } from "../../shared-ui/time-series-2/draw/draw";
import { useSlopesForDrawFunctionIfEnabled } from "../time-series/secondary-variable-view/dra-and-aria-secondary-variable.view";
import { minutesToMilliseconds } from "date-fns";
import { useOperatingModesQuery } from "../om/manager/queries";
import { useEffect } from "react";

function LightweightDTCForCommentCard({ comment }: { comment: Comment }) {
  const zone = useTimezone();
  const modesQuery = useOperatingModesQuery({ staleTime: Infinity });
  const modes = modesQuery.data;

  const jot = useMemo(() => {
    const start = DateTime.fromISO(comment.start_date, { zone: "utc" })
      .setZone(zone, { keepLocalTime: true })
      .toMillis();
    const end = DateTime.fromISO(comment.end_date, { zone: "utc" })
      .setZone(zone, { keepLocalTime: true })
      .toMillis();

    const jot = createStore();

    jot.set(Atoms.setInitialStateAtom, {
      variant: iife(() => {
        const context = comment.context;

        if (!context) return undefined;

        switch (context.type) {
          case "FaultTreeNode":
            return ChartVariant.FaultTrees;
          case "OperatingLimit":
            return ChartVariant.OperatingLimits;
          default:
            return undefined;
        }
      }),
      decideAnomBooleanForNewlyAddedVariableTrendLines: undefined,
      expanded: false,
      batchVariablesOrExpressions: assertMinLen1(
        comment.variables.map((x) => ({
          type: "variable" as const,
          bv: x._id.padStart(48, "0"),
        }))
      ),
    });
    jot.set(Atoms.setDomainAtom, [start, end]);

    jot.set(Atoms.setSlopingTrendsAtom, {
      on: comment.context?.chartContext.showSlopingTrends ?? false,
    });

    // must be done after sloping trends, not sure why right now..
    jot.set(
      Atoms.setAllAnomalyColorationAtom,
      comment.context?.chartContext.showAnomalyColoration ?? true
    );

    return jot;
  }, [comment, zone]);

  useEffect(() => {
    if (!modes) return;

    const handle = () => {
      const shouldSetItToABool =
        jot.get(Atoms.modeTransparencyDefaultValueAtom) === undefined;

      console.log({ shouldSetItToABool });

      if (!shouldSetItToABool) return;

      const primaryTrendLine = jot.get(Atoms.primaryBatchVariableAtom);
      if (!isVariableVariant(primaryTrendLine)) return;
      const variableId = primaryTrendLine.bv.slice(24);
      const thisVariableHasAtLeastOneMode = modes.some((m) =>
        m.bindingVariableIdsSet.includes(variableId)
      );
      jot.set(
        Atoms.modeTransparencyDefaultValueAtom,
        thisVariableHasAtLeastOneMode
      );
    };

    handle();

    const cleanup = jot.sub(Atoms.modeTransparencyDefaultValueAtom, handle);
    return cleanup;
  }, [jot, modes]);

  return (
    <Provider store={jot}>
      <Internal comment={comment} />
    </Provider>
  );
}

function Internal({ comment }: { comment: Comment }) {
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  if (!isVariableVariant(primaryTrendLine))
    throw new Error("expressions unsupported right now");

  const isOperatingLimitsChart = comment.context?.type === "OperatingLimit";

  const vid = primaryTrendLine.bv.slice(24);

  const operatingLimits = useOperatingLimitsQuery(vid, {
    enabled: isOperatingLimitsChart,
    staleTime: minutesToMilliseconds(5),
  });

  const tz = useTimezone();

  const slopesForDraw = useSlopesForDrawFunctionIfEnabled(true);

  const toggleFs = useToggleFullscreen();

  const limits = useMemo(() => {
    if (!isOperatingLimitsChart) return undefined;

    const data = operatingLimits.data;
    if (!data) return undefined;

    const out = data.map((x): Pick<typeof x, "data" | "level"> => {
      return {
        level: x.level,
        data: x.data.map((y): typeof y => ({
          end:
            y.end === null
              ? null
              : DateTime.fromMillis(y.end, { zone: "utc" })
                  .setZone(tz, { keepLocalTime: true })
                  .toMillis(),
          start: DateTime.fromMillis(y.start, { zone: "utc" })
            .setZone(tz, { keepLocalTime: true })
            .toMillis(),
          value: y.value,
        })),
      };
    });

    const m: Record<string, typeof out> = {
      ["0".repeat(24) + vid]: out,
    };

    return m;
  }, [operatingLimits.data, tz, isOperatingLimitsChart, vid]);

  const [start, end] = useAtomValue(Atoms.getDomainAtom);

  const timeseriesQuery = useTrendChartQuery(
    {
      variables: comment.variables.map((x) => x._id),
      start,
      end,
      idk: { type: TrendChartQueryType.Regular },
    },
    {
      staleTime: minutesToMilliseconds(3),
    }
  );

  const timeseriesDataAsChartFormat = useMemo(
    () =>
      (timeseriesQuery.data &&
        chartFormat(timeseriesQuery.data, "0".repeat(24))) ??
      [],
    [timeseriesQuery.data]
  );

  return (
    <TimeseriesChart
      onLineClick={
        toggleFs &&
        (() =>
          ({ jotaiStore: s }) => {
            toggleFs(s);
          })
      }
      hideCommentPills
      inViewOverride
      noDaBars
      slopes={slopesForDraw}
      stillUseColorForAnomalyColorationOff={false}
      theme="light"
      limitsMap={limits}
      timezone={useTimezone()}
      height={500}
      width={2000}
      stages={undefined}
      lineWidthScale={0.5}
      app="DRA"
      data={timeseriesDataAsChartFormat}
      padding={{
        right: 30,
        bottom: 20,
      }}
    />
  );
}

export { LightweightDTCForCommentCard };

import * as Sidebar from "../../../shared-ui/time-series-2/secondary-variable-view/left-sidebar";
import { useVariablesArrayQuery } from "../../../hooks/tanstack-query";
import { cn } from "../../../shared-ui/frontend/cn";
import {
  Atoms,
  SelectedVariable,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useDebouncedHoverDataForBatchVariable } from "../../../shared-ui/time-series-2/svv-store/use-debounced-hoverdata-for-batch-variable";
import { usePlantTimeFormatter } from "../../../hooks/use-plant-time-formatter";
import { ControlButtons } from "./control-buttons";
import * as d3 from "d3";
import {
  atom,
  PrimitiveAtom,
  useAtom,
  useAtomValue,
  useSetAtom,
  useStore,
} from "jotai";
import { formatDuration4 } from "../../../shared-ui/lib/date-utils";
import { BsClock as Clock } from "react-icons/bs";
import {
  getBvOrId,
  isVariableVariant,
} from "../../../shared-ui/time-series-2/draw/draw";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { iife } from "../../../lib/utils";
import { CSSProperties, PropsWithChildren } from "react";
import { CSS } from "@dnd-kit/utilities";
import { GripVertical } from "lucide-react";
import { useMemo } from "use-memo-one";
import { focusAtom } from "jotai-optics";

const numCardsAtom = atom((get) => get(Atoms.selectedVariablesAtom).length);

const numExcludedVariablesAtom = atom((get) => {
  const { expression, variable } = get(Atoms.excludedBatchVariablesSetAtomAtom);

  return get(expression).size + get(variable).size;
});

function SelectedCount() {
  const total = useAtomValue(numCardsAtom);

  const numExcluded = useAtomValue(numExcludedVariablesAtom);

  if (numExcluded === 0) return;

  return (
    <span className="text-xs text-xslate-10 font-bold">
      {total - numExcluded} / {total} selected
    </span>
  );
}

function VariableCardsSidebar() {
  const [first, ...rest] = useAtomValue(Atoms.selectedVariableAtomsAtom);
  const openSidebar = useAtomValue(
    useAtomValue(Atoms.cardsSidebarOpenAtomAtom)
  );

  if (!openSidebar) return null;

  return (
    <Sidebar.Container>
      <div className="flex w-full gap-4 justify-center items-center">
        <Sidebar.IncludeAllButton className="border-t-0 rounded-t-none" />
        <SelectedCount />
      </div>
      <div
        className={cn("slide-up-fade-in flex shrink-0 flex-col gap-2 p-3 pl-4")}
      >
        {first && <VariableCard atom={first} renderAsDraggable={false} />}

        {/* the first one is fixed and not draggable  */}
        <SortableVariableCards>
          {rest.map((atom) => {
            return (
              <VariableCard
                key={atom.toString()}
                atom={atom}
                renderAsDraggable
              />
            );
          })}
        </SortableVariableCards>
      </div>
    </Sidebar.Container>
  );
}

/**
 * All variable cards beyond the first one are draggable
 */
function SortableVariableCards({ children }: PropsWithChildren) {
  const [[_, ...rest], setSelectedVariables] = useAtom(
    Atoms.selectedVariablesAtom
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={(e) => {
        const theThingDragging = e.active.id.toString();
        const over = e.over?.id.toString();
        if (over === undefined) {
          return; // no-op
        }

        setSelectedVariables((curr) => {
          const [first, ...rest] = curr;

          if (!first) throw new Error("BUG");

          const idxA = rest.findIndex((x) => getBvOrId(x) === theThingDragging);

          const idxB = rest.findIndex((x) => getBvOrId(x) === over);

          if (idxA === -1 || idxB === -1) {
            throw new Error("unhandled case onDragEnd 2");
          }

          const newOrder = rest.filter(
            (x) => getBvOrId(x) !== theThingDragging
          );
          newOrder.splice(idxB, 0, curr[idxA + 1]!);

          return [first, ...newOrder];
        });
      }}
    >
      <SortableContext items={rest.map(getBvOrId)}>{children}</SortableContext>
    </DndContext>
  );
}

function VariableCard({
  atom: svAtom,
  driftTime,
  renderAsDraggable,
}: {
  atom: PrimitiveAtom<SelectedVariable>;
  driftTime?: number;
  renderAsDraggable: boolean;
}) {
  const batchVariable = useAtomValue(svAtom);

  const bvIfApplicable = useAtomValue(
    useMemo(() => {
      return focusAtom(svAtom, (o) => o.guard(isVariableVariant).prop("bv"));
    }, [svAtom])
  );

  const isVariableTrendLine = isVariableVariant(batchVariable);

  const variables = useVariablesArrayQuery().data;

  const variableObject = bvIfApplicable
    ? variables?.find((x) => x._id === bvIfApplicable.slice(24))
    : undefined;

  const { color } = batchVariable;
  const [isHovered, hoverState] = useDebouncedHoverDataForBatchVariable(svAtom);

  const plantFormat = usePlantTimeFormatter("dd-LLL-yyyy hh:mm a");

  const d3Color = d3.color(color);
  const darker = d3Color?.darker(1).toString();
  const backgroundColor = d3Color?.copy({ opacity: 0.1 })?.toString();

  const checkedSets = useAtomValue(Atoms.checkedBatchVariablesAtom);

  const checked = isVariableTrendLine
    ? checkedSets.variable.has(batchVariable.bv)
    : checkedSets.expression.has(batchVariable.id);

  const jot = useStore();

  const toggleCheckedExpressionTrendLine = useSetAtom(
    Atoms.toggleExcludedExpressionTrendLineAtom
  );
  const toggleCheckedVariableTrendLine = useSetAtom(
    Atoms.toggleExcludedVariableTrendLineAtom
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: getBvOrId(batchVariable),
    disabled: iife(() => {
      if (!renderAsDraggable) return true;
      return false;
    }),
  });

  const dragStyles: CSSProperties = renderAsDraggable
    ? {
        // see https://github.com/clauderic/dnd-kit/issues/44
        transform: CSS.Translate.toString(transform),
        transition,
      }
    : {};

  const mouseInteractionStyles: CSSProperties =
    checked && isHovered && !isDragging
      ? {
          borderColor: darker,
          backgroundColor,
        }
      : {};

  const isDraggingStyles: CSSProperties = isDragging
    ? {
        /**
         * This how tailwind gives the ring color. But we have dynamically
         * generated ones so it must be done this way.
         */
        // @ts-ignore
        "--tw-ring-opacity": "1",
        "--tw-ring-color": darker,
      }
    : {};

  return (
    <div
      ref={renderAsDraggable ? setNodeRef : undefined}
      {...(renderAsDraggable ? listeners : undefined)}
      {...(renderAsDraggable ? attributes : undefined)}
      className={cn(
        "relative rounded-md border border-xslate-7 group flex flex-col gap-1 border-b pb-0 text-xs cursor-pointer",
        isDragging ? "ring-2 ring-offset-2 opacity-50" : "transition-all"
      )}
      onMouseEnter={() =>
        jot.get(Atoms.redrawCanvasFnAtom)?.({
          ...batchVariable,
          stage: "Remainder",
        })
      }
      onMouseLeave={() => jot.get(Atoms.redrawCanvasFnAtom)?.(undefined)}
      style={{
        ...dragStyles,
        ...mouseInteractionStyles,
        ...isDraggingStyles,
      }}
      onClick={() => {
        isVariableTrendLine
          ? toggleCheckedVariableTrendLine(batchVariable.bv)
          : toggleCheckedExpressionTrendLine(batchVariable.id);
      }}
    >
      {renderAsDraggable && (
        <GripVertical className="size-4 text-xslate-8 absolute left-0 top-1/2 -translate-x-[100%] -translate-y-1/2 cursor-row-resize" />
      )}
      <div className="flex items-start gap-1 pl-1 pt-1">
        <Sidebar.ColoredCheckbox batchVariable={batchVariable} />
        <div className="inline-flex flex-col">
          <span
            className={cn(
              "leading-3 flex items-center justify-between group-hover:font-medium break-all",
              isHovered && "font-medium"
            )}
          >
            {isVariableTrendLine
              ? variableObject?.trimmedName
              : batchVariable.expression}
            {driftTime && (
              <span className="text-xs text-xslate-11 flex flex-row ml-1">
                <Clock className="w-3 h-3 relative top-0.5 mr-0.5" />
                {formatDuration4(driftTime * 60 * 1000)}
              </span>
            )}
          </span>
          {isVariableTrendLine && variableObject && (
            <span className="text-xslate-9 text-xs break-all">
              {variableObject.description}
            </span>
          )}
        </div>
        <Sidebar.PinButton
          className="ml-auto hover:bg-inherit hover:border-xslate-11 border border-transparent shrink-0 -translate-y-1"
          batchVariable={batchVariable}
        />
      </div>

      <p className="pl-1">
        {hoverState ? (
          <>
            <span className="font-bold">{hoverState.v.toFixed(2)}</span> |{" "}
            {plantFormat(hoverState.t)}
          </>
        ) : (
          <span className="text-transparent select-none">invisible</span>
        )}
      </p>

      <div className="p-0.5 flex justify-between">
        <ControlButtons identifier={svAtom} />
      </div>
    </div>
  );
}

export { VariableCardsSidebar };

export const SCORE_CATEGORIES = [95, 90, 70, 40, 0] as const;

export function getScoreCategory(score: number) {
  return score >= 95
    ? ({ color: "darkGreen", label: "Excellent", start: 95 } as const)
    : score >= 90
      ? ({ color: "green", label: "Very Good", start: 90 } as const)
      : score >= 70
        ? ({ color: "amber", label: "Good", start: 70 } as const)
        : score >= 40
          ? ({ color: "orange", label: "Fair", start: 40 } as const)
          : ({ color: "danger", label: "Poor", start: 0 } as const);
}

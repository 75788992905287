import { useEffect, useState } from "react";
import { atom, Atom, useAtomValue, useStore } from "jotai";
import { Atoms, SelectedVariable } from "./use-svv-store";
import { getBvOrId } from "../draw/draw";
import { focusAtom } from "jotai-optics";
import { useMemo } from "use-memo-one";

function useDebouncedHoverDataForTrendLine(trendLine: Atom<SelectedVariable>) {
  const bvOrId = useAtomValue(
    useMemo(() => {
      return atom((get) => getBvOrId(get(trendLine)));
    }, [trendLine])
  );

  const jot = useStore();

  /**
   * Normally I would say this is a terrible pattern (data syncing),
   * but this data changes very fast and we have a card for each
   * batch variable, so this ends up being a lot of render cycles.
   *
   * It actually causes the chart to lag during interaction if the
   * sidebar is open (and so these cards are showing), because React
   * steals time between each draw cycle. So, we defer the component
   * updates by debouncing.
   *
   * In short, store copies of the state, and sync it up every x seconds,
   * at which point the component will rerender, allowing the chart redraws
   * to take priority.
   */
  const [hoverData, setHoverData] = useState<
    { v: number; t: number } | undefined
  >();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let timer = undefined as undefined | ReturnType<typeof setTimeout>;

    // immediately set
    setHoverData(jot.get(Atoms.hoverIntersectionMapAtom)?.[bvOrId]);

    const intersectedValueAtom = focusAtom(
      Atoms.hoverIntersectionMapAtom,
      // if the map exists, focus on this part of it
      (o) => o.optional().prop(bvOrId)
    );

    // then listen for the changes
    const cleanup = jot.sub(intersectedValueAtom, () => {
      const hoveredStateValue = jot.get(intersectedValueAtom);
      clearTimeout(timer);
      timer = setTimeout(() => {
        setHoverData(hoveredStateValue);
      }, 50);
    });

    return () => {
      cleanup();
      clearTimeout(timer);
    };
  }, [bvOrId, jot]);

  useEffect(() => {
    let timer = undefined as undefined | ReturnType<typeof setTimeout>;

    const checkIsHovered = () => {
      const newHoveredLine = jot.get(Atoms.hoveredLineAtom);
      const isHovered = newHoveredLine
        ? bvOrId === getBvOrId(newHoveredLine)
        : false;

      return isHovered;
    };

    // immediately set
    setIsHovered(checkIsHovered());

    const cleanup = jot.sub(Atoms.hoveredLineAtom, () => {
      const isHovered = checkIsHovered();

      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsHovered(isHovered);
      }, 50);
    });

    return () => {
      cleanup();
      clearTimeout(timer);
    };
  }, [jot, bvOrId]);

  return [isHovered, hoverData] as const;
}

export { useDebouncedHoverDataForTrendLine as useDebouncedHoverDataForBatchVariable };

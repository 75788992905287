import { useAtomValue, useSetAtom } from "jotai";
import { GenericCommentForm, OnCommentCreate } from "./generic-comment-form";
import {
  Atoms,
  ChartVariant,
  isVariableTrendLine,
} from "../../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { useMutation } from "@tanstack/react-query";
import {
  createComment,
  type CreateCommentPayload,
} from "../../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../../zustand/useBaseUrl";
import { addUnknownErrorToast } from "../../../toast/use-toast-store";
import { iife } from "../../../../lib/utils";
import { DateTime } from "luxon";
import { useTimezone } from "../../../../zustand/config/useConfigStore";
import { Comments } from "../../../../hooks/tanstack-query";
import { useCallback } from "use-memo-one";
import { assertMinLen1 } from "../../../../shared-ui/lib/utils";
import Comment from "../../../../types/api/Comment";
import { useParams } from "react-router-dom";

function CreateCommentForm({
  noPortalMultiSelects,
}: {
  noPortalMultiSelects?: true;
}) {
  const params = useParams();

  const allBvs = useAtomValue(Atoms.allBvsAtom);

  const variant = useAtomValue(Atoms.chartVariantAtom);

  const brushState = useAtomValue(Atoms.brushStoreAtom);
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);
  const primaryIsVariableTrendLine = isVariableTrendLine(primaryTrendLine);
  const close = useSetAtom(Atoms.resetBrushAtom);
  const b = useBaseUrlExperimental();
  const showSlopingTrends = useAtomValue(Atoms.slopingTrendsAtom);

  const invalidateCommentsList = Comments.list.useInvalidate();

  const m = useMutation({
    mutationFn: async (p: CreateCommentPayload) => {
      return await createComment(b, p);
    },
    onError: (e) => {
      addUnknownErrorToast(e);
    },
    onSuccess: () => {
      invalidateCommentsList();
      close();
    },
  });

  const zone = useTimezone();

  const onIssueToggle = useCallback(
    (isIssue: boolean) => {
      if (!brushState) return;
      brushState.brushStore.getState().setRightFixed(isIssue);
    },
    [brushState]
  );

  if (!brushState || brushState.for !== "comment-create") return null;

  const onSubmit: OnCommentCreate = (d) => {
    if (!primaryIsVariableTrendLine)
      throw new Error("Should not be able to submit a comment");

    const unorderedRange = brushState.brushStore.getState().unorderedRange;

    const rightBrush = unorderedRange[1];
    const start =
      rightBrush === undefined
        ? unorderedRange[0]
        : Math.min(unorderedRange[0], rightBrush);

    const end =
      rightBrush === undefined
        ? Date.now()
        : Math.max(unorderedRange[0], rightBrush);

    const fakeify = (t: number) => {
      // If t points to 5PM zone time, this function returns 5PM UTC time
      // So it does add/subtract milliseconds to make this happen.
      return DateTime.fromMillis(t, { zone }) // parse the time in the user's timezone
        .setZone("utc", { keepLocalTime: true }) // change to utc, but keep the same local time
        .toMillis();
    };

    /**
     * We are still using fake dates in comments, and I don't want
     * to just start using real dates without migrating the db first..
     *
     * So just make it fake for now
     * */
    const startFake = fakeify(start);
    const endFake = fakeify(end);

    const baseContext: Pick<
      NonNullable<Comment["context"]>,
      "chartContext" | "shortId" | "variableId"
    > = {
      chartContext: {
        showSlopingTrends,
        showAnomalyColoration: primaryTrendLine.anom,
        showOperatingLimitLines: variant === ChartVariant.OperatingLimits,
      },
      variableId: primaryTrendLine.bv.slice(24),
      shortId: 0,
    };

    m.mutate({
      private: d.type === "Private",
      variables: d.taggedVariables,
      text: d.comment,
      labels: d.taggedLabels,
      tagged_teams: d.taggedTeams,
      tagged_users: d.taggedUsers,
      type: iife(() => {
        if (d.type === "Issue") return "Issue";
        return "Comment";
      }),
      context: iife((): NonNullable<Comment["context"]> => {
        /**
         * Get the correct context depending on how this chart was
         * rendered (what page?)
         */
        switch (variant) {
          case ChartVariant.OperatingLimits:
            const groupId = params["groupId"];
            if (!groupId || groupId.length !== 24) {
              const e = new Error("Invalid groupId during comment creation");
              addUnknownErrorToast(e);
              throw e;
            }

            return {
              type: "OperatingLimit",
              groupId,
              ...baseContext,
            };
          case ChartVariant.FaultTrees:
            const treeId = params["treeId"];
            const nodeId = new URLSearchParams(window.location.search)
              .get("selectednode")
              ?.trim();

            if (!treeId || treeId.length !== 24) {
              // not a mongo id
              const e =
                "Unable to create comment: Fault Tree id is missing from the URL";
              addUnknownErrorToast(e);
              throw e;
            }

            if (!nodeId || nodeId.length !== 24) {
              // not a mongo id
              const e =
                "Unable to create comment: selected node id is missing from the URL";
              addUnknownErrorToast(e);
              throw e;
            }

            return {
              type: "FaultTreeNode",
              treeId,
              nodeId,
              ...baseContext,
            };
          default:
            const _:
              | ChartVariant.SlopingTrends
              | ChartVariant.Aria
              | ChartVariant.InstantCalculator
              | undefined = variant;
            return {
              type: undefined, // will link back to Profile Book
              ...baseContext,
            };
        }
      }),
      link: window.location.href,
      start_date: startFake,
      end_date: endFake,
    });
  };

  if (!primaryIsVariableTrendLine) return;

  return (
    <GenericCommentForm
      noPortalMultiSelects={noPortalMultiSelects}
      showRangeLabel
      showIssueAndMyNote
      className="border-t border-xslate-6 mt-2"
      isLoading={false}
      onSubmit={onSubmit}
      close={close}
      defaults={{
        taggedLabels: [],
        taggedTeams: [],
        taggedUsers: [],
        taggedVariables: assertMinLen1(allBvs.map((x) => x.slice(24))), // if primaryIsVariableTrendLine is true, this must be true too
        comment: "",
        issueResolution: true,
      }}
      onIssueToggle={onIssueToggle}
    />
  );
}

export { CreateCommentForm };

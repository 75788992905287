import {
  type ComponentProps,
  type Key,
  useEffect,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";

import { v4 } from "uuid";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { Input } from "../input";
import { cn } from "../cn";
import { Button } from "../button";
import { ScrollArea } from "../scroll-area";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";

type AM_PM = "AM" | "PM";

function getTimeAtoms(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amPm: AM_PM = hours < 12 ? "AM" : "PM";

  const minute = minutes;
  return { amPm, hour: hours, minute };
}

function TimePicker({
  onOpenChange,
  open,
  withoutPortal,
  side = "top",
  value,
  onSubmit,
}: Pick<ComponentProps<typeof Popover>, "open" | "onOpenChange"> &
  Pick<ComponentProps<typeof PopoverContent>, "withoutPortal" | "side"> & {
    value: Date;
    onSubmit: (date: Date) => void;
  }) {
  const [date, setDate] = useState(value);
  const atoms = getTimeAtoms(date);

  const ms = value.valueOf();

  useEffect(() => {
    setDate(new Date(ms));
  }, [open, ms]);

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Input
          readOnly
          className="max-w-min hover:border-xindigo-8"
          value={format(date, "h : mm a")}
        />
      </PopoverTrigger>
      <PopoverContent
        withoutPortal={withoutPortal}
        side={side}
        className="border-xslate-7 p-0"
      >
        <div className="flex border-b border-xslate-4">
          <List
            active={atoms.hour % 12}
            options={Array.from({ length: 12 }).map((_, i) => {
              return {
                label: i ? i.toString() : "12", // show 0 as 12
                value: i,
              };
            })}
            onSelect={(x) => {
              const modified = new Date(date);
              modified.setHours(atoms.amPm === "AM" ? x : x + 12);
              setDate(modified);
            }}
          />
          <List
            active={atoms.minute}
            options={Array.from({ length: 60 }).map((_, i) => {
              const label = i.toString().padStart(2, "0");
              return {
                label: label,
                value: i,
              };
            })}
            onSelect={(newMinutes) => {
              const modified = new Date(date);
              modified.setMinutes(newMinutes);
              setDate(modified);
            }}
          />
          <List
            active={atoms.amPm}
            onSelect={(newAmPm) => {
              if (newAmPm === atoms.amPm) return;

              // modify the hours
              const modified = new Date(date);
              modified.setHours(
                newAmPm === "AM" ? atoms.hour - 12 : atoms.hour + 12
              );
              setDate(modified);
            }}
            options={
              [
                {
                  label: "AM",
                  value: "AM",
                },
                {
                  label: "PM",
                  value: "PM",
                },
              ] as const
            }
          />
        </div>
        <div className="flex p-2">
          <Button
            variant={"default"}
            className="ml-auto"
            size={"xxs"}
            onClick={() => {
              const zeroed = new Date(date);
              zeroed.setSeconds(0);
              zeroed.setMilliseconds(0);
              onSubmit(zeroed);
              onOpenChange?.(false);
            }}
          >
            OK
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}

function List<T extends Key>({
  options,
  onSelect,
  active,
}: {
  options:
    | { label: string; value: T }[]
    | readonly { label: string; value: T }[];
  onSelect?: (value: T) => void;
  active: T;
}) {
  const divRef = useRef<HTMLDivElement>(null);

  const [id] = useState(v4);

  function scrollToSelection(selectedTop: number) {
    const container = divRef.current;

    if (!container) return;

    const containerTop = container.getBoundingClientRect().top;

    const offset = selectedTop - containerTop;

    const withTopPadding = offset - 5.2;

    container.scrollTo({
      top: container.scrollTop + withTopPadding,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    const selectedTop = document
      .getElementById(id)
      ?.getBoundingClientRect().top;

    if (selectedTop === undefined) return;

    scrollToSelection(selectedTop);
  }, [id]); // only on initial render

  return (
    <ScrollArea
      // provide the viewport ourselves so we can ref it
      withoutViewport
      className="inline-flex h-[200px] flex-col gap-1 overflow-scroll border-xslate-4 px-1.5 [&:not(:last-child)]:border-r"
    >
      <ScrollAreaPrimitive.Viewport
        className="h-full w-full rounded-[inherit]"
        ref={divRef} // ref the viewport to manually scroll
      >
        {options.map(({ label, value }) => {
          const isActive = value === active;
          return (
            <div
              id={isActive ? id : undefined} // only needed for first render tbh
              onClick={(e) => {
                onSelect?.(value);
                const myTop = e.currentTarget.getBoundingClientRect().top;
                scrollToSelection(myTop);
              }}
              className={cn(
                "cursor-pointer rounded-md px-3.5 py-0.5 text-sm transition-all first:mt-2 last:mb-2 active:scale-95 [&:not(:first-child)]:mt-[1.5px] [&:not(:last-child)]:mb-[1.5px]",
                isActive
                  ? "bg-xindigo-4"
                  : "hover:bg-xslate-3 hover:text-xindigo-11"
              )}
              key={value.toString()}
            >
              <span>{label}</span>
            </div>
          );
        })}
      </ScrollAreaPrimitive.Viewport>
    </ScrollArea>
  );
}

export { TimePicker };

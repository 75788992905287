import React, { useState, useEffect, useRef } from "react";
import MainWrapper from "../../common/MainWrapper";
import moment from "moment-timezone";
import useOnSpacebar from "../useOnSpacebar";
import { useNavigate, useParams } from "react-router";
import PageError from "../../common/PageError";
import { useSearchParams } from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";
import MainLayout from "../../layouts/MainLayout";
import Button from "../../common/Button/Button";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import { FaArrowLeft } from "react-icons/fa";
import {
  getFaultTree,
  getFaultTreeNodes,
  getFaultTrees,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  FaultTreeDetailsStoreProvider,
  useGetUseFaultTreeDetailsStore,
} from "./ft-details-store";
import {
  useGetOnTreeNodeClick,
  useGetTreeNodeData,
} from "./use-get-tree-node-data";
import { useDateState } from "../../../zustand/useDateState";
import { LinkWithQuery } from "../../nav/LinkWithQuery2";
import { DrawerFtNodeDetails } from "../node-details/drawer-ft-node-details";
import { FaultTreeChart2 } from "../../../shared-ui/d3ft/FaultTreeChart2";
import {
  VariabilityDrawer,
  VariabilityDrawerStoreProvider,
} from "../../variability-view/variability-drawer";
import { Badge } from "../../../shared-ui/frontend/badge";
import { useActiveNodeCounts } from "../overview/FaultTreeOverview2";
import { useFaultTreeStatusQuery } from "../../../hooks/tanstack-query";
import { NODE_STATUSES } from "../overview/FaultTreeOverviewCard2";

function FaultTreeDetailsPage() {
  useDocumentTitle("Fault Trees > DRA");
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();

  const baseUrl = useBaseUrlExperimental();
  const tree = useFaultTreeDetailsStore((s) => s.tree);
  const publishedTrees = useFaultTreeDetailsStore((s) => s.publishedTrees);
  const lastProcessed = useFaultTreeDetailsStore((s) => s.lastProcessed);

  const TVButton = useRef(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // const [isExpandedView, setIsExpandedView] = useState(false); // uhm was this ever used ?
  const selectedDateEnd = useDateState().axisRangeTo.dateString;

  const Params = useParams();
  const [pageError, setPageError] = useState(false);
  const [isTreeLoading, setIsTreeLoading] = useState(true);
  const [reset, setReset] = useState(0);
  const savedZoomState = useRef<unknown>();
  const [showInactiveNodes, setShowInactiveNodes] = useState(true);
  useEffect(() => {
    savedZoomState.current = undefined;
  }, [reset, showInactiveNodes]);

  const getTreeNodeData = useGetTreeNodeData();

  const nodeId = searchParams.get("selectednode");
  const treeIdInUrl = Params.treeId;

  useEffect(() => {
    if (pageError) return;

    if (!treeIdInUrl) {
      setPageError(true); // not a valid FT
      return;
    }

    const handleSelectedTreeId = async (
      treeId: string,
      selectedNodeId: string | undefined
    ) => {
      const itsNodesPromise = getFaultTreeNodes(baseUrl, treeId);

      const wholeTreePromise = getFaultTree(baseUrl, treeId);

      let data;

      if (selectedNodeId) {
        // run in parallel if you can
        data = await Promise.all([
          wholeTreePromise,
          getTreeNodeData(selectedNodeId),
        ]);
      } else {
        // run sequentially if needed
        data = await wholeTreePromise.then((wholeTree) =>
          getTreeNodeData(wholeTree.rootNodeId).then(
            (node) => [wholeTree, node] as const
          )
        );
      }

      const [wholeTree, node] = data;

      useFaultTreeDetailsStore.setState({
        tree: { nodes: await itsNodesPromise, mainTree: wholeTree },
        selectedNode: selectedNodeId ? node : undefined,
        lastProcessed: node.resolutionDate ?? undefined,
      });
    };

    const run = async () => {
      const setAllFtsPromise = getFaultTrees(baseUrl).then((trees) => {
        useFaultTreeDetailsStore
          .getState()
          .setPublishedTrees(trees.filter((t) => t.published));
      });

      /* Pass in the correct node ID, or select the root if it doesn't exist. */
      const initTreePromise = handleSelectedTreeId(
        treeIdInUrl,
        nodeId ?? undefined
      );

      await Promise.all([setAllFtsPromise, initTreePromise]);
      setIsTreeLoading(false);
    };

    run().catch(() => setPageError(true));

    if (document.fullscreenElement) {
      const timer = setInterval(() => {
        handleSelectedTreeId(treeIdInUrl, undefined);
      }, 1000 * 60);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [
    treeIdInUrl,
    baseUrl,
    nodeId,
    pageError,
    useFaultTreeDetailsStore,
    document.fullscreenElement,
  ]);

  const handleOnNodeClick = useGetOnTreeNodeClick();

  const [showCloseButton, setShowCloseButton] = useState(false);

  function enterFullscreen(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setShowCloseButton(true);
    document.body.requestFullscreen();
    (e.target as unknown as HTMLButtonElement).classList.add("btn-active");
    document.body.style.overflow = "hidden";
    const nav = document.getElementById("main-navbar");
    if (nav) {
      nav.style.display = "none";
    }
    const secondNav = document.getElementById("secondary-navbar");
    if (secondNav) {
      secondNav.style.display = "none";
    }

    const mainWrapper = document.getElementById("main-wrapper");

    if (mainWrapper) {
      mainWrapper.style.height = "100vh";
    }
  }

  function exitFullscreen(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setShowCloseButton(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    (e.target as unknown as HTMLButtonElement).classList.remove("btn-active");
    document.body.style.overflow = "";
    // @ts-ignore
    document.getElementById("main-navbar").style.display = "";
    // @ts-ignore
    document.getElementById("secondary-navbar").style.display = "";
    // @ts-ignore
    document.getElementById("main-wrapper").style.height = "";
  }

  // user can exit fullscreen by pressing escape,
  // so make sure we catch that and do additional things we need
  useEffect(() => {
    // @ts-ignore
    function handleEsc(e) {
      if (!document.fullscreenElement) exitFullscreen(e);
    }
    document.addEventListener("fullscreenchange", handleEsc);
    return () => {
      document.removeEventListener("fullscreenchange", handleEsc);
    };
  }, []);
  // @ts-ignore
  function onTVMode(e) {
    if (!document.fullscreenElement) {
      enterFullscreen(e);
    } else {
      exitFullscreen(e);
    }
  }

  const lastProcessedStringMaybe =
    lastProcessed && moment(lastProcessed).format("MMMM Do YYYY, h:mm a");

  function onTreeSelect(treeId: string) {
    const copy = new URLSearchParams(searchParams.toString());
    copy.delete("selectednode");
    copy.delete("selectedCommentId");

    navigate(
      { pathname: "../" + treeId, search: "?" + copy.toString() },
      { relative: "path" }
    );
  }

  const dateStateNavChildren = (
    <div className="my-3 md:my-0 flex md:flex-row flex-col">
      {!isTreeLoading && publishedTrees ? (
        <select
          className="select select-sm mr-4 select-bordered"
          value={tree?.mainTree._id}
          onChange={(e) => {
            const treeId = e.target.value;
            treeId !== tree?.mainTree._id && onTreeSelect(treeId);
          }}
        >
          {publishedTrees.map((t) => {
            return (
              <option key={t._id} value={t._id}>
                {t.name}
              </option>
            );
          })}
        </select>
      ) : null}
    </div>
  );

  const { treeCounts, rootCounts } = useActiveNodeCounts([
    tree?.mainTree?.rootNodeId || "",
  ]);
  const nodeResult = useFaultTreeStatusQuery(
    tree?.mainTree._id || "",
    selectedDateEnd
  );
  const status =
    Object.values(NODE_STATUSES).find((o) => o.num === nodeResult.data?.status)
      ?.value || "processing";

  return (
    <MainLayout
      dateStateNavChildren={dateStateNavChildren}
      leftmostDateStateNavChildren={
        <LinkWithQuery
          to={"../.."}
          pick={{ d: true, mo: true, y: true, z: true, cd: true }}
          relative="path"
        >
          <Button icon={FaArrowLeft} className="btn-ghost" />
        </LinkWithQuery>
      }
      showDateNav
    >
      <DrawerFtNodeDetails />
      <LoadingOverlay visible={isTreeLoading} overlayBlur={2} />
      <div className="overflow-hidden">
        {pageError ? (
          <PageError
            message={"An error has occured. Please refresh the page."}
          />
        ) : (
          tree && (
            <>
              <MainWrapper>
                <div className="flex flex-col">
                  <div className="prose m-2 max-w-none flex-none">
                    <h2 className="inline-flex">{tree?.mainTree.name}</h2>
                    <Badge
                      variant="secondary"
                      className="ml-2 relative bottom-1"
                    >
                      {`${status?.toUpperCase()} ${status !== "processing" ? "top node" : ""} | ${tree?.mainTree.nodeCount} nodes | ${treeCounts.get(tree?.mainTree.name) || 0} active`}
                    </Badge>
                    {showCloseButton && (
                      <Button
                        icon="times"
                        className="btn-ghost z-50 normal-case absolute right-0 top-0"
                        onClick={(e) => onTVMode(e)}
                      ></Button>
                    )}
                  </div>
                  <div className="grow">
                    <FaultTreeChart2
                      key={
                        reset.toString() +
                        // @ts-ignore
                        savedZoomState.current?.transform.toString()
                      }
                      treeId={tree.mainTree._id}
                      selectedDate={selectedDateEnd}
                      zoomEnabled={true}
                      savedZoomState={savedZoomState}
                      handleTreeNodeChange={handleOnNodeClick}
                      filterInactiveNodes={!showInactiveNodes}
                      width={window.innerWidth}
                      // the navbar, title and footer are roughly 250px
                      height={window.innerHeight - 250}
                      className="h-[calc(100vh-250px)]"
                    />
                  </div>
                  <div className="flex-none">
                    <Button
                      ref={TVButton}
                      icon="tv"
                      iconClasses="mr-2"
                      className="btn-ghost normal-case"
                      onClick={(e) => onTVMode(e)}
                    >
                      TV Mode
                    </Button>
                    <Button
                      icon="undo"
                      iconClasses="mr-2"
                      className="btn-ghost normal-case"
                      onClick={() => setReset((curr) => curr + 1)}
                    >
                      Reset
                    </Button>
                    <Button
                      icon={!showInactiveNodes ? "eye" : "eye-slash"}
                      iconClasses="mr-2"
                      className="btn-ghost normal-case"
                      onClick={() => setShowInactiveNodes((curr) => !curr)}
                    >
                      {showInactiveNodes
                        ? "Hide Inactive Nodes"
                        : "Show Inactive Nodes"}
                    </Button>
                  </div>
                </div>
              </MainWrapper>
              {lastProcessedStringMaybe && (
                <div className="absolute right-2 bottom-4">
                  Last Updated: {lastProcessedStringMaybe}
                </div>
              )}
            </>
          )
        )}
      </div>
    </MainLayout>
  );
}

export default function WithProvider() {
  return (
    <FaultTreeDetailsStoreProvider>
      <VariabilityDrawerStoreProvider>
        <VariabilityDrawer />
        <FaultTreeDetailsPage />
      </VariabilityDrawerStoreProvider>
    </FaultTreeDetailsStoreProvider>
  );
}

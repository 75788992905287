import { cn } from "../../lib/utils";
import { Badge } from "../../shared-ui/frontend/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shared-ui/frontend/tooltip";
import { getScoreCategory } from "../clusters/utils";
import { PropsWithCn } from "../types/component.types";

export function ClusterScoreBadge({
  className,
  score,
}: PropsWithCn<{ score: number | null | undefined }>) {
  if (score === null || score === undefined) return null;
  const variant = getScoreCategory(score);
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div
          className={cn("whitespace-nowrap inline-flex flex-nowrap", className)}
        >
          <Badge
            variant={variant.color}
            className="select-none rounded-r-none border-r-0 ml-1 transition-none"
          >
            {variant.label}
          </Badge>
          <Badge
            className="select-none rounded-l-none transition-none"
            variant={variant.color}
          >
            {score}
          </Badge>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <p>Long-Term Relationship Strength (ranges from 0 to 100)</p>
      </TooltipContent>
    </Tooltip>
  );
}

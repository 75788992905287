import React, { useEffect, useId, useMemo, useState } from "react";
import Fragment from "../../common/Fragment";
import FaultTreeCard from "./FaultTreeManagerCard";
import { useNavigate } from "react-router";
import FaultTreeTemplate from "../../../shared-ui/d3ft/FaultTreeTemplate";
import { useModal } from "../../common/useModal";
import useAuthStore from "../../../zustand/auth/useAuthStore";
import _ from "lodash";
import usePermissionBasedDocumentTitleForSettingsPage from "../../settings/hooks/usePermissionBasedDocumentTitleForSettingsPage";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  TreeTemplateEnum,
  TreeTemplateNumbers,
} from "../../../shared-ui/d3ft/FaultTreeTemplates";
import {
  createFaultTreeFromTemplate,
  reorderAllFaultTreesForAUnit,
} from "../../../frameworks/fetcher/api-routes-experimental";
import { addToast } from "../../toast/use-toast-store";
import {
  useFaultTreesQuery,
  useInvalidateSectionsQuery,
} from "../../../hooks/tanstack-query";
import LoadingScreen from "../../common/LoadingScreen";
import { Plus } from "lucide-react";
import { Button } from "../../ui/button";
import { CreateFaultTreeSectionButton } from "../../groups/manager/create-ft-section-button";
import { useFaultTreesWithSections } from "../../groups/manager/use-groups-with-sections";
import { faultTreeSchema } from "../../../lib/api-schema/ft/fault-tree";
import { SectionsArea } from "../../groups/manager/ft-section-card";
import useHasEditPermission from "../../../zustand/useHasEditPermission";
import {
  DndContext,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useMutation } from "@tanstack/react-query";
import { SortableContext } from "@dnd-kit/sortable";

const PAGE_NAME = "Fault Trees";

function FaultTreeManager() {
  usePermissionBasedDocumentTitleForSettingsPage();

  const nav = useNavigate();
  const baseUrlSlash = useBaseUrlExperimental();
  const hasEditAccess = useHasEditPermission();
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false);

  const treesQuery = useFaultTreesQuery();
  const trees = treesQuery.data || [];

  async function createTree(template: TreeTemplateEnum) {
    setLoading(true);
    createFaultTreeFromTemplate(baseUrlSlash, template).then((response) => {
      // Navigate to editor on new tree creation.
      nav("../../ft/edit/" + response._id, { relative: "path" });
    });
  }

  const faultTreesWithSections = useFaultTreesWithSections();

  return (
    <Fragment>
      {loading && (
        <LoadingScreen
          message={"Building Fault Tree"}
          visible={loading}
          overlayBlur={undefined}
        />
      )}
      <div className="flex flex-col sm:flex-row items-center justify-between md:mt-6">
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-[2rem] sm:text-[1.75rem] mr-2">
            {PAGE_NAME}
          </span>

          {hasEditAccess && (
            <Button
              size={"xs"}
              onClick={() =>
                openModal({
                  title: "Select a fault tree template to get started",
                  closeLabel: "Cancel",
                  closePosition: "top",
                  closeIcon: true,
                  size: "w-[80vw] max-h-none max-w-none",
                  body: {
                    component: (
                      <div className="w-full max-h-[75vh]">
                        <div className="flex">
                          {TreeTemplateNumbers.map((template) => {
                            return (
                              <FaultTreeTemplate
                                key={template.toString()}
                                template={template}
                                onClick={() => {
                                  createTree(template);
                                  closeModal();
                                  addToast({
                                    title: "Creating new tree...",
                                    variant: "primary",
                                  });
                                }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    ),
                  },
                })
              }
            >
              <Plus className="size-3.5 mr-1" /> Create Fault Tree
            </Button>
          )}
          <CreateFaultTreeSectionButton className="ml-3" />
        </div>
        <div>
          {!!trees.length && (
            <span className="mr-5 italic text-[1.25rem] text-titlegrey">
              Displaying{" "}
              {trees.filter((tree) => hasEditAccess || tree.published).length}{" "}
              {PAGE_NAME}
            </span>
          )}
        </div>
      </div>
      <div className="drop-shadow-md my-6">
        {faultTreesWithSections && (
          <div className="list-none my-5">
            {/* trees that dont belong to a section go on top  */}
            <LoneTreesArea trees={faultTreesWithSections.remainingFaultTrees} />
            {faultTreesWithSections.remainingFaultTrees.length > 0 &&
              faultTreesWithSections.sectionsWithFaultTrees.length > 0 && (
                <div className="w-full h-[1.5px] bg-xslate-6 my-8"></div>
              )}
            <SectionsArea />
          </div>
        )}
      </div>
    </Fragment>
  );
}

function LoneTreesArea({ trees }: { trees: faultTreeSchema[] }) {
  const treesWithSections = useFaultTreesWithSections();
  const hasEditAccess = useHasEditPermission();
  const [copiedTrees, setCopiedTrees] = useState(trees);

  useEffect(() => {
    /**
     * Every drag and drop event will cause a PATCH update.
     * We do not want to wait for the request to finish
     * before we see the cards switch places. It must be
     * done immediately, so we'll keep a copy of the trees
     * and update it manually on drag and drop. Them, when
     * the request finishes, it'll update again but we'll
     * have already done it optimistically.
     */
    setCopiedTrees(trees);
  }, [setCopiedTrees, trees]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const currentOrder = useMemo(
    () => copiedTrees.map((x) => x._id),
    [copiedTrees]
  );

  const droppableContainerId = useId();
  const { setNodeRef } = useDroppable({
    id: droppableContainerId,
  });
  const refreshSections = useInvalidateSectionsQuery();
  const b = useBaseUrlExperimental();

  const updateTreeOrderMut = useMutation({
    mutationFn: async (newLoneTreesOrder: string[]) => {
      if (!hasEditAccess) return;

      const theseGoOnTop = newLoneTreesOrder;
      const theseGoOnTheBottom =
        treesWithSections?.sectionsWithFaultTrees.flatMap((x) =>
          x.faultTrees.map((y) => y._id)
        ) || [];
      const newDisplayIdsOrdering = [...theseGoOnTop, ...theseGoOnTheBottom];

      await reorderAllFaultTreesForAUnit(b, newDisplayIdsOrdering);
    },
  });

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={
        hasEditAccess
          ? (e) => {
              const theThingDragging = e.active.id.toString();

              const over = e.over?.id.toString();

              if (over === undefined) {
                return; // no-op
              }

              if (over === droppableContainerId) return;

              if (theThingDragging === over) return; // no-op because same element

              const idxA = currentOrder.indexOf(theThingDragging);
              const idxB = currentOrder.indexOf(over);

              if (idxA === -1 || idxB === -1) {
                throw new Error("unhandled case onDragEnd 2");
              }

              const newOrder = currentOrder.filter(
                (x) => x !== theThingDragging
              );
              newOrder.splice(idxB, 0, theThingDragging);

              // mutate
              updateTreeOrderMut.mutate(newOrder);

              // optimistic update so the user sees instant re-ordering
              setCopiedTrees(
                copiedTrees.slice().sort((a, b) => {
                  const idxA = newOrder.indexOf(a._id);
                  const idxB = newOrder.indexOf(b._id);
                  return idxA - idxB;
                })
              );
            }
          : undefined
      }
    >
      <SortableContext items={currentOrder}>
        <div ref={setNodeRef}>
          {copiedTrees.map((tree) => (
            <FaultTreeCard renderAsDraggable key={tree._id} tree={tree} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

export default FaultTreeManager;

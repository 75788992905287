import "./OperatingLimitsDetails.scss";
import { useBaseUrlExperimental } from "../../../zustand/useBaseUrl";
import {
  OlDetailsStoreProvider,
  useGetUseOlDetailsStore,
} from "./use-ol-details-store";
import { LinkWithQuery } from "../../nav/LinkWithQuery2";
import { FaArrowLeft } from "react-icons/fa";
import Button from "../../common/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { produce } from "immer";
import {
  QUERY_MODE_LABELS,
  limitLevelNumSchema,
  limitTypeSchema,
} from "../constants";
import { OperatingFitnessToggle } from "../overview/OperatingFitnessLevelsToggler";
import { capitalize } from "lodash";
import {
  GroupSelect,
  useSelectedGroupOnOperatingLimitsPages,
} from "./group-select";
import { OperatingLimitsOverviewCharts } from "./ol-details-charts";
import { ListOfCharts } from "./list-of-charts/list-of-charts";
import { OperatingLimitsDetailsSidebar } from "./sidebar";
import { DaisySelect } from "../../common/Select/Select";
import {
  VariabilityDrawer,
  VariabilityDrawerStoreProvider,
} from "../../variability-view/variability-drawer";
import {
  FullscreenDrawer,
  FullscreenProvider,
} from "../../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { FullscreenChartContainer } from "../../../shared-ui/time-series-2/secondary-variable-view/secondary-variable-view";
import { Provider, useAtomValue } from "jotai";
import { DRASecondaryVariableViewNeedsProvider } from "../../time-series/secondary-variable-view/dra-secondary-variable.view";
import { useOperatingLimitsQuery } from "../../../hooks/tanstack-query";
import {
  Atoms,
  ChartVariant,
} from "../../../shared-ui/time-series-2/svv-store/use-svv-store";
import { UseViewModeStoreProvider } from "../../../shared-ui/time-series-2/grid-view-store";
import { ClusterDrawerStoreProvider } from "../../clusters/cluster-drawer/use-cluster-drawer-store";
import { ClusterDrawer } from "../../clusters/cluster-drawer/cluster-drawer";
import { TimeseriesChartTooltipStoreProvider } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "../../time-series/global-tooltip";
import { CorrelatedTagsDrawerStoreProvider } from "../../clusters/cluster-drawer/use-correlated-tags-drawer-store";
import { CorrelatedTagsDrawer } from "../../clusters/cluster-drawer/correlated-tags-drawer";
import Nav from "../../nav/Nav";
import { cn } from "../../../lib/utils";
import DateStateNav from "../../dateSelector/date-state-nav";
import {
  globalGroupsSidebarOpenAtom,
  SelectedGroupContextProvider,
  ToggleGlobalSidebarButton,
} from "../../common/sidebar/generic-global-sidebar-with-groups";
import { GlobalOperatingLimitsSidebar } from "./global-operating-limits-sidebar";
import { VIEW_MODE } from "../../common/view-mode-selectors";

function Page() {
  const group = useSelectedGroupOnOperatingLimitsPages();
  const onGroupSelect = useOnGroupSelect();

  return (
    <Provider>
      <VariabilityDrawerStoreProvider
        init={{
          isForOFPage: true,
        }}
      >
        <VariabilityDrawer />
        <Nav />
        <div
          id="interactible-container"
          className={cn(
            "relative MIN_H_SCREEN animate-in slide-in-from-bottom-7 duration-300"
          )}
        >
          <div className="flex">
            <SelectedGroupContextProvider
              selectedGroup={group}
              onSelect={(g) => {
                onGroupSelect(g._id);
              }}
            >
              <GlobalOperatingLimitsSidebar />
            </SelectedGroupContextProvider>
            <div className="grow">
              <DateStateNav
                allowRangeCalendar
                leftmostChildren={
                  <>
                    <LinkWithQuery
                      to={"../.."}
                      pick={{ d: true, mo: true, y: true, z: true, cd: true }}
                      relative="path"
                    >
                      <Button icon={FaArrowLeft} className="btn-ghost" />
                    </LinkWithQuery>
                    <ToggleGlobalSidebarButton />
                    <NavChildren />
                  </>
                }
              />

              <div className="flex justify-between py-2 mb-1 mx-2">
                <div className="page-header prose pl-1">
                  <h2>{`Operating Fitness Detailed View${
                    group ? `: ${group.name}` : ""
                  }`}</h2>
                </div>
                <div className="flex flex-row">
                  <div className="flex gap-2 items-center bg-base-100 rounded-md px-2">
                    <NumberedLevelsToggles />
                  </div>
                </div>
              </div>
              <OperatingLimitsOverviewCharts className="mb-2" />
              <div className="flex">
                <OperatingLimitsDetailsSidebar />
                <ListOfCharts />
              </div>
            </div>
          </div>
        </div>
      </VariabilityDrawerStoreProvider>
    </Provider>
  );
}

const DECREASE_PER_LIMIT = 0.04;

function FullscreenChartNeedsProvider() {
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom);

  if (primaryTrendLine.type !== "variable")
    throw new Error("This is an expression chart. Bug");

  const primaryVariableId = primaryTrendLine.bv.slice(24);
  const limitsQuery = useOperatingLimitsQuery(primaryVariableId);
  const data = limitsQuery.data;

  const proportionOfScreenHeight =
    0.9 - DECREASE_PER_LIMIT * (data ? data.length : 0);

  const SOME_SCALE = 2;

  return (
    <DRASecondaryVariableViewNeedsProvider
      padding={{
        left: 45,
      }}
      expandable
      numTicks={undefined}
      ableToGetTaller={false}
      height={window.innerHeight * proportionOfScreenHeight * SOME_SCALE}
      width={window.innerWidth * SOME_SCALE}
      lineWidthScale={0.3}
      axesFontScale={0.7}
    />
  );
}

function QueryModeSelect() {
  const useStore = useGetUseOlDetailsStore();
  const queryMode = useStore((s) => s.queryMode);

  return (
    <DaisySelect
      className="mx-2 w-[200px]"
      value={queryMode}
      bordered
      onChange={(e) =>
        useStore.getState().setQueryMode(e.target.value as typeof queryMode)
      }
      options={Object.entries(QUERY_MODE_LABELS).map(([value, label]) => {
        return {
          value: value,
          label: label,
        };
      })}
    />
  );
}

function NumberedLevelsToggles() {
  const useStore = useGetUseOlDetailsStore();
  const excludeLevels = useStore((s) => s.excludeLevels);
  const excludeTypes = useStore((s) => s.excludeTypes);

  return (
    <>
      {Object.entries(excludeLevels).map(([level, exclude]) => {
        const isOn = !exclude;
        const levelNum = limitLevelNumSchema.parse(parseInt(level));

        return (
          <OperatingFitnessToggle
            key={level}
            levelOrType={levelNum}
            checked={isOn}
            onChange={(e) => {
              const on = e.target.checked;
              const isExcluded = !on;
              useStore.getState().setExcludeLevel(
                produce((s) => {
                  s[limitLevelNumSchema.parse(parseInt(level))] = isExcluded; // we store the opposite truth value because its convenient for the API
                })
              );
            }}
          >{`L${level}`}</OperatingFitnessToggle>
        );
      })}
      {Object.entries(excludeTypes).map(([type, exclude]) => {
        const isOn = !exclude;

        return (
          <OperatingFitnessToggle
            key={type}
            checked={isOn}
            onChange={(e) => {
              const on = e.target.checked;
              const isExcluded = !on;
              useStore.getState().setExcludeType(
                produce((s) => {
                  s[limitTypeSchema.parse(type)] = isExcluded; // we store the opposite truth value because its convenient for the API
                })
              );
            }}
          >
            {capitalize(type)}
          </OperatingFitnessToggle>
        );
      })}
    </>
  );
}

function useOnGroupSelect() {
  const nav = useNavigate();
  const [sp] = useSearchParams();

  return (gid: string) =>
    nav(`../${gid}?${sp.toString()}`, {
      relative: "path",
    });
}

function NavChildren() {
  const groupObj = useSelectedGroupOnOperatingLimitsPages();
  const onChange = useOnGroupSelect();

  const globalOpen = useAtomValue(globalGroupsSidebarOpenAtom);

  return (
    <>
      {!globalOpen && (
        <GroupSelect
          value={groupObj?._id.toString()}
          onChange={onChange}
          valueFn={(g) => g._id}
        />
      )}
      <QueryModeSelect />
    </>
  );
}

export function OperatingLimitsDetails() {
  const baseUrl = useBaseUrlExperimental();
  const savedView = (localStorage.getItem("OF_VIEW_MODE") ||
    "grid") as VIEW_MODE;

  return (
    <OlDetailsStoreProvider key={baseUrl}>
      <CorrelatedTagsDrawerStoreProvider>
        <CorrelatedTagsDrawer chartVariant={ChartVariant.OperatingLimits} />
        <FullscreenProvider>
          <ClusterDrawerStoreProvider>
            <UseViewModeStoreProvider
              init={{ initialViewMode: savedView, initialNumCols: 2 }}
            >
              <TimeseriesChartTooltipStoreProvider>
                {() => (
                  <>
                    <ClusterDrawer isOperatingLimits />
                    <GlobalTooltip />
                    <Page />
                    <FullscreenDrawer className="max-h-[98dvh] overflow-scroll">
                      {(store) => (
                        <FullscreenChartContainer>
                          <Provider store={store}>
                            <FullscreenChartNeedsProvider />
                          </Provider>
                        </FullscreenChartContainer>
                      )}
                    </FullscreenDrawer>
                  </>
                )}
              </TimeseriesChartTooltipStoreProvider>
            </UseViewModeStoreProvider>
          </ClusterDrawerStoreProvider>
        </FullscreenProvider>
      </CorrelatedTagsDrawerStoreProvider>
    </OlDetailsStoreProvider>
  );
}

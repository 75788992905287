import { type AnomalyLevelEnum } from "../lib/anomaly-levels";

type Timeseries = {
  timestamp: number;
  value: number;
};

type Changepoints = {
  timestamp: number;
  da: number;
  mode: string | null;
  value: number;
};

type Point = {
  v: number;
  t: number;
};

type Points = {
  pts: [Point, ...Point[]];
  r: [number, number];
  d: AnomalyLevelEnum | null; // TODO attach this to something else
};

type PointsPartitioned = [Points, ...Points[]];

type StageFromAPI = {
  d: [number, number];
  r: [number, number];
  _id: "Remainder" | "000000000000000000000000" | (string & {});
  ptsPartitioned: PointsPartitioned;
};

type TimeseriesForBv = (
  | {
      type: "expression";
      id: string;
    }
  | {
      type: "variable";
      bv: string;
    }
) & {
  stages: [StageFromAPI, ...StageFromAPI[]];
  d: [number, number];
  r: [number, number];
};

type TimeseriesForBvFromVariable = Extract<
  TimeseriesForBv,
  { type: "variable" }
>;

type TimeseriesForBvFromExpression = Extract<
  TimeseriesForBv,
  { type: "expression" }
>;

type StageForDraw = {
  d: [number, number];
  r: [number, number];
  _id: StageFromAPI["_id"];
  ptsPartitioned: PointsPartitioned;
  offset: { (t: number): number; invert(t: number): number };
};

type TimeseriesForBvForDraw = (
  | {
      type: "variable";
      bv: string;
    }
  | {
      type: "expression";
      id: string;
    }
) & {
  stages: [StageForDraw, ...StageForDraw[]];
  d: [number, number];
  r: [number, number];
};

enum LineWidth {
  Regular,
  ExplicitBold,
  HoverBold,
}

export {
  type Timeseries,
  type Changepoints,
  type StageFromAPI,
  type Point,
  type Points,
  type TimeseriesForBv,
  type TimeseriesForBvForDraw,
  type PointsPartitioned,
  type TimeseriesForBvFromVariable,
  type TimeseriesForBvFromExpression,
  LineWidth,
};

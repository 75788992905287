import { createStore, Provider, useAtomValue } from "jotai";
import { FullscreenDrawer } from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { useGetUseTimeseriesChartsTooltipStore } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { FullscreenChartContainer } from "../../shared-ui/time-series-2/secondary-variable-view/secondary-variable-view";
import { DRASecondaryVariableViewNeedsProvider } from "../time-series/secondary-variable-view/dra-secondary-variable.view";
import {
  Atoms,
  ChartVariant,
  isVariableTrendLine,
} from "../../shared-ui/time-series-2/svv-store/use-svv-store";
import { iife } from "../../lib/utils";
import { useOperatingLimitsQuery } from "../../hooks/tanstack-query";
import { minutesToMilliseconds } from "date-fns";

export function FullscreenChartForCommentsPage() {
  return (
    <FullscreenDrawer>
      {(store) => <AutoAdjustHeightForLimits store={store} />}
    </FullscreenDrawer>
  );
}

const DECREASE_PER_LIMIT = 0.04;

function AutoAdjustHeightForLimits({
  store,
}: {
  store: ReturnType<typeof createStore>;
}) {
  const primaryTrendLine = useAtomValue(Atoms.primaryBatchVariableAtom, {
    store,
  });
  const useTooltipStore = useGetUseTimeseriesChartsTooltipStore();

  const isLimitsVariant =
    useAtomValue(Atoms.chartVariantAtom, {
      store,
    }) === ChartVariant.OperatingLimits;

  const primaryIsVariableVariant = isVariableTrendLine(primaryTrendLine);

  const limits = useOperatingLimitsQuery(
    primaryIsVariableVariant ? primaryTrendLine.bv.slice(24) : "dummy-unused",
    { enabled: primaryIsVariableVariant, staleTime: minutesToMilliseconds(5) }
  ).data;

  const height = iife(() => {
    const BASE = 0.9;

    if (!isLimitsVariant) return BASE;

    return BASE - DECREASE_PER_LIMIT * (limits ? limits.length : 0);
  });

  return (
    <FullscreenChartContainer>
      <Provider store={store}>
        <DRASecondaryVariableViewNeedsProvider
          onLineClick={
            useTooltipStore &&
            ((numVars) => {
              if (numVars < 2) return undefined;

              return ({ bvOrId, clientX, clientY, jotaiStore: _ }) => {
                useTooltipStore.getState().setClickedLineData({
                  clientX,
                  clientY,
                  jotaiStore: store,
                  bvOrId,
                });
              };
            })
          }
          ableToGetTaller={false}
          height={window.innerHeight * height * 2}
          width={window.innerWidth * 2}
          lineWidthScale={0.3}
          axesFontScale={0.7}
        />
      </Provider>
    </FullscreenChartContainer>
  );
}

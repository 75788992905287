/**
 * This is gonna live with the time-series stuff
 * because I suspect grid view will probably be
 * integral to the chart itself, and not just a
 * feature of Profile Book.
 *
 * Also, grid view affects the chart's styles so
 * it makes sense to have grid view be part of the
 * chart, and other parts of the app can hook into
 * it. Not the other way around.
 */

import {
  createContext,
  useContext,
  useState,
  type PropsWithChildren,
} from "react";
import { create } from "zustand";
import { VIEW_MODE } from "../../components/common/view-mode-selectors";
import { subscribeWithSelector } from "zustand/middleware";

type ViewModeStore = {
  viewMode: VIEW_MODE;
  setViewMode: (mode: VIEW_MODE) => void;
  numCols: number;
  setNumCols: (numCols: number) => void;
};

type Initializer = { initialViewMode?: VIEW_MODE; initialNumCols?: number };

function createViewModeStore(init?: Initializer) {
  return create<ViewModeStore>()(
    subscribeWithSelector((set) => ({
      viewMode: init?.initialViewMode ?? "grid",
      setViewMode: (mode) => set({ viewMode: mode }),
      numCols: init?.initialNumCols ?? 4,
      setNumCols: (numCols) => set({ numCols }),
    }))
  );
}

type UseViewModeStore = ReturnType<typeof createViewModeStore>;

const UseViewModeStoreContext = createContext<UseViewModeStore | undefined>(
  undefined
);

/**
 * Wrap a group of charts that should be rendered together on the same page.
 * This page renders this different depending on the view mode provided in
 * the store.
 */
function UseViewModeStoreProvider({
  children,
  init,
}: PropsWithChildren<{ init?: Initializer }>) {
  const [useStore] = useState(() => createViewModeStore(init));

  return (
    <UseViewModeStoreContext.Provider value={useStore}>
      {children}
    </UseViewModeStoreContext.Provider>
  );
}

function useGetUseViewModeStore() {
  const useStore = useContext(UseViewModeStoreContext);

  if (!useStore)
    throw new Error(
      "useGetUseViewModeStore must be used within a UseViewModeStoreProvider"
    );

  return useStore;
}

function useGetUseViewModeStoreNotRequired() {
  return useContext(UseViewModeStoreContext);
}

export {
  UseViewModeStoreProvider,
  useGetUseViewModeStore,
  useGetUseViewModeStoreNotRequired,
};

import { cn, iife } from "../../lib/utils";
import { useToggleFullscreen } from "../../shared-ui/time-series-2/fullscreen/fullscreen-provider";
import { VIEW_MODE } from "../common/view-mode-selectors";
import {
  chartDimensionsConfig,
  DRASecondaryVariableViewNeedsProvider,
  DRASecondaryVariableViewProvider,
} from "../time-series/secondary-variable-view/dra-secondary-variable.view";
import { useGetUseTimeseriesChartsTooltipStore } from "../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { assertMinLen1 } from "../../shared-ui/lib/utils";
import { useMemo } from "use-memo-one";

export function DriCharts({
  chartViewMode,
  chartsToDisplay,
  gridModeCols,
  store,
}: {
  chartViewMode: VIEW_MODE;
  chartsToDisplay: string[];
  gridModeCols: number;
  store: NonNullable<ReturnType<typeof useGetUseTimeseriesChartsTooltipStore>>;
}) {
  const toggleFullscreen = useToggleFullscreen();

  const chartDimsProps = useMemo(
    () =>
      chartDimensionsConfig(
        chartViewMode === "grid"
          ? { isGridView: true, numCols: gridModeCols }
          : { isGridView: false }
      ),
    [gridModeCols, chartViewMode]
  );

  const numCharts = chartsToDisplay.length;

  if (chartViewMode !== "chart") {
    return (
      <>
        {chartsToDisplay.map((variableId, i) => {
          // this is a special case that we use in cluster view to render a "not part of this group line" ctrl+f for "_.partition" if interested
          const separatingLine = variableId === null;
          const isLastElt = i === numCharts - 1;

          return separatingLine ? (
            isLastElt ? null : (
              <div className="flex items-center my-3">
                <hr className="grow border border-gray-500" />
                <div className="mx-4 mono text-[0.8rem]">
                  Not part of this group
                </div>
                <hr className="grow border border-gray-500" />
              </div>
            )
          ) : (
            <div
              key={variableId}
              className={cn(chartViewMode !== "grid" && "mb-6")}
            >
              <DRASecondaryVariableViewProvider
                key={variableId}
                initialBatchVariables={[
                  {
                    type: "variable",
                    bv: variableId.padStart(48, "0"),
                  },
                ]}
                initialExpanded={numCharts <= 1}
              >
                <DRASecondaryVariableViewNeedsProvider
                  commentsOnlyInFullscreen={chartViewMode === "grid"}
                  ableToGetTaller={chartViewMode !== "grid"}
                  {...chartDimsProps}
                  expandable
                  numTicks={chartViewMode === "grid" ? 4 : undefined}
                  onlyMinMaxYAxes={chartViewMode === "grid"}
                  onLineClick={iife(() => {
                    if (chartViewMode === "grid") {
                      return (
                        toggleFullscreen &&
                        (() =>
                          ({ jotaiStore }) => {
                            toggleFullscreen(jotaiStore);
                          })
                      );
                    }

                    return (numVars) => {
                      if (numVars < 2) return undefined;

                      return ({ bvOrId, clientX, clientY, jotaiStore }) => {
                        store.getState().setClickedLineData({
                          clientX,
                          clientY,
                          jotaiStore,
                          bvOrId,
                        });
                      };
                    };
                  })}
                />
              </DRASecondaryVariableViewProvider>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <DRASecondaryVariableViewProvider
      key={chartsToDisplay.join(",")}
      initialBatchVariables={assertMinLen1(
        chartsToDisplay.map((variableId) => ({
          type: "variable",
          bv: variableId.padStart(48, "0"),
        }))
      )}
      initialExpanded={numCharts <= 1}
    >
      <DRASecondaryVariableViewNeedsProvider
        {...chartDimsProps}
        onLineClick={iife(() => {
          return (numVars) => {
            if (numVars < 2) return undefined;
            return ({ bvOrId, clientX, clientY, jotaiStore }) => {
              store.getState().setClickedLineData({
                clientX,
                clientY,
                jotaiStore,
                bvOrId,
              });
            };
          };
        })}
      />
    </DRASecondaryVariableViewProvider>
  );
}

"use client";

import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cn } from "./cn";
import { cva, type VariantProps } from "class-variance-authority";

const outerSize = {
  lg: "h-6 w-11",
  md: "h-5 w-9",
  sm: "h-4 w-6",
};

const innerSize = {
  lg: "size-5 data-[state=checked]:translate-x-5",
  md: "size-4 data-[state=checked]:translate-x-4",
  sm: "size-3 data-[state=checked]:translate-x-2",
} satisfies typeof outerSize;

const switchVariants = cva(undefined, {
  variants: {
    size: outerSize,
  },
  defaultVariants: {
    size: "lg",
  },
});

const innerVariants = cva(undefined, {
  variants: {
    size: innerSize,
  },
  defaultVariants: {
    size: "lg",
  },
});

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> &
    VariantProps<typeof switchVariants>
>(({ className, size, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-indigo-600 data-[state=unchecked]:bg-xslate-5",
      switchVariants({ size, className })
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block size-4 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-6 data-[state=unchecked]:translate-x-0",
        innerVariants({ size })
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };

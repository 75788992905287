export type Option = { label: string; value: string; description?: string };

export function exprRef(opt: Option) {
  return `<span class="exprRef" contenteditable="false" data-value="${opt.value}">${opt.label}</span>`;
}

export function parseExprRef(html: string): string {
  // replace exprRef spans with their values
  const regex =
    /<span class="exprRef" contenteditable="false" data-value="(.+?)">(.+?)<\/span>/g;
  let match;
  let newHtml = html;
  while ((match = regex.exec(html)) !== null) {
    newHtml = newHtml.replace(match[0], `"${match[1]}"`);
  }
  newHtml = replaceHtmlSpecialChars(newHtml);
  return newHtml;
}

export function replaceHtmlSpecialChars(str: string) {
  // return html str as it would be displayed as text
  const tempElement = document.createElement("div");
  tempElement.innerHTML = str.replace(/&nbsp;/g, " "); // nbsp will get replaced with a weird space mathjs can't parse
  const decodedString = tempElement.textContent || tempElement.innerText;
  return decodedString;
}

export function getRangeHtml(range: Range) {
  // create a new div element to contain the Range content
  const container = document.createElement("div");
  // clone the Range and surround its contents with the div
  container.appendChild(range.cloneContents());
  // todo should we be deleting this div?
  return container.innerHTML;
}

export function findQuoteIndex(str: string) {
  let inSpan = false; // Indicates whether the current position is inside a span tag
  let index = -1; // Will hold the index of the quotation mark, if found
  for (let i = 0; i < str.length; i++) {
    if (!inSpan && str[i] === '"') {
      // Found the target quotation mark outside of a span tag
      return i;
    } else if (
      str.slice(i, i + 6) === "<span " ||
      str.slice(i, i + 5) === "<span>"
    ) {
      // Entering a span tag
      inSpan = true;
    } else if (inSpan && str.slice(i, i + 7) === "</span>") {
      // Exiting a span tag
      inSpan = false;
      i += 6; // Skip the rest of the span closing tag
    }
  }
  return index; // Return -1 if no appropriate quotation mark was found
}

export function moveCaretPosition(div: HTMLDivElement, offset: number) {
  // Create a new range and selection
  const range = document.createRange();
  const selection = window.getSelection();
  if (!selection) return;

  // Clear existing selections
  selection.removeAllRanges();

  // Initialize variables for the current node and character count
  let current = null;
  let charCount = 0;

  // Use a TreeWalker to go through text nodes
  const walker = document.createTreeWalker(div, NodeFilter.SHOW_TEXT, null);

  // Walk through all text nodes to find the correct position
  while (walker.nextNode()) {
    current = walker.currentNode;
    // Check if the offset is within this node
    if (offset <= charCount + (current.nodeValue?.length || 0)) {
      // Found the node, set the range and break
      range.setStart(current, offset - charCount);
      range.collapse(true);
      break;
    }
    // Add this node's length to the total count
    charCount += current.nodeValue?.length || 0;
  }

  // If we didn't find a suitable text node (e.g., offset is beyond last character)
  if (current === null) {
    // Just use the div's last child or the div itself
    range.setStart(div, div.childNodes.length);
    range.collapse(true);
  }

  // Add the range to the selection
  selection.addRange(range);

  // Optionally, focus the div to immediately show the caret
  div.focus();
}

export function getCaretPosition(input: HTMLDivElement): {
  x: number;
  y: number;
} {
  const sel = window.getSelection();
  if (!sel || sel.rangeCount === 0) throw new Error("No selection available");
  const rect = sel.getRangeAt(0).getBoundingClientRect();
  const { offsetTop: inputY, offsetLeft: inputX } = input;
  let x = rect.left - input.scrollLeft + inputX;
  let y = rect.top - input.scrollTop + inputY; //+ rect.height; // Position below the line of text
  return { x, y };
}

function getRangeHTML(range: Range) {
  const div = document.createElement("div");
  div.appendChild(range.cloneContents());
  return div.innerHTML;
}

export function getContentAroundCaret(
  element: HTMLDivElement,
  asHTML = false
): {
  before: string;
  after: string;
} {
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);

    // Create range for text before caret
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.startContainer, range.startOffset);
    const textBeforeCaret = asHTML
      ? getRangeHTML(preCaretRange)
      : preCaretRange.toString();

    // Create range for text after caret
    const postCaretRange = range.cloneRange();
    postCaretRange.selectNodeContents(element);
    postCaretRange.setStart(range.endContainer, range.endOffset);
    const textAfterCaret = asHTML
      ? getRangeHTML(postCaretRange)
      : postCaretRange.toString();

    return { before: textBeforeCaret, after: textAfterCaret };
  }
  return { before: "", after: "" };
}

export function findFunctionName(str1: string, str2: string) {
  // Find the start of the function name
  let start = str1.length - 1;
  let end = start;
  // keep track of open parens to know when how many to close
  let numOpenParens = 0;
  // check if open paren is in string2, otherwise walk back to find it
  let seenOpenParen = /^[a-zA-Z_]*\(/.test(str2);
  while (
    start >= 0 &&
    (!seenOpenParen || /[a-zA-Z_]/.test(str1[start] || ""))
  ) {
    if (str1[start] === "(") {
      // parenthesis can be used within function args but if the preceding character is a letter, it's a function name
      if (/[a-zA-Z]/.test(str1[start - 1] || "")) {
        seenOpenParen = true;
        end = start;
      }
      numOpenParens++;
    }
    if (str1[start] === ")") {
      numOpenParens--;
    }
    start--;
  }
  start++;
  if (numOpenParens === 1) {
    return str1.substring(start, end);
  }
  const part1 = str1.substring(start, end + 1);

  // Find the end of the function name
  end = 0;
  while (end < str2.length) {
    if (str2[end] === "(") {
      numOpenParens++;
    }
    if (str2[end] === ")") {
      numOpenParens--;
    }
    if (numOpenParens > 0) {
      break;
    }
    end++;
  }
  const part2 = str2.substring(0, end);
  if (numOpenParens === 0) return "";

  return part1 + part2;
}

export function getLastWordMatchingRegex(str: string, regex: RegExp) {
  let lastMatch = "";
  let match;
  while ((match = regex.exec(str)) !== null) {
    lastMatch = match[0];
  }
  return lastMatch;
}

export function decodeHtml(html: string) {
  var textArea = document.createElement("textarea");
  textArea.innerHTML = html;
  return textArea.value;
}

export function replaceOptions(str: string, options: Option[]): string {
  // could just do a regex but then have to escape special characters
  // options.forEach((opt) => {
  //   const regex = new RegExp(`"${opt.value}"`, "g");
  //   replaceIds = replaceIds.replace(regex, exprRef(opt));
  // });
  for (const option of options) {
    const searchStr = `"${option.value}"`; // The placeholder including quotes
    const replacement = exprRef(option);
    // Replace all occurrences of searchStr in str with replacement
    str = replaceAllOccurrences(str, searchStr, replacement);
  }
  return str;
}

function replaceAllOccurrences(
  str: string,
  search: string,
  replacement: string
): string {
  if (search === "") {
    // Avoid infinite loop
    return str;
  }
  let index = 0;
  let result = "";
  let searchLength = search.length;
  while ((index = str.indexOf(search)) !== -1) {
    result += str.substring(0, index) + replacement;
    str = str.substring(index + searchLength);
  }
  result += str;
  return result;
}

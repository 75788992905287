import * as d3 from "d3";

const getColors = [
  {
    value: 0,
    color: "green",
    label: "No Risk",
    r: 136,
    g: 196,
    b: 37,
  },
  {
    value: 1,
    color: "yellow",
    label: "Low Risk",
    r: 248,
    g: 202,
    b: 0,
  },
  {
    value: 2,
    color: "orange",
    label: "Medium Risk",
    r: 233,
    g: 127,
    b: 2,
  },
  {
    value: 3,
    color: "red",
    label: "High Risk",
    r: 194,
    g: 26,
    b: 1,
  },
].map((o) => {
  const { r, g, b } = o;
  o.rgb = `rgb(${r}, ${g}, ${b})`;
  return o;
});

const operators = ["da", "da_hi", "da_lo", "da_sf"];
const STATUS_COLORS = getColors.map((c) => c.rgb).slice(1);

// copied from https://github.com/FortAwesome/Font-Awesome/tree/master/svgs/solid
const ICONS = {
  normal:
    "M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z",
  issue:
    "M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z",
  monitoring:
    "M304 192v32c0 6.6-5.4 12-12 12h-56v56c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-56h-56c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h56v-56c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v56h56c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z",
};
/**
 * draw method adapted from https://observablehq.com/@d3/tree
 */
const renderFaultTree = (
  data,
  acks,
  options,
  ref,
  handleTreeNodeChange,
  zoomStateRef
) => {
  // data is either tabular (array of objects) or hierarchy (nested objects)
  if (!data || !ref) {
    return null;
  }
  const defaultOptions = {
    path: null, // as an alternative to id and parentId, returns an array identifier, imputing internal nodes
    id: Array.isArray(data) ? (d) => d.id : null, // if tabular data, given a d in data, returns a unique identifier (string)
    parentId: Array.isArray(data) ? (d) => d.parentId : null, // if tabular data, given a node d, returns its parent’s identifier
    children: null, // if hierarchical data, given a d in data, returns its children
    tree: d3.tree, // layout algorithm (typically d3.tree or d3.cluster)
    sort: null, // how to sort nodes prior to layout (e.g., (a, b) => d3.descending(a.height, b.height))
    label: null, // given a node d, returns the display name
    title: null, // given a node d, returns its hover text
    link: null, // given a node d, its link (if any)
    linkTarget: "_blank", // the target attribute for links (if any)
    width: 1000,
    height: 1000,
    r: 7, // radius of nodes
    dx: 10,
    fontSize: 15,
    fontFamily: "'Open Sans', sans-serif",
    padding: 1, // horizontal padding for first and last column
    fill: "#999", // fill for nodes
    fillOpacity: null, // fill opacity for nodes
    stroke: "rgb(161, 161, 170)", // stroke for links
    strokeWidth: 2.5, // stroke width for links
    strokeOpacity: 1, // stroke opacity for links
    strokeLinejoin: null, // stroke line join for links
    strokeLinecap: null, // stroke line cap for links
    halo: "#fff", // color of label halo
    haloWidth: 0, // padding around the labels
    pdf: false, // we apply slightly different styles for pdf export
    panEnabled: true,
    zoomEnabled: false,
    highlightNode: null,
  };
  // merge options
  const opts = Object.assign({}, defaultOptions, options);
  // expand options
  let {
    path,
    id,
    parentId,
    children,
    tree,
    sort,
    label,
    title,
    link,
    linkTarget,
    width,
    height,
    r,
    dx,
    fontSize,
    fontFamily,
    padding,
    fill,
    fillOpacity,
    stroke,
    strokeWidth,
    strokeOpacity,
    strokeLinejoin,
    strokeLinecap,
    halo,
    haloWidth,
    pdf,
    panEnabled,
    zoomEnabled,
  } = opts;

  // If id and parentId options are specified, or the path option, use d3.stratify
  // to convert tabular data to a hierarchy; otherwise we assume that the data is
  // specified as an object {children} with nested objects (a.k.a. the “flare.json”
  // format), and use d3.hierarchy.
  const root =
    path != null
      ? d3.stratify().path(path)(data)
      : id != null || parentId != null
        ? d3.stratify().id(id).parentId(parentId)(data)
        : d3.hierarchy(data);

  // Sort the nodes.
  if (sort != null) root.sort(sort);

  // Compute labels and titles.
  const descendants = root.descendants();
  const L = label == null ? null : descendants.map((d) => label(d.data, d));

  // Compute the layout.
  const dy = width / (root.height + padding);
  tree().nodeSize([dx, dy])(root);

  const svg = (ref.hasOwnProperty("current") ? d3.select(ref.current) : ref) // React version passes ref.current, static JSDOM version passes svg element
    .attr("viewBox", [0, 0, width, height])
    .attr("font-family", "'Open Sans', sans-serif");
  if (pdf) {
    svg.attr("height", height).attr("width", width);
  }

  svg.selectAll("*").remove();

  // Calculate the vertical extent of the tree
  const minX = d3.min(root.descendants(), (d) => d.x);
  const maxX = d3.max(root.descendants(), (d) => d.x);
  const treeHeight = maxX - minX; // Total height of the tree
  // Calculate the horizontal extent of the tree
  const minY = d3.min(root.descendants(), (d) => d.y);
  const maxY = d3.max(root.descendants(), (d) => d.y);
  const treeWidth = maxY - minY; // Total width of the tree
  // Center the tree on the SVG canvas
  const centerX = (width - treeWidth) / 2; // Center horizontally
  const centerY = (height - treeHeight) / 2 - minX; // Center vertically, adjusting for minX offset
  const g = svg
    .append("g")
    .attr("transform", `translate(${centerX},${centerY})`)
    .append("g");
  const zoomed = (e) => {
    g.attr("transform", e ? e.transform : "");
  };
  const zoom = panEnabled
    ? d3
        .zoom()
        .scaleExtent(zoomEnabled ? [0.3, 5] : [1, 1])
        .on("zoom", (e) => {
          if (zoomStateRef) {
            zoomStateRef.current = e;
          }
          zoomed(e);
        })
    : () => {};
  svg.call(zoom);

  if (zoomStateRef && zoomStateRef.current) {
    const initialTransform = zoomStateRef.current.transform;
    svg.call(zoom.transform, initialTransform); // Sync initial transform with zoom behavior
  }

  // wkhtmltopdf doesn't support gradients
  if (!pdf) {
    // create radial gradient
    const defs = svg.append("defs");
    STATUS_COLORS.forEach((color, index) => {
      defs
        .append("radialGradient")
        .attr("id", `grad${index}`)
        .attr("cx", "50%") //not really needed, since 50% is the default
        .attr("cy", "50%") //not really needed, since 50% is the default
        .attr("r", "50%") //not really needed, since 50% is the default
        .selectAll("stop")
        .data([
          { offset: "0%", color: "#0071bc" },
          { offset: "50%", color: "#0071bc" },
          { offset: "51%", color: "#FFF" },
          { offset: "79%", color: "#FFF" },
          { offset: "80%", color: color },
          { offset: "100%", color: color },
        ])
        .enter()
        .append("stop")
        .attr("offset", function (d) {
          return d.offset;
        })
        .attr("stop-color", function (d) {
          return d.color;
        });
    });
  }

  g.append("g")
    .attr("fill", "none")
    .attr("stroke-linecap", strokeLinecap)
    .attr("stroke-linejoin", strokeLinejoin)
    .attr("stroke-width", strokeWidth)
    .selectAll("path")
    .data(root.links())
    .join("path")
    .attr(
      "d",
      d3
        .linkHorizontal()
        .x((d) => d.y)
        .y((d) => d.x)
    )
    .attr("stroke-width", (d) =>
      d.source.data.status > 0 &&
      d.source.data.status < 5 &&
      d.target.data.status > 0 &&
      d.target.data.status < 5 &&
      options.highlightNode === undefined
        ? strokeWidth * 2
        : strokeWidth
    )
    .attr("stroke-opacity", (d) =>
      d.source.data.status > 0 &&
      d.source.data.status < 5 &&
      d.target.data.status > 0 &&
      d.target.data.status < 5 &&
      options.highlightNode === undefined
        ? strokeOpacity
        : strokeOpacity - 0.5
    )
    .attr("stroke", (d) =>
      d.source.data.status > 0 &&
      d.source.data.status < 5 &&
      d.target.data.status > 0 &&
      d.target.data.status < 5 &&
      options.highlightNode === undefined
        ? "#0071bc"
        : stroke
    );

  const nodeFill = (d) => {
    if (options.highlightNode !== undefined) {
      return d.data._id === options.highlightNode ? "#3730a3" : fill;
    }
    if (d.data.errorMessage) return "#de0b07";

    for (let index = 0; index < operators.length; index++) {
      const op = operators[index];
      if (d.data.value?.includes(op + "(")) {
        switch (d.data.status) {
          case 2: // active with da(1) (yellow halo)
          case 3: // active with da(2) (orange halo)
          case 4: // active with da(3) (red halo)
            return pdf
              ? STATUS_COLORS[d.data.status - 2]
              : `url(#grad${d.data.status - 2})`;
          // no default
        }
      }
    }
    switch (d.data.status) {
      case 0: // inactive (grey)
        return fill;
      case 1: // active no da(0) (blue)
      // active with da > 0, but no da operator in expression so don't show ring
      case 2:
      case 3:
      case 4:
        return "#0071bc";
      case 5: // processing (pink)
        return "#d67bea";
      case 6: // error (green)
        return "#de0b07";
      default:
        return fill;
    }
  };
  const nodeRadius = (d) =>
    d.data.status > 1 &&
    d.data.status < 5 &&
    (d.data.value.includes("da(") ||
      d.data.value.includes("da_hi(") ||
      d.data.value.includes("da_lo(") ||
      d.data.value.includes("da_sf(")) &&
    options.highlightNode === undefined
      ? r * 1.75
      : r;

  const node = g
    .append("g")
    .selectAll("a")
    .data(root.descendants())
    .join("a")
    // .attr("xlink:href", link == null ? null : d => link(d.data, d))
    // .attr("target", link == null ? null : linkTarget)
    .attr("transform", (d) => `translate(${d.y},${d.x})`)
    .attr("style", "cursor: pointer;")
    .attr("data-name", (d) => d.data.name)
    .attr("data-fill", nodeFill)
    .attr("data-r", nodeRadius)
    .on("click", (event, node) => {
      handleTreeNodeChange(node.data._id);
    });

  node.append("circle").attr("fill", nodeFill).attr("r", nodeRadius);

  // if there's an errorMessage, show a tooltip
  node
    .append("text")
    .attr("x", -r)
    .attr("y", -r * 3)
    .attr("class", "errorMessage")
    .attr("fill", "#FF0000")
    .text((d) => (d.data.errorMessage ? `Error: ${d.data.errorMessage}` : ""));
  // if there's an acknowledgement, show a tooltip
  node
    .append("path")
    .attr("transform", (d) => {
      return `scale(0.03) translate(${
        (d.children ? -1 : 1) * (nodeRadius(d) * 30 + (d.children ? 600 : 100))
      }, -200)`;
    })
    .attr("d", (d) => {
      if (acks[d.data._id]) {
        return ICONS[acks[d.data._id].type];
      }
      return "";
    })
    .attr("fill", (d) => {
      if (acks[d.data._id]) {
        switch (acks[d.data._id].type) {
          case "normal":
            return "#3730a3";
          case "monitoring":
            return "#f7931e";
          case "issue":
            return "#de0b07";
          default:
            return "";
        }
      }
      return "";
    })
    .attr("paint-order", "stroke")
    .attr("stroke", "#ffffff")
    .attr("stroke-width", 100);

  if (pdf) {
    // draw 2 smaller circle for halo since wkhtmltopdf doesn't support gradients
    node
      .append("circle")
      .attr("fill", (d) => {
        if (d.data.status === 2 || d.data.status === 3 || d.data.status === 4) {
          return "#fff";
        }
        return "none";
      })
      .attr("r", (d) =>
        d.data.status > 1 && d.data.status < 5 ? r * 1.25 : r
      );
    node
      .append("circle")
      .attr("fill", (d) => {
        if (d.data.status === 2 || d.data.status === 3 || d.data.status === 4) {
          return "#0071bc";
        }
        return "none";
      })
      .attr("r", (d) =>
        d.data.status > 1 && d.data.status < 5 ? r * 0.75 : r
      );
  }

  if (title != null)
    node
      .append("title")
      .text((d) => title(d.data, d))
      .attr("style", `font-family: ${fontFamily}`);

  if (L)
    node
      .append("text")
      .attr("dy", "0.32em")
      .attr("x", (d) => {
        const isAcknowledged = acks[d.data._id];
        return (
          ((d.data.status > 1 && d.data.status < 5
            ? fontSize
            : fontSize / 1.5) +
            (isAcknowledged ? 20 : 0)) *
          (d.children ? -1 : 1)
        );
      })
      .attr("y", 0)
      .attr("text-anchor", (d) => (d.children ? "end" : "start"))
      .attr("paint-order", "stroke")
      .attr("stroke", haloWidth === 0 ? null : halo)
      .attr("stroke-width", haloWidth === 0 ? null : haloWidth)
      .text((d, i) =>
        L[i].length > 12 && pdf
          ? `${L[i].substring(0, 12)}...`
          : L[i].length > 16
            ? `${L[i].substring(0, 16)}...`
            : L[i]
      )
      .attr(
        "style",
        `font-size: ${fontSize}px; font-family: ${fontFamily}; font-weight: 400`
      );

  node.on("mouseenter", (event, d) => {
    const target = d3.select(event.target);
    // expand abbreviated text
    target.selectAll("text:not(.errorMessage)").text(event.target.dataset.name);
    target.selectAll("text:not(.errorMessage)").style("font-weight", "bold");
    // highlight node
    target
      .selectAll("circle")
      .attr("r", parseFloat(event.target.dataset.r) * 1.1);
  });
  node.on("mouseleave", (event, d) => {
    const target = d3.select(event.target);
    target
      .selectAll("text:not(.errorMessage)")
      .text(
        event.target.dataset.name.length > 16
          ? `${event.target.dataset.name.substring(0, 16)}...`
          : event.target.dataset.name
      );
    target.selectAll("text:not(.errorMessage)").style("font-weight", "normal");
    target.selectAll("circle").attr("r", event.target.dataset.r);
  });
};

export default renderFaultTree;

import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import "./FaultTreeOverview.scss";
import FaultTreeLegend from "./FaultTreeLegend";
import Footer from "../../nav/Footer";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import MainLayout from "../../layouts/MainLayout";
import { LoadingOverlay } from "@mantine/core";
import { FaultTreeOverviewCard2 } from "./FaultTreeOverviewCard2";
import {
  useActiveFaultTreeNodesQuery,
  useFtOrder,
  usePublishedFaultTreesQuery,
  useUserMutation,
} from "../../../hooks/tanstack-query";
import { useFaultTreesWithSections } from "../../groups/manager/use-groups-with-sections";
import { iife } from "../../../lib/utils";
import { minLen1 } from "../../../shared-ui/lib/utils";
import { SectionContainer } from "../../ov/unit-ov/unit-overview";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../shared-ui/frontend/select";
import { useDateState } from "../../../zustand/useDateState";

type SortOptions = "section" | "nodes" | "root";

export function FaultTreeOverview2() {
  useDocumentTitle("Fault Trees > DRA");
  const userMut = useUserMutation();
  const treesAndSections = useFaultTreesWithSections();
  const [lastProcessed, setLastProcessed] = useState<string>();
  const faultTreesQuery = usePublishedFaultTreesQuery();
  const { isLoading, isError } = faultTreesQuery;
  const roots = faultTreesQuery.data?.map((x) => x.rootNodeId) || [];
  const { treeCounts, rootCounts } = useActiveNodeCounts(roots);
  const order = useFtOrder()?.data; // saved order
  // if the user selects a different order we want it to show immediately (don't wait for useFtOrder to update)
  const [sortOption, setSortOption] = useState<SortOptions>("section");

  useEffect(() => {
    setSortOption((order || "section") as SortOptions);
  }, [order]);

  const date = lastProcessed
    ? moment(lastProcessed).format("MMMM Do YYYY, h:mm a")
    : null;

  return (
    <MainLayout showDateNav={true}>
      <LoadingOverlay overlayBlur={2} visible={!isError && isLoading} />

      <div className="bg-bggrey flex flex-col MIN_H_SCREEN2">
        {/* <MainLoader match={!init} /> */}
        <div>
          <div className="flex justify-between py-2 mb-1 px-4">
            <div className="page-header prose pl-1">
              <h2>Fault Trees Overview</h2>
            </div>
            <div className="flex flex-row gap-2">
              <Select
                value={sortOption}
                onValueChange={(s) => {
                  userMut.mutate({
                    preferred_order: {
                      pov: [],
                      ft: s,
                    },
                  });
                  setSortOption(s as SortOptions);
                }}
              >
                <SelectTrigger className="max-w-max text-nowrap">
                  <span>
                    Sort:{" "}
                    {iife(() => {
                      if (sortOption === "section") return "Sections";
                      if (sortOption === "nodes")
                        return "Number of Active Nodes";
                      if (sortOption === "root") return "Active Top Nodes";
                      return "Section";
                    })}
                  </span>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="section">Sections</SelectItem>
                    <SelectItem value="nodes">
                      Number of Active Nodes
                    </SelectItem>
                    <SelectItem value="root">Active Top Nodes</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <div className="rounded-md bg-base-100 w-full border border-xslate-7">
                <FaultTreeLegend />
              </div>
            </div>
          </div>
          {!isError &&
            !isLoading &&
            (faultTreesQuery.data.length > 0 ? (
              iife(() => {
                if (sortOption === "section") {
                  if (!treesAndSections) return null;
                  return (
                    <div className="px-8 mb-12 flex flex-col gap-3">
                      {iife(() => {
                        const remainingSorted =
                          treesAndSections.remainingFaultTrees
                            .map((x) =>
                              faultTreesQuery.data.find((y) => y._id === x._id)
                            )
                            .filter((x) => x !== undefined);
                        if (!remainingSorted.length) return null;

                        return remainingSorted.map((t) => (
                          <div key={t._id} className="mb-2 group">
                            <FaultTreeOverviewCard2
                              tree={t}
                              setLastProcessed={setLastProcessed}
                            />
                          </div>
                        ));
                      })}

                      {treesAndSections.remainingFaultTrees.length > 0 &&
                        treesAndSections.sectionsWithFaultTrees.length > 0 && (
                          <div className="h-[1px] bg-xslate-6"></div>
                        )}

                      {treesAndSections.sectionsWithFaultTrees.map((s) => {
                        const toRender = s.section.groups
                          .map((x) =>
                            faultTreesQuery.data.find((y) => y._id === x)
                          )
                          .filter((x) => x !== undefined);

                        if (!minLen1(toRender)) return null;

                        return (
                          <SectionContainer
                            key={s.section._id}
                            section={s.section}
                          >
                            {toRender.map((t) => {
                              return (
                                <FaultTreeOverviewCard2
                                  tree={t}
                                  key={t._id}
                                  setLastProcessed={setLastProcessed}
                                />
                              );
                            })}
                          </SectionContainer>
                        );
                      })}
                    </div>
                  );
                } else {
                  return (
                    <ul className="list-none px-8 mb-12">
                      {faultTreesQuery.data
                        .sort((a, b) => {
                          if (sortOption === "nodes") {
                            return (
                              (treeCounts.get(b.name) || 0) -
                              (treeCounts.get(a.name) || 0)
                            );
                          }
                          if (sortOption === "root") {
                            const diff =
                              (rootCounts.get(b.name) || 0) -
                              (rootCounts.get(a.name) || 0);
                            return diff === 0
                              ? (treeCounts.get(b.name) || 0) -
                                  (treeCounts.get(a.name) || 0)
                              : diff;
                          }
                          return 0;
                        })
                        .map((t) => (
                          <li key={t._id} className="mb-2 group">
                            <FaultTreeOverviewCard2
                              tree={t}
                              setLastProcessed={setLastProcessed}
                            />
                          </li>
                        ))}
                    </ul>
                  );
                }
              })
            ) : (
              <h3 className="text-center text-2xl font-semibold text-xslate-11 pt-12 h-[60vh]">
                No published fault trees to display
              </h3>
            ))}

          {!isError && !isLoading && date && (
            <div className="FaultTreeOverview__procDate">
              {faultTreesQuery.data.length} Fault Trees &nbsp;&middot;&nbsp;
              Last Updated: {date}
            </div>
          )}
        </div>
        <Footer className="!mt-auto" />
      </div>
    </MainLayout>
  );
}

export function useActiveNodeCounts(rootIds: string[]) {
  const selectedDateEnd = useDateState().axisRangeTo.dateString;
  const activeNodesQuery = useActiveFaultTreeNodesQuery(selectedDateEnd);
  const activeTrees = Object.values(activeNodesQuery.data || {});
  const treeCounts = activeTrees.reduce(
    // tree name => number of active nodes
    (map, str) => map.set(str, (map.get(str) || 0) + 1),
    new Map()
  );
  const activeRoots = Object.keys(activeNodesQuery.data || {})
    .filter((x) => rootIds.includes(x))
    .map((x) => activeNodesQuery.data![x]);
  const rootCounts = activeRoots.reduce(
    (map, str) => map.set(str, (map.get(str) || 0) + 1),
    new Map()
  );
  return { treeCounts, rootCounts };
}

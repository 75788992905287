import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { createContext, PropsWithChildren, useContext, useState } from "react";

const ProfileBookTabs = ["Folders", "Clusters", "Groups", "Tags"] as const;
type SidebarTab = (typeof ProfileBookTabs)[number];

type FolderCreateOrEditMode = "creating" | "editing" | undefined;

type Title =
  | {
      type: "anomalies";
      name:
        | "Watchlist"
        | "Fresh 3rd Degree Tags"
        | "3rd Degree Tags"
        | "Fresh 2nd Degree Tags"
        | "2nd Degree Tags";
    }
  | {
      type: "slopes";
      name: "Long-term trends" | "Medium-term trends" | "Short-term trends";
    }
  | {
      type: "clusters";
      _id: string;
    }
  | {
      type: "groups";
      _id: string;
    }
  | {
      type: "personal-folders";
      _id: string;
    }
  | {
      type: "current-tags";
    };

const sectionOfTitle = (title: Title) => {
  switch (title.type) {
    case "groups":
    case "clusters":
      return title.type;
    default:
      return "folders";
  }
};

type ProfileBookStore = {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;

  sidebarTab: SidebarTab;
  setSidebarTab: (tab: SidebarTab) => void;

  showSlopingTrends: boolean;
  setShowSlopingTrends: (show: boolean) => void;

  showAnomalies: boolean;
  setShowAnomalies: (show: boolean) => void;

  folderCreateOrEdit: FolderCreateOrEditMode;
  setFolderCreateOrEdit: (mode: FolderCreateOrEditMode) => void;

  title: Title;
  setTitle: (title: Title) => void;

  globalShutdownToggle: boolean;
  setGlobalShutdownToggle: (_: boolean) => void;

  globalModeTransparencyToggle: boolean;
  setGlobalModeTransparencyToggle: (_: boolean) => void;

  onSlopingTrendsFolderClick: () => void;

  setDefaultModes: () => void;
};

const defaultModeOptions = {
  globalShutdownToggle: false,
  globalModeTransparencyToggle: true,
  showAnomalies: true,
  showSlopingTrends: false,
};

function createUseProfileBookStore() {
  return create<ProfileBookStore>()(
    subscribeWithSelector((set) => ({
      ...defaultModeOptions,

      setGlobalShutdownToggle: (globalShutdownToggle) =>
        set({ globalShutdownToggle }),

      setGlobalModeTransparencyToggle: (globalModeTransparencyToggle) =>
        set({ globalModeTransparencyToggle }),

      setShowAnomalies: (show) => set({ showAnomalies: show }),

      setDefaultModes: () => set({ ...defaultModeOptions }),

      sidebarOpen: true,
      setSidebarOpen: (open) => set({ sidebarOpen: open }),

      sidebarTab: "Folders",
      setSidebarTab: (tab) => set({ sidebarTab: tab }),

      showSlopingTrends: false,
      setShowSlopingTrends: (show) => set({ showSlopingTrends: show }),

      folderCreateOrEdit: undefined,
      setFolderCreateOrEdit: (mode) => set({ folderCreateOrEdit: mode }),

      title: {
        type: "current-tags",
      },
      setTitle: (title) => set({ title }),

      onSlopingTrendsFolderClick: () => {
        set({
          globalShutdownToggle: true,
          showAnomalies: false,
          showSlopingTrends: true,
        });
      },
    }))
  );
}

type UseProfileBookStore = ReturnType<typeof createUseProfileBookStore>;

const UseProfileBookStoreContext = createContext<
  UseProfileBookStore | undefined
>(undefined);

function useGetUseProfileBookStoreRequired() {
  const useStore = useGetUseProfileBookStoreNotRequired();
  if (!useStore) {
    throw new Error(
      "useGetUseProfileBookStore must be used within a UseProfileBookStoreProvider"
    );
  }
  return useStore;
}

function useGetUseProfileBookStoreNotRequired() {
  return useContext(UseProfileBookStoreContext);
}

function UseProfileBookStoreProvider({ children }: PropsWithChildren) {
  const [useStore] = useState(() => createUseProfileBookStore());

  return (
    <UseProfileBookStoreContext.Provider value={useStore}>
      {children}
    </UseProfileBookStoreContext.Provider>
  );
}

export {
  UseProfileBookStoreProvider,
  useGetUseProfileBookStoreRequired,
  useGetUseProfileBookStoreNotRequired,
  type SidebarTab,
  sectionOfTitle,
};

import MainLoader from "../../common/MainLoader";
import { Header } from "./ft-node-details-header";
import { FaultTreeCardStatusSeries2 } from "../overview/FaultTreeCardStatusSeries2";
import { FaultTreeNodeDetailsInfo2 } from "./FaultTreeNodeDetailsInfo2";
import { FaultTreeNodeDetailsChart2 } from "./FaultTreeNodeDetailsChart2";
import { useSelectedNodeQuery } from "../details/use-selected-node-query";
import { useGetUseFaultTreeDetailsStore } from "../details/ft-details-store";
import { useFTStatusSeriesCache } from "../details/use-ft-status-series-cache";
import { Drawer, DrawerContent } from "../../../shared-ui/frontend/drawer";
import { TimeseriesChartTooltipStoreProvider } from "../../../shared-ui/time-series-2/global-timeseries-tooltip-and-clicked-line-store/use-global-timeseries-tooltip-and-clicked-line-store";
import { GlobalTooltip } from "../../time-series/global-tooltip";
import { ClickedLineControlsNeedsTimeseriesTooltipStoreProvider } from "../../time-series/secondary-variable-view/control-buttons";

function DrawerFtNodeDetails() {
  const useFaultTreeDetailsStore = useGetUseFaultTreeDetailsStore();
  const selectedNode = useFaultTreeDetailsStore((s) => s.selectedNode);
  const getSeriesByNodeId = useFTStatusSeriesCache();
  const { removeSelectedNodeQuery } = useSelectedNodeQuery();

  const closeDialog = () => {
    removeSelectedNodeQuery();
    useFaultTreeDetailsStore.getState().setSelectedNode(undefined);
  };

  return (
    <Drawer
      dismissible={false}
      direction="right"
      open={!!selectedNode}
      onOpenChange={(open) => {
        if (!open) closeDialog();
      }}
    >
      <DrawerContent
        // select-auto allows selecting text
        className="w-[90dvw] h-[100dvh] right-0 left-auto overflow-scroll rounded-none p-0 bg-zinc-100 !select-text"
      >
        {!selectedNode ||
        selectedNode.name == null ||
        selectedNode.status == null ? (
          <div className="faultTree-nodeDetails-wrapper">
            <MainLoader message={"Loading..."} />
          </div>
        ) : (
          <TimeseriesChartTooltipStoreProvider>
            {() => (
              <>
                <GlobalTooltip />
                <ClickedLineControlsNeedsTimeseriesTooltipStoreProvider />
                <div className="px-6">
                  <div className="mb-2">
                    <Header />
                    <FaultTreeCardStatusSeries2
                      className="px-10 pt-4 pb-2 bg-white border border-bordgrey2 rounded-lg"
                      variableId={selectedNode._id}
                      treeId={selectedNode.faultTreeId}
                      height="1.5rem"
                      showDateRow
                      series={getSeriesByNodeId(selectedNode._id)}
                    />
                    <FaultTreeNodeDetailsInfo2 />
                    <hr />
                  </div>
                  <FaultTreeNodeDetailsChart2 key={selectedNode._id} />
                </div>
              </>
            )}
          </TimeseriesChartTooltipStoreProvider>
        )}
      </DrawerContent>
    </Drawer>
  );
}

export { DrawerFtNodeDetails };
